import '../../node_modules/bootstrap/js/dist/tab';
import './markup/j-preload-bg';
import './markup/j-scroll-hit';
import './markup/j-scroll-to-anchor';
import './markup/j-sticky';

import noUiSlider from '../../node_modules/nouislider/distribute/nouislider';
import '../../node_modules/owl.carousel/dist/owl.carousel';
import '../../node_modules/select2/dist/js/select2.min';
import '../../node_modules/bootstrap/js/dist/tooltip';

$(document).ready(function () {
    $('.select2-yii').select2({
        minimumResultsForSearch: -1
    });

    $('[data-toggle="tooltip"]').tooltip();

    $('.b-footer__top').on('click', function () {
        $('body,html').animate({scrollTop: 0});
    });

    $('.b-header-search-toggle').on('click', function () {
        $('.b-header-search').toggleClass('b-header-search_active');
    });

    $('.b-header-search__close').on('click', function () {
        $('.b-header-search').toggleClass('b-header-search_active');
    });
});

window.noUiSlider = noUiSlider;


$('.b-menu-burger').on('click', function () {
    var menu = document.getElementsByClassName('b-menu_main');
    this.classList.toggle('b-menu-burger_open')
    menu[0].classList.toggle('b-menu_open');
});

var dropdowns = document.getElementsByClassName('b-dropdown');
[].forEach.call(dropdowns, function (item) {
    item.addEventListener('click', function () {
        this.classList.toggle('b-dropdown_open');
    });
});

var flips = document.getElementsByClassName('b-flip');
for (var i = 0; i < flips.length; i++) {
    var item = flips[i];
    var btns = item.querySelectorAll('.b-flip__btn');
    for (var j = 0; j < btns.length; j++) {
        btns[j].addEventListener('click', function () {
            this.closest('.b-flip').classList.toggle('b-flip_back');
        });
    }
}

$('.owl-carousel_content_provider').owlCarousel({
    loop: true,
    margin: 10,
    nav: true,
    lazyLoad: true,
    responsive: {
        0: {
            items: 1
        },
        600: {
            items: 3
        },
        1000: {
            items: 5
        }
    }
});

$('.owl-carousel_content_reviews').owlCarousel({
    loop:true,
    margin:10,
    nav:true,
    lazyLoad: true,
    responsive:{
        0:{
            items:1
        },
        600:{
            items:1
        },
        1000:{
            items:1
        }
    }
});

$('.table-mobile .b-table-content').owlCarousel({
    loop:false,
    nav:false,
    dots: false,
    lazyLoad: true,
    responsive:{
        0:{
            nav:false,
            items:1
        },
        600:{
            nav:false,
            items:1
        },
        1000:{
            nav:false,
            items:1
        }
    }
});
