import mutations from './mutations';
import getters from './getters';
import actions from './actions';

export default {
    namespaced: true,
    state: {
        plans: [],
        sort: null,
        sort_list: [],
        max_list_length: null,
        lists: [],
        hide_similar_parameters: false,
        hidden_rows: [],
        lessThanYearContractTypes: [],
        lessThanTwoYearsContractTypes: [],
    },
    mutations: mutations,
    getters: getters,
    actions: actions
};