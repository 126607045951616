<template>
    <div @click="cartButton()" class="b-plan__action">
        <span>{{ visuallyAddedToCart ? 'remove from comparison' : 'add to comparison' }}</span><i
            ref="cartButton" class="far fa-clone"
            data-test-role="add-plan-to-cart-icon"
            :class="{ 'text-success': visuallyAddedToCart }"></i>
    </div>
</template>

<script>
    import DefaultErrorHandlerMixin from '../common/DefaultErrorHandlerMixin';

    export default {
        mixins: [ DefaultErrorHandlerMixin ],
        props: {
            'plan': Object,
        },
        data: function () {
            return {
                visuallyAddedToCart: false,
            }
        },
        computed: {
            isInTheCartOnServer: function () {
                return this.$store.state.cart.plan_ids.indexOf(parseInt(this.plan.id)) != -1;
            },
        },
        mounted: function () {
            this.updateAddToCartTooltip();
        },
        methods: {
            cartButton: function () {
                if (this.visuallyAddedToCart) {
                    this.visuallyAddedToCart = false;
                    this.executeStoreAction('deletePlan');
                } else {
                    this.visuallyAddedToCart = true;
                    this.executeStoreAction('addPlan');
                }
            },
            executeStoreAction(action) {
                var vm = this;

                this.$store.dispatch('cart/' + action, this.plan.id).catch(
                    this.defaultErrorHandler
                ).then(() => {
                    if (vm.isInTheCartOnServer) {
                        if (action == 'addPlan') {
                            vm.$emit('added-to-cart');
                        }
                    } else {
                        if (this.visuallyAddedToCart) {
                            this.visuallyAddedToCart = false;
                        }
                    }
                });
            },
            updateAddToCartTooltip() {
                var tip;
                if (this.visuallyAddedToCart) {
                    tip = 'Remove plan from comparison list';
                } else {
                    tip = 'Add plan to comparison list';
                }

                $(this.$refs.cartButton).attr('data-original-title', tip);
                $('.bs-tooltip-top .tooltip-inner').text(tip);
                $(this.$refs.cartButton).tooltip('update');
            },
        },
        watch: {
            isInTheCartOnServer: {
                handler: function () {
                    this.visuallyAddedToCart = this.isInTheCartOnServer;
                    this.updateAddToCartTooltip();
                },
                immediate: true
            }
        }
    }
</script>
