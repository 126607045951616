<template>
    <div>
        <ConfirmationModal ref="modal"
                           :title="'Save your comparison list'"
                           :text="'If you change the active list, all unsaved changes will be lost. Save changes?'"/>

        <ProfileSaveListModal ref="save_modal"/>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    import ProfileSaveListModal from './ProfileSaveListModal'

    export default {
        components: { ProfileSaveListModal },
        computed: {
            ...mapGetters({
                activeListId: 'cart/profile/activeListId',
                activeList: 'cart/profile/activeList',
            }),
        },
        methods: {
            show(onSuccessSaveCallback, onCancelCallback) {
                var vm = this;

                if (vm.activeList.has_changes) {
                    //Got changes in active list, ask user if he wants to save them
                    vm.$refs.modal.show(
                        () => {
                            //Yes button callback
                            if (vm.activeList.id == -1) {
                                vm.$refs.save_modal.show(onSuccessSaveCallback, onCancelCallback);
                            } else {
                                vm.$store.dispatch('cart/profile/saveActiveList', {
                                    name: vm.activeList.name,
                                    overwrite: true
                                }).then(
                                    onSuccessSaveCallback,
                                    vm.defaultErrorHandler
                                );
                            }
                        },
                        //No button callback, do what we need without saving active list
                        onSuccessSaveCallback,
                        () => {
                            //Cancel callback
                            if (onCancelCallback)
                                onCancelCallback();
                        }
                    );
                } else {
                    onSuccessSaveCallback();
                }
            }
        }
    }
</script>