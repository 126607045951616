<template>
    <span>
        ${{price_per_year}} <span
            v-if="show_notice"
            class="b-tip"
            v-b-tooltip.hover
            title="Based on current standard price"></span>
    </span>
</template>

<script>
    import { mapGetters } from 'vuex'

    export default {
        props: {
            plan: Object,
        },
        computed: {
            ...mapGetters({
                getSelectedDetails: 'cart/profile/getSelectedDetails',
            }),
            price_per_year() {
                return this.getSelectedDetails(this.plan).price_per_year;
            },
            show_notice() {
                var lessThanYearContractTypes = this.$store.getters['cart/profile/lessThanYearContractTypes'];

                return lessThanYearContractTypes.indexOf(this.getSelectedDetails(this.plan).contract_type_value) != -1;
            }
        },
    }
</script>