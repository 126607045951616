<template>
    <div data-test-role="select-plan-button">
        <button v-if="plan.tracking_mode == 'tracker'" :disabled="disabled"
                :class="'btn ' + buttonClass" @click="showTrackingForm()">
            {{selectButtonLabel}}
        </button>
        <a v-if="plan.tracking_mode == 'custom_url'" :class="'btn ' + buttonClass" :href="plan.custom_tracking_url"
           target="_blank">
            {{selectButtonLabel}}
        </a>
    </div>
</template>

<script>
    export default {
        name: "SignInButton",
        props: {
            plan: Object,
            defaultTrackerLabel: {
                default: 'SELECT'
            },
            buttonClass: {
                type: String,
                default: 'btn-primary'
            },
            defaultCustomUrlLabel: {
                default: 'VISIT SITE'
            },
            overridePlanId: String
        },
        computed: {
            selectButtonLabel: function () {
                if (this.plan.hasOwnProperty('tracking_button_label') &&
                    this.plan.tracking_button_label != '' &&
                    this.plan.tracking_button_label != null)
                    return this.plan.tracking_button_label

                if (this.plan.tracking_mode == 'tracker') {
                    return this.defaultTrackerLabel;
                } else if (this.plan.tracking_mode == 'custom_url') {
                    return this.defaultCustomUrlLabel;
                }

            },
            disabled() {
                if (this.plan.hasOwnProperty('status')) {
                    return this.plan.status != 'published'
                }

                return false;
            }
        },
        methods: {
            showTrackingForm() {
                // showPlanTrackingForm => /code/frontend/web/js/common.js
                let plan_id = this.plan.id;

                if (this.overridePlanId) {
                    plan_id = this.overridePlanId
                }
                let target = (this.$store.hasModule('plans')) ? this.$store.state.plans.leadDataTarget : '';
                showPlanTrackingForm(plan_id, target);
            },
        }
    }
</script>