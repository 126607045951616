import { GET } from "../../../utils";
import profile from "./profile";

export function sendToCartApi (
    payload,
    checkResponseCallback, //Коллбэк проверяющий правильность структуры ответа
)
{
    return new Promise((resolve, reject) => {
        payload = Object.assign(
        {
            data: null,
            method: 'GET'
        }, payload);

        axios({
            url: window.cartApiUrl + '/' + payload.action,
            method: payload.method,
            data: payload.data
        }).then(function (response) {
            if (response.data.status == 'error') {
                reject(new Error(response.data.message));
            } else {
                if (checkResponseCallback == undefined || checkResponseCallback(response)) {
                    resolve(response);
                } else {
                    console.log('Wrong response structure');
                    reject();
                }
            }
        }).catch((error) => {
            console.log(error);
            reject();
        });
    });
}

function responseCheckWidget(response)
{
    return response.data.hasOwnProperty('ids') && response.data.hasOwnProperty('count')
}

export default {
    namespaced: true,
    modules: {
        profile: profile
    },
    state: {
        plan_ids: [],
        count: 0,
    },
    mutations: {
        setPlanIds (state, newPlanIds) {
            state.plan_ids = newPlanIds;
        },
        setCount (state, newCount) {
            state.count = newCount;
        },
    },
    getters: {
        apiUrl: function(state) {
            return state.api_url;
        },
        plan_ids: function(state) {
            return state.plan_ids;
        },
    },
    actions: {
        cartWidgetResponseParse: function(context, response) {
            context.commit('setPlanIds', response.data.ids);
            context.commit('setCount', response.data.count);
        },
        deletePlan: function (context, id) {
            return sendToCartApi(
                {
                    action: 'delete-plan-id',
                    data: {plan_id: id},
                    method: 'POST'
                },
                responseCheckWidget)
                .then((response) => {
                    context.dispatch('cartWidgetResponseParse', response);
                });
        },
        addPlan: function (context, id) {
            return sendToCartApi(
                {
                    action: 'add-plan-id',
                    data: {plan_id: id},
                    method: 'POST'
                },
                responseCheckWidget)
                .then((response) => {
                    context.dispatch('cartWidgetResponseParse', response);
                });
        },
        getActiveListInfo: function (context) {
            return sendToCartApi(
                {
                    action: 'get-active-list-info',
                },
                responseCheckWidget)
                .then((response) => {
                    context.dispatch('cartWidgetResponseParse', response);
                });
        },
        init: function(context, init_data) {
            window.cartApiUrl = init_data.api_url;
            context.state.count = init_data.count;
            context.state.plan_ids = init_data.plan_ids;
        }
    }
};