<template>
    <!-- Contract options -->
    <div data-test-role="select-plan-contract-options">
        <label v-if="published(plan)" v-for="(planDetails, index) in plan.details"
               v-bind:for="'co_' + planDetails.id"
               class="b-form-field b-form-field_custom contract-options">

            <input class="b-form-field__control"
                   type="radio"
                   :name="'co_' + plan.id"
                   v-bind:value="planDetails.id"
                   v-bind:id="'co_' + planDetails.id"
                   v-model="plan.selected_details_id"
                   @change="$eventHub.$emit('update:plan-selected-details-id', {
                                            planId: plan.id,
                                            planDetailsId: planDetails.id
                                        })">
            <span class="b-form-field__view b-form-field__view_primary">
                 <span :class="{'got-a-notice': getFieldNotice(planDetails)!=''}"
                       v-b-tooltip.hover
                       :title="getFieldNotice(planDetails)">
                {{planDetails.contract_type.replace(' ', '&nbsp;')}}
                 </span>
            </span>
        </label>

    </div>
</template>

<script>
    export default {
        props: {
            plan: Object
        },
        methods: {
            published(plan) {
                return plan.status == 'published';
            },
            getFieldNotice(planDetails) {
                if (planDetails.fields_notices.hasOwnProperty('contract_type')) {
                    return planDetails.fields_notices['contract_type'];
                }
                return '';
            }
        }
    }
</script>

<style>
    label.contract-options {
        display: block;
    }
</style>