// Mixin with the method to be used in promises to handle errors
// Takes the error text and outputs it to the ErrorModal modal
export default {
    methods: {
        defaultErrorHandler(error) {
            console.log(error);
            if (error == undefined || error.message == '') {
                this.$root.error_message = "Sorry, an error occurred during request, please try again later"
            } else {
                this.$root.error_message = error.message;
            }
        },
    }
};
