export default {
    data: function () {
        return {
            screenIsMobile: false
        };
    },
    created() {
        this.updateScreenIsMobile();
        global.$(window).on('resize', () => {
            this.updateScreenIsMobile();
        });
    },
    methods: {
        updateScreenIsMobile: function () {
            this.screenIsMobile = global.$(window).width() <= 992;
        },
    },
};
