
export default {
    setPlans (state, newPlans) {
        state.plans = newPlans;
    },
    setSort(state, newSort) {
        state.sort = newSort;
    },
    setLists(state, newLists) {
        state.lists = newLists;
    },
    setListActive(state, listId) {
        var newActiveList = state.lists.find((list) => {
            return list.id == listId;
        });

        var activeList = state.lists.find((list) => {
            return list.is_active;
        });

        activeList.is_active = false;
        newActiveList.is_active = true;
    },
    setHiddenRows(state, newHiddenRows){
        for (var i in newHiddenRows) {
            newHiddenRows[i] = String(newHiddenRows[i]);
        }

        for (var i in state.rows) {
            state.rows[i].hidden =
                newHiddenRows.indexOf(state.rows[i].id) != -1;
        }

        state.hidden_rows = newHiddenRows;
    },
    setHideSimilarParameters(state, value) {
        state.hide_similar_parameters = value;
    },
};