<template lang="pug">
  div.row.popup-lead.mt-5
    div.col-md-12.text-center.pb-5
      h3(v-html="leadFormTitle").text-white.pt-5.pb-5
      div.row.text-left.text-white
        div.col-md-6.pl-md-5.pl-3
          div
            p(v-html="leadFormText")
          div.mt-3.mb-3
            a(href="tel:0508226672").call-link
              i.b-link__ico.fas.fa-phone
              span.b-link__text.ml-3 0508 226 672
        div.col-md-6.pr-5
          form(action="#")
            div.row
              div.col-md-6
                div.form-group
                  input(type="text" v-model="$v.popupLead.name.$model" placeholder="first name" :class="attributeError('name') ? 'error has-error' : ''").form-control
                  label(class="error-text" v-if="attributeError('name')") Name cannot be blank
              div.col-md-6
                div.form-group
                  input(type="text" v-model="popupLead.lastName" placeholder="last name").form-control
            div.row.mb-3
              div.col-md-12
                input(type="text" v-model="$v.popupLead.phone.$model" placeholder="phone number" :class="attributeError('phone') ? 'error has-error' : ''").form-control
                label(class="error-text" v-if="attributeError('phone')") Phone is invalid
            div.row.mb-3
              div.col-md-12
                input(type="text" v-model="$v.popupLead.email.$model" placeholder="email" :class="attributeError('email') ? 'error has-error' : ''").form-control
                label(class="error-text" v-if="attributeError('email')") Enter valid email
            div.row
              div.col-md-12.default-checkbox
                input(type="checkbox" id="subscribe" v-model="popupLead.subscribe").b-form-field__control
                label(for="subscribe").text-white.ml-2 Subscribe to our newsletter and never miss deal!
            div.row
              div.col-md-12.default-checkbox
                input(type="checkbox" id="terms" v-model="$v.popupLead.agreeTerms.$model").b-form-field__control
                label(for="terms").text-white.ml-2 agree to NZ Compare #[a(href="/p/terms-of-use") Terms of Use] and #[a(href="/p/privacy-policy") Privacy Pollicy]
                label(class="error-text" v-if="attributeError('agreeTerms')") You must agree to Terms of Use and Privacy Pollicy
            div.row
              div.col-md-12
                a(href="#" @click.prevent="submitPupupLead" :class="{ 'disabled': !submitActive }").btn.btn-primary.text-uppercase.mt-4 submit
</template>

<script>
import {validationMixin} from 'vuelidate'
import {required, email} from 'vuelidate/lib/validators'

export default {
  name: "PopupLead",
  mixins: [validationMixin],
  validations: {
    popupLead: {
      name: {
        required,
      },
      phone: {
        required,
      },
      email: {
        required,
        email
      },
      agreeTerms: {
        required
      },
    }
  },
  data: function () {
    return {
      popupLead: {
        name: '',
        lastName: '',
        phone: '',
        email: '',
        agreeTerms: '',
        subscribe: '',
      },
      errors: {},
      submitActive: true,
      leadFormTitle: leadFormTitle,
      leadFormText: leadFormText,
    };
  },
  methods: {
    attributeError: function (attribute) {
      let error = null;
      if (this.errors[attribute]) {
        error = this.errors[attribute][0];
      }
      return (this.$v.popupLead[attribute].$invalid || !!error) && this.$v.popupLead[attribute].$dirty;
    },
    submitPupupLead: async function () {
      this.errors = {};
      let name = this.popupLead.name;
      if (this.popupLead.name && this.popupLead.lastName) {
        name += ` ${this.popupLead.lastName}`;
      }

      const formData = {
        name: name,
        email: this.popupLead.email,
        phone: this.popupLead.phone,
        is_agree: this.popupLead.agreeTerms === 'true' ? 1 : 0,
        is_subscribed: this.popupLead.subscribe ? 1 : 0,
      }

      this.submitActive = false;
      try {
        this.$v.popupLead.$touch();
        if (this.$v.popupLead.$anyError) {
          this.submitActive = true;
          return;
        }

        const response = await axios.post('/popup-lead/site/save', formData);
        if (response.data.errors) {
          this.errors = response.data.errors;
        } else {
          const modal = $('[data-remodal-id=deals-modal]').remodal()
          modal.close();
          global.$('[data-remodal-id="confirm-popup-lead"]').remodal().open();
        }
      } catch (error) {
        console.log(error);
      }
      this.submitActive = true;
    }
  }
}
</script>

<style scoped>
.popup-lead {
  background-color: #333233;
}
@media (max-width: 576px) {
  .popup-lead .default-checkbox .text-white {
    display: inline;
  }
}


.btn-secondary {
  max-width: 140px;
}

.call-link {
  color: white;
  font-size: 2em;
  font-weight: bold;
}

.call-link:hover {
  color: white;
}

.error-text {
  color: red;
}

h3 {
  font-size: 50px;
}

p {
  font-size: 24px;
  color: white;
}
</style>
