<template>
    
</template>

<script>
    import DefaultErrorHandlerMixin from '../common/DefaultErrorHandlerMixin'

    export default {
        mixins: [DefaultErrorHandlerMixin],
        computed: {
            plan_ids: function() {
                return this.$store.state.cart.plan_ids;
            }
        },
        methods: {
            executeStoreAction(action, plan_id) {
                this.$store.dispatch('cart/' + action, plan_id)
                .catch(this.defaultErrorHandler);
            },
        },
        created: function() {
            var vm = this;

            //Event on adding to cart from non vue environment
            $( document ).on( "vue-cart:add-plan-id", function(event, plan_id) {
                vm.executeStoreAction('addPlan', plan_id);
            });
            //Event on removing plan from cart
            $( document ).on( "vue-cart:delete-plan-id", function(event, plan_id) {
                vm.executeStoreAction('deletePlan', plan_id);
            });
        },
        watch: {
            plan_ids: {
                handler: function () {
                    //Send event to all listeners
                    $(document).trigger("vue-cart:plan-ids-update", [this.plan_ids]);
                },
                immediate: true,
            }
        }
    }
</script>