<template>
    <div>
        <div class="b-stripe_gray pb-4">
            <div class="container-fluid">
                <div class="d-lg-flex justify-content-between align-items-center">
                    <div class="b-address mb-3 mb-lg-0">
                        <AddressAutocomplete/>
                    </div>
                    <Cart/>
                </div>
            </div>
        </div>
        <div class="container-fluid">
            <AlertIgnoreAddress/>
            <AlertConnectionType/>
            <AlertCoverageConnectionProblems/>
            <AlertExpiredPlan/>

            <div class="b-hr"></div>
            <div class="row d-flex align-items-top">
                <div class="col-lg-8 mb-3 mb-lg-0">
                    <div class="form-group mb-0">
                        <label><b>Quick filters:</b></label>
                        <div data-test-id="plans-basic-filter-quick-filters">
                            <label v-for="(quick_filter_label, quick_filter_name) in quick_filters_list" v-bind:for="'qf_' + quick_filter_name"
                                   class="b-form-field b-form-field_custom">
                                <input class="b-form-field__control" v-bind:value="quick_filter_name" type="checkbox" v-bind:id="'qf_' + quick_filter_name"
                                       v-model="quick_filters_filter[quick_filter_name]">
                                <span class="b-form-field__view b-form-field__view_primary">{{quick_filter_label}}</span>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="d-flex">
                        <div class="form-group mb-0 mr-4 ml-auto">
                            <label class="d-none d-lg-block">&nbsp;</label>
                            <AdvancedFilters />
                        </div>
                        <div class="form-group mb-0">
                            <label class="d-none d-lg-block">&nbsp;</label>
                            <UsefulTips/>
                        </div>
                    </div>
                </div>
            </div>

            <div class="b-hr"></div>
            <PlansList :addressIsRequired="true">
                <template slot="header">
                    <div class="mb-3">
                        <div class="row d-flex align-items-end">
                            <div class="col-lg-8 mb-3 mb-lg-0">
                                <div v-if="showConnectionTypes" class="form-group mb-0">
                                    <ConnectionTypeFilter/>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 mb-2 mb-lg-0 ">
                                <div class="row">
                                    <div class="mb-2 col-lg-7 col-md-6 ml-lg-auto b-select-100">
                                        <PlansListSort/>
                                    </div>
                                    <div class="mb-2 col-lg-5 col-md-6 mb-lg-0 b-select-100">
                                        <PlansListPriceTypes/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>

            </PlansList>

            <AdvancedFiltersModal />
            <AddressAutocompleteModal/>
            <AvailableConnectionsModal :auto-open="bundle_options_instead_availability != 1" />
            <ChooseBundleOptionsModal :auto-open="bundle_options_instead_availability == 1" />
        </div>
    </div>
</template>

<script>
import AdvancedFilters from './advanced_filters/AdvancedFilters'
import AdvancedFiltersModal from './advanced_filters/AdvancedFiltersModal'
import AddressAutocomplete from './autocomplete/AddressAutocomplete'
import AddressAutocompleteModal from './autocomplete/AddressAutocompleteModal'
import Cart from './cart/Cart'
import PlansList from './plan/PlansList'
import { mapGetters } from 'vuex'
import PlansListSort from './plan/PlansListSort'
import PlansListPriceTypes from './plan/PlansListPriceTypes'
import AvailableConnectionsModal from './AvailableConnectionsModal'
import AlertConnectionType from './AlertConnectionType'
import AlertExpiredPlan from './AlertExpiredPlan'
import AlertIgnoreAddress from './AlertIgnoreAddress'
import UsefulTips from './plan/UsefulTips'
import AlertCoverageConnectionProblems from './AlertCoverageConnectionProblems';
import ConnectionTypeFilter from "./ConnectionTypeFilter";
import ChooseBundleOptionsModal from "./ChooseBundleOptionsModal.vue";

export default {
    components: {
        ChooseBundleOptionsModal,
        ConnectionTypeFilter,
        AddressAutocomplete, AddressAutocompleteModal, Cart, PlansList,
        AdvancedFilters, AdvancedFiltersModal, PlansListSort,
        PlansListPriceTypes, AvailableConnectionsModal, AlertConnectionType,
        AlertIgnoreAddress, UsefulTips, AlertCoverageConnectionProblems, AlertExpiredPlan
    },
    computed: {
        ...mapGetters([
            'connection_types_list',
            'quick_filters_list',
            'isAddressEmpty',
            'ignore_address_coverage',
            'bundle_options_instead_availability'
        ]),
        quick_filters_filter() {
            return this.$store.state.plans.filters.quick_filters;
        },
        showConnectionTypes() {
            return Object.keys(this.connection_types_list).length > 1;
        },
    },
    watch: {
        quick_filters_filter: {
            handler() {
                //this.$store.commit('setFilters', { quick_filters: value });
                this.$store.commit('convertQuickFiltersToAdvanced');
            },
            deep: true
        },
    },
}
</script>
<script setup>
</script>
