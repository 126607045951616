<template>
    <div>
        <div class="alert alert-warning" role="alert" v-if="visible">
            Sorry, plans' availability may not be accurate at this time due to technical issues.
        </div>
    </div>
</template>

<script>
    export default {
        computed: {
            visible() {
                return this.$store.state.plans.meta.coverage_connection_problems;
            }
        },
    }
</script>