<template>
    <div>
        <div class="b-address__body" v-if="currentAddress">{{currentAddress}}</div>
        <a data-remodal-target="changeAddress" class="b-address__change" href="#">
            <template v-if="currentAddress">
                Change address
            </template>
            <template v-else>
                Specify your address
            </template>
        </a>
        <template v-if="!isAddressEmpty">
            <a v-if="enabled_connection_types.length > 0" data-remodal-target="availableModal" class="b-address__change ml-3" href="#">
                Connection info
            </a>
            <span v-else class="b-address__change ml-3 b-link_gray-dark">
                Connection info
            </span>
            <a v-if="!ignore_address_coverage" class="b-address__change ml-3" href="#" @click.prevent="ignoreAddress">
                Ignore address
            </a>
            <a v-else class="b-address__change ml-3" href="#" @click.prevent="stopIgnoringAddress">
                Stop ignoring address
            </a>
            <ConfirmationModal ref="ignore_address_confirmation_modal"
                               :text="ignore_address_modal_text"/>
        </template>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'

    export default {
        computed: {
            ...mapGetters([
                'isAddressEmpty',
                'currentAddress',
                'enabled_connection_types',
                'ignore_address_coverage',
                'ignore_address_modal_text'
            ]),
        },
        methods: {
            ignoreAddress() {
                this.$refs.ignore_address_confirmation_modal.show(
                    () => {
                        //Yes button callback
                        this.$store.dispatch('startIgnoringAddress');
                    },
                    null,
                    true
                );
            },
            stopIgnoringAddress() {
                this.$store.dispatch('stopIgnoringAddress');
            }
        }
    }
</script>

