export const status = {
    COMPLETE: 'complete',
    LOADING: 'loading',
    MESSAGE: 'message',
};

export var LoadingStatusesMixin = {
    props: {
        init_status: {
            type: String,
            default: status.LOADING,
        },
    },
    data: function() {
        return {
            status: this.init_status,
            previous_status: null
        };
    },
    computed: {
        isCompleteStatus: function() {
            return this.status == status.COMPLETE;
        },
        isLoadingStatus: function() {
            return this.status == status.LOADING;
        },
        isMessageStatus: function() {
            return this.status == status.MESSAGE;
        },
        isLoadedOnce() {
            return this.previous_status != null;
        },
        circleLoaderShouldBeShown() {
            return this.isLoadingStatus && this.previous_status != status.COMPLETE  && this.isLoadedOnce;
        },
        skeletonShouldBeShown() {
            return this.isLoadingStatus && this.previous_status == null;
        },
    },
    watch: {
        status(newVal, oldVal) {
            this.previous_status = oldVal;
        }
    }
};