<template>
    <div class="remodal-wrapper remodal-is-closed" style="display: none;">
        <div class="remodal-is-initialized remodal-is-closed" data-remodal-id="changeAddress" tabindex="-1">
            <div class="text-left mb-4">
                <p>To give you the most relevant results, we need to know your address</p>
                <div class="form-group">
                    <label v-if="!mustChoose" for="modal-address-search-field"><b>Start typing your address</b></label>
                    <input id="modal-address-search-field"
                           type="text"
                           class="form-control autocomplete"
                           @keyup="updateError"
                           placeholder="Start typing your address"
                           v-model="addressQuery"
                           autocomplete="off">
                    <span class="invalid-tooltip d-block" v-if="show_query_error">To use autocomplete enter more than {{minAddressQueryLength - 1}} symbols</span>
                </div>
            </div>
            <a v-if="!mustChoose" href="#" data-remodal-action="cancel" class="b-link b-link_gray-darken">Close</a>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue'
    import { mapGetters, mapState } from 'vuex'

    export default {
        props: {
            addressIsRequired: {
                type: Boolean,
                default: true
            }
        },
        data: function () {
            return {
                mustChoose: false,
                show_query_error: false,
                addressQuery: ''
            };
        },
        computed: {
            ...mapGetters([
                'currentAddress',
                'isAddressEmpty',
                'autocompleteDataUrl',
                'addressApiToken',
                'debugAddress',
                'debugAddressAid',
                'minAddressQueryLength',
                'addressApiVersion'
            ]),
        },
        methods: {
            updateError: function () {
                this.show_query_error = this.addressQuery.length < this.minAddressQueryLength && this.addressQuery.length > 0;
            },
        },
        mounted: function() {
            var vm = this;

            $('[data-remodal-id="changeAddress"]').remodal({
                closeOnCancel: !this.mustChoose,
                closeOnEscape: !this.mustChoose,
                closeOnOutsideClick: !this.mustChoose,
                hashTracking: false
            });

            $(document).on('click', '.ignore-address-if-not-found', function(e) {
                e.preventDefault();
                vm.$store.dispatch('startIgnoringAddress');
                vm.mustChoose = false;

                Vue.nextTick(function () {
                    $('[data-remodal-id="changeAddress"]').remodal().close();
                });
            });

            this.approximateSearch = false;
            let noSuggestionNotice = 'Not your address? <a href="/site/wrong-address" target="_blank">Click here</a><div class="ignore-address-if-not-found"><a href="#">Search without address</a></div>';

            $("#modal-address-search-field" ).autocomplete({
                serviceUrl: this.autocompleteDataUrl,
                showNoSuggestionNotice: true,
                noSuggestionNotice: noSuggestionNotice,
                paramName: 'q',
                minChars: this.minAddressQueryLength,
                width: 'auto',
                triggerSelectOnValidInput: false,
                ajaxSettings: {
                    headers: {
                        "X-Api-Key": this.addressApiToken,
                    },
                },
                onSearchStart: function(params) {
                    $(this).addClass('loading');
                    if (params.q == this.debugAddress) {
                        params['debugAid'] = this.debugAddressAid;
                    }
                },
                onSearchComplete: function(query, suggestions) {
                    $(this).removeClass('loading');

                    if (vm.approximateSearch && suggestions.length > 0) {
                        $('.autocomplete-suggestions').append('<div class="autocomplete-suggestion">' + noSuggestionNotice + '</div>');
                    }
                },
                transformResult: function (response) {
                    response = JSON.parse(response);

                    let suggestions;
                    if (vm.addressApiVersion == 2) {
                        vm.approximateSearch = response.approximateSearch;
                        suggestions = response.suggestions;
                    } else {
                        suggestions = response;
                    }

                    var result = {suggestions: []};
                    for (var i in suggestions) {
                        result.suggestions[i] = {
                            value: suggestions[i].label,
                            data: suggestions[i].aid
                        };
                    }
                    return result;
                },
                onSelect: function (suggestion) {
                    vm.$store.commit('setAddress', {
                        'id': suggestion.data,
                        'formatted': suggestion.value,
                    });
                    vm.$store.dispatch('stopIgnoringAddress');

                    $('.remodal[data-remodal-id="changeAddress"]').remodal().close();
                }
            });

            $(document).on('closing', '.remodal[data-remodal-id="changeAddress"]', function () {
                $( "#modal-address-search-field" ).autocomplete().hide();
            });

            $(document).on('opening', '.remodal[data-remodal-id="changeAddress"]', function () {
                if (!vm.mustChoose) {
                    $('#modal-address-search-field').val('');
                }
            });
        },
        watch: {
            isAddressEmpty: {
                handler() {
                    if(this.isAddressEmpty) {
                        if (this.addressIsRequired) {
                            this.mustChoose = true;

                            //Show modal to user if no address provided
                            var vm = this;

                            Vue.nextTick(function () {
                                $('[data-remodal-id="changeAddress"]').remodal().open();
                            });

                            if (this.currentAddress != '') {
                                //Show address options on first load if formatted address is not empty
                                var showOptionsOnFirstLoad = function () {
                                    $("#modal-address-search-field").val(vm.currentAddress);
                                    $("#modal-address-search-field").trigger('focus');
                                    $(document).off('opened', '.remodal[data-remodal-id="changeAddress"]', showOptionsOnFirstLoad);
                                };
                                $(document).on('opened', '.remodal[data-remodal-id="changeAddress"]', showOptionsOnFirstLoad);
                            }

                            $(document).on('closed', '.remodal[data-remodal-id="changeAddress"]', function () {
                                vm.mustChoose = false;
                                $(document).off('closed', '.remodal[data-remodal-id="changeAddress"]');
                            });
                        }
                    } else {
                        this.mustChoose = false;

                        Vue.nextTick(function () {
                            $('[data-remodal-id="changeAddress"]').remodal().close();
                        });
                    }
                },
                immediate: true
            },
            mustChoose() {
                var remodal_settings = $('[data-remodal-id="changeAddress"]').remodal().settings;
                remodal_settings.closeOnCancel =
                    remodal_settings.closeOnEscape =
                        remodal_settings.closeOnOutsideClick = !this.mustChoose;

            }
        }
    }
</script>

<style>
    .ignore-address-if-not-found {
        float: right;
    }

    .ignore-address-if-not-found a {
        color: #DB0000;
    }

    .ignore-address-if-not-found a:hover {
        color: #DB0000;
        text-decoration: underline;
    }
</style>
