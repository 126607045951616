<template>
    <div>
        <slot name="header">
            <div class="d-lg-flex justify-content-between align-items-center mb-3">
                <div class="d-lg-flex" data-test-id="plans-sort-filters">
                    <div class="mb-2 mb-lg-0 mr-3">
                        <PlansListSort/>
                    </div>
                    <div class="mb-2 mb-lg-0">
                        <PlansListPriceTypes/>
                    </div>
                </div>
                <div class="b-text b-text_size" v-if="plansTotalCount > 0">
                    {{shownPlansCount}} ({{plansFrom}}-{{plansTo}})/{{plansTotalCount}} plans shown
                </div>
            </div>
        </slot>

        <slot></slot>

        <div v-if="isMessageStatus" class="text-center big-message d-flex">
            <div class="m-auto">
                {{error_message}}
            </div>
        </div>

        <PlansSkeleton v-if="plansSkeletonShouldBeShown" :count="5"/>

        <CircleLoader v-if="circleLoaderShouldBeShown"/>

        <div v-if="plansShouldBeShown" :class="{ dimmed: isLoadingStatus }" ref="plans_block">

            <template v-for="(plan, plan_position) in plans">
                <template v-if="getPromoForPlanPosition(plan_position)">
                    <PlanAdvertisement :promo="getPromoForPlanPosition(plan_position)" :isCompleteStatus="advertisementShouldBeShown"/>
                </template>

                <Plan
                      :plan="plan"
                      :key="plan.id"
                      :priceUnit="priceUnit"
                      @show-featured-text="$refs.featured_modal.show($event.text, $event.label, $event.details_url)"
                      @show-special-text="$refs.featured_modal.show($event.text, $event.label, $event.details_url)"/>
            </template>
        </div>

        <div class="mb-5" v-if="paginationShouldBeShown">
            <nav class="d-flex justify-content-center">
                <Pagination :pageCount = "page_count"
                            :page = "current_page + 1"
                            :radius = "pagination_size"
                            @update="loadPage($event)"/>
            </nav>
            <div class="b-text b-text_size mt-2 text-center" v-if="plansTotalCount > 0">
                Shown {{shownPlansCount}} plans ({{plansFrom}}-{{plansTo}}) out of {{plansTotalCount}} plans
            </div>
        </div>

        <FeaturedModal ref="featured_modal" />

    </div>
</template>

<script>
    import Pagination from '../common/Pagination'
    import { mapGetters } from 'vuex'
    import PlansSkeleton from './PlansSkeleton'
    import FeaturedModal from './FeaturedModal'
    import Plan from './Plan'
    import { status, LoadingStatusesMixin } from '../common/LoadingStatusesMixin'
    import PlanAdvertisement from "./PlanAdvertisement";
    import PlansListSort from "./PlansListSort";
    import PlansListPriceTypes from "./PlansListPriceTypes";
    import { objectsDifference, cloneObject } from "../../js/utils";

    export default {
        mixins: [LoadingStatusesMixin],
        components: {
            PlanAdvertisement, Plan, Pagination, PlansSkeleton, FeaturedModal, PlansListSort, PlansListPriceTypes
        },
        props: {
            addressIsRequired: {
                type: Boolean,
                default: false
            },
            errorMessageNoPlans: {
                type: String,
                default: 'Plans aren\'t found'
            }
        },
        data: function() {
            return {
                error_message: '',
                pagination_size: 5,
                oldFilters: {}
            };
        },
        computed: {
            paginationShouldBeShown() {
                return this.isCompleteStatus ||
                    (this.isLoadingStatus && this.previous_status == status.COMPLETE);
            },
            advertisementShouldBeShown() {
              return this.isCompleteStatus;
            },
            ...mapGetters([
                'plans',
                'promo',
                'plansFrom',
                'plansTo',
                'plansTotalCount',
                'plansPerPage',
                'priceUnit',
                'page_count',
                'current_page',
                'urlWithCurrentFilters',
                'isAddressEmpty',
                'ignore_address_coverage'
            ]),
            filters: {
                get () {
                    return this.$store.getters.filters;
                },
                set (newFilters) {
                    this.$store.commit('setFilters', newFilters);
                }
            },
            plansShouldBeShown() {
                return (this.isCompleteStatus || this.isLoadingStatus) && this.addressIsOk;
            },
            addressIsOk(){
                return !this.addressIsRequired || (this.addressIsRequired && !this.isAddressEmpty) || this.ignore_address_coverage;
            },
            plansSkeletonShouldBeShown() {
                return this.skeletonShouldBeShown || !this.addressIsOk;
            },
            shownPlansCount() {
                return Math.min(this.plansPerPage, this.plansTotalCount);
            },
            connection_types_filter() {
                return this.$store.state.plans.filters.connection_types;
            },
        },
        methods: {
            loadPage(page, replaceHistory) {
                this.$store.commit('setPage', page-1);

                if (replaceHistory == undefined) {
                    replaceHistory = true;
                }

                if (replaceHistory) {
                     window.history.pushState({}, '', this.urlWithCurrentFilters);
                }

                var itWasAFirstPlansLoad = !this.isLoadedOnce;
                this.status = status.LOADING;

                this.$store.dispatch('loadPage', page).then(
                    () => {
                        if (!this.plans.length) {
                            this.status = status.MESSAGE;
                            this.error_message = this.connection_types_filter.hyper
                                ? 'Hyped about Hyper Fibre? It\'s coming to Broadband Compare soon!'
                                : this.errorMessageNoPlans;
                        } else {
                            this.status = status.COMPLETE;
                            this.error_message = '';
                        }
                    },
                    (e) => {
                        if (!(e.hasOwnProperty('message') && e.message == 'cancelled')) {
                            this.status = status.MESSAGE;
                            this.error_message = 'Sorry, please try again later...';
                        }
                    }).then(() => {
                        if (!itWasAFirstPlansLoad) {
                            //Scroll to top after load
                            if ($(window).width() >= 768) {
                                //For big screens
                                var scrollTarget = $('header').offset().top + $('header').height();
                            } else {
                                //For mobile phones
                                var scrollTarget = $(this.$refs.plans_block).offset().top - $('#quickAccessPanel').outerHeight();
                            }

                            if ($(document.documentElement).scrollTop() > scrollTarget)
                                $([document.documentElement, document.body]).animate({
                                    scrollTop: scrollTarget
                                }, 500);
                        }
                    });
            },

            initFiltersWatch(){
                return this.$watch('filters', () => {
                    var diff = objectsDifference(this.oldFilters, this.filters);
                    if (diff) {
                        this.loadPage(1);
                    }
                    this.oldFilters = cloneObject(this.filters);
                }, { deep: true });
            },
            getPromoForPlanPosition(position) {
                if (this.promo) {
                    return this.promo.find(function (el) {
                        return el.position_order == position;
                    });
                }
            },
        },
        beforeMount: function() {
            if (this.addressIsOk)
                this.loadPage(this.current_page, false);

            window.initFiltersWatch = () => {
                this.oldFilters = cloneObject(this.filters);
                window.unwatchFilters = this.initFiltersWatch()
            };

            window.initFiltersWatch();
            window.addEventListener("popstate", () => {
                if (this.isLoadedOnce) {
                    window.unwatchFilters();
                    this.$store.dispatch('restoreFiltersFromGET').then(() => {
                        window.initFiltersWatch();
                        if (this.addressIsOk)
                            this.loadPage(this.current_page, false);
                    });
                }
            });

            var vm = this;
            $(window).resize(function(){
                if($(this).width() >= 768){
                   vm.pagination_size = 5;
                } else {
                    vm.pagination_size = 1;
                }
            }).resize();//trigger resize on page load

            this.$eventHub.$on('show-featured-modal', function($event) {
                vm.$refs.featured_modal.show($event)
            });
        },
    }
</script>

