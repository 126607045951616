<template>
    <div class="mb-2 mb-lg-0">
        <label for="hide_similar_parameters"
               class="b-form-field b-form-field_checkbox ml-0 ml-lg-3 hide_similar_parameters"
               data-test-id=comparison-cart-hide-similar-parameters-checkbox>
            <input v-model="value" type="checkbox"
                   @change="onValueChange"
                   id="hide_similar_parameters"
                   class="b-form-field__control">
            <span class="b-form-field__view"></span>
            <span class="b-form-field__text">Only show differences below</span>
        </label>
    </div>
</template>

<script>
    export default {
        props: {
            value: Boolean
        },
        methods: {
            onValueChange: function(){
                this.$emit('update:value', this.value)
            }
        }
    }
</script>