/**
 * Config for plan table, array of objects, each object describes one row
 * Properties:
 *
 * {
    id: String, //Id of row for saving 'hidden' flag in user settings
    name: String, //Name of row
    showUnpublishedTip: Boolean, //True if plan is not published and needs to show it in table
    hideIfPlanNotPublished: Boolean, //
    rawValueExpression: Function, //Callback for comparing values for different plans
    formattedValueExpression: Function, //Callback with formatted value
    component: String //component to be used for td content ('DefaultColumn' is used if not set)
    hidden: Boolean //True if minus has been clicked
}
 */

import { cloneObject } from "../../../../js/utils";

export var default_row_config = {
    id: null,
    name: '',
    showUnpublishedTip: false,
    hideIfPlanNotPublished: true,
    rawValueExpression: null,
    hidden: false
};
export const FEATURES_ITEMS_TEMPLATE = 'features_items';
var rows_config = [
    {
        id: 1,
        name: 'ISP',
        rawValueExpression: (plan) => plan.provider.name,
        component: 'ProviderName',
        hideIfPlanNotPublished: false,
    },
    {
        id: 2,
        name: 'ISP rating',
        rawValueExpression: (plan) => plan.provider.rating,
        component: 'ProviderRating',
        hideIfPlanNotPublished: false,
    },
    {
        id: 17,
        name: 'Feature',
        component: 'Deal',
        hideIfPlanNotPublished: false,
    },
    {
        id: 3,
        name: 'Plan name',
        rawValueExpression: (plan) => plan.name,
        component: 'PlanName',
        hideIfPlanNotPublished: false,
    },
    {
        id: 4,
        name: 'Contract options',
        showUnpublishedTip: true,
        noticeField: 'contract_type',
        component: 'ContractOptions'
    },
    {
        id: 5,
        name: 'Special price',
        rawValueExpression: (plan, selectedDetails) => selectedDetails.special_price,
        component: 'SpecialPrice'
    },
    {
        id: 6,
        name: 'Price per month',
        rawValueExpression: (plan, selectedDetails) => selectedDetails.price,
        formattedValueExpression: (plan, selectedDetails) => '$' + selectedDetails.price
    },
    {
        id: 7,
        name: 'Price per year',
        rawValueExpression: (plan, selectedDetails) => selectedDetails.price_per_year,
        component: 'PricePerYear'
    },
    {
        id: 8,
        name: 'Price per two years',
        rawValueExpression: (plan, selectedDetails) => selectedDetails.price_per_two_years,
        component: 'PricePerTwoYears'
    },
    {
        id: 10,
        name: 'Rating',
        rawValueExpression: (plan, selectedDetails) => selectedDetails.rating,
        component: 'PlanRating'
    },
    {
        id: 11,
        name: 'Connection type',
        rawValueExpression: (plan) => plan.connection_type,
    },
    {
        id: 12,
        name: 'Download speed',
        rawValueExpression: (plan) => plan.speed.down,
        formattedValueExpression: (plan) => 'Up to ' + plan.speed.down + ' Mbps'
    },
    {
        id: 13,
        name: 'Upload speed',
        rawValueExpression: (plan) => plan.speed.up,
        formattedValueExpression: (plan) => 'Up to ' + plan.speed.up + ' Mbps'
    },
    {
        id: 14,
        name: 'Data',
        rawValueExpression: (plan) => plan.data,
        formattedValueExpression (plan) {
            if (plan.data == -1) {
                return 'Unlimited';
            } else {
                return plan.data + 'GB';
            }
        }
    },
    {
        id: 15,
        name: 'Connection fee',
        noticeField: 'connection_fee',
        rawValueExpression: (plan, selectedDetails) => selectedDetails.connection_fee,
        formattedValueExpression: (plan, selectedDetails) => '$' + selectedDetails.connection_fee
    },
    {
        id: 16,
        name: 'Termination fee',
        rawValueExpression: (plan, selectedDetails) => selectedDetails.termination_fee.value,
        component: 'TerminationFee'
    },
    FEATURES_ITEMS_TEMPLATE,
    {
        id: 18,
        name: 'Standard T&Cs',
        rawValueExpression: (plan) => plan.provider.termsCondLink,
        component: 'ProviderTermsCondLink',
    }
];

for (var i in rows_config) {
    if (typeof rows_config[i] != "string"){
        rows_config[i] = Object.assign(cloneObject(default_row_config), rows_config[i]);
        //Id should be a string, because features' columns' ids are strings, and all ids can be in
        //the same hidden_rows array. If you add string to number array, all numbers will become strings
        //and for ex. array.indexOf(6) will not work, only array.indexOf('6'). So we make array's elements'
        //type consistent
        rows_config[i].id = String(rows_config[i].id);
    }
}

export default rows_config;