<template>
    <tr v-if="visible" :class="rowConfig.hidden ? 'hidden-row' : ''">

        <RowMinus v-if="$root.inProfileCartMode" :rowConfig="rowConfig"></RowMinus>

        <td class="name-column">{{rowConfig.name}}</td>

        <template v-for="plan in plans">
            <td :class="[ 'plan-columns', columnsSelector, {grey: areAllParamsSimilar} ]"
                v-if="showRowContentForPlan(plan)">

                <ColumnContent :plan="plan"
                                 :rowConfig="rowConfig"/>

            </td>
            <td v-if="showUnpublishedTip(plan)"
                :rowspan="rowSpanForUnpublishedPlans"
                class="unpublished-plan">
                <div class="text-center">This plan is outdated</div>
            </td>
        </template>
    </tr>
</template>

<script>
    import '../columns/ColumnContent';
    import RowsConfigMixin from '../common/RowsConfigMixin';
    import RowMinus from '../common/RowMinus';

    export default {
        mixins: [ RowsConfigMixin ],
        components: { RowMinus },
        props: {
            rowConfig: Object,
            plans: {
                type: Array,
                default: []
            },
            hidden_parameters_are_shown: {
                type: Boolean,
                default: false
            },
            hide_similar_parameters: {
                type: Boolean,
                default: false
            },
            rowSpanForUnpublishedPlans: {
                type: Number,
                default: null
            },
        },
        computed: {
            columnsSelector() {
                return 'columns-' + this.rowConfig.id;
            },
            visible() {
                return this._isRowVisible(this.rowConfig, this.plans, this.hidden_parameters_are_shown, this.hide_similar_parameters)
            },
            areAllParamsSimilar() {
                return this._areAllPlansSimilarInRow(this.plans, this.rowConfig);
            },
        },
        methods: {
            showRowContentForPlan(plan) {
                return this._showRowContentForPlan(plan, this.rowConfig);
            },
            showUnpublishedTip(plan) {
                return this._showUnpublishedTip(plan, this.rowConfig);
            }
        }
    }
</script>

<style>
    td.unpublished-plan {
        padding: 0.8rem 5px 0.8rem 0;
    }

    td.plan-columns.grey {
        color: #999999;
    }

    td.name-column {
        width: 150px;
    }

    .b-table_comparison tbody tr.hidden-row {
        background: #f2f2f2;
    }
</style>