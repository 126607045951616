<template>
    <div class="container-fluid profile-cart">

        <div v-if="isCompleteStatus && plans.length == 0" class="text-center big-message d-flex">
            <div class="m-auto">
                This list is empty. <a :href="plans_comparison_url">Choose some plans to compare</a>
            </div>
        </div>

        <PlanTable
            ref="plan_table"
            :plans="plans"
            :hide_similar_parameters="hide_similar_parameters"
            :hidden_rows="hidden_rows"
            :sortListForSelect2 = "sortListForSelect2"
            @change-sort="saveSort($event)"
            @change-hide-similar-parameters="saveHideSimilarParameters($event)"
        />

        <div v-if="isLoadedOnce && !isGuest" class="d-lg-flex justify-content-between align-items-center">
            <div class="mb-2 mb-lg-0">
                <button class="btn btn-primary text-uppercase mr-3 mb-2 mb-lg-0"
                    @click="showSaveModal()"
                    :disabled="!isCompleteStatus">
                    save this comparison list</button>

                <ProfileSelect2ActiveList/>

                <span v-if="activeList.has_changes" class="text-gray-dark ml-3">
                    (unsaved changes)
                </span>
            </div>

            <div>
                <button @click="createNewActiveListWithChangesCheck" :disabled="!isCompleteStatus"
                        class="btn btn-primary btn-outline-gray-dark btn-sm">
                    Create new list</button>

                <button @click="showDeleteListModal(activeList)" class="btn btn-outline-gray-dark btn-sm">
                    <i class="fas fa-times fa-danger mr-1"></i>Delete this list
                </button>
            </div>
            <ConfirmationModal ref="delete_confirmation_modal"
                               :title="'Delete comparison list'"
                               :text="'Are you sure that you want to delete current list?'"/>
        </div>

        <ProfileSaveListModal ref="save_modal"/>

        <SaveConfirmationModal ref="save_confirmation_modal"/>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    import { status, LoadingStatusesMixin } from '../../common/LoadingStatusesMixin'
    import DefaultErrorHandlerMixin from '../../common/DefaultErrorHandlerMixin'
    import ProfileSaveListModal from './ProfileSaveListModal'
    import ProfileSelect2ActiveList from './ProfileSelect2ActiveList'
    import SaveConfirmationModal from './SaveConfirmationModal'
    import PlanTable from './table/ResponsivePlanTable'

    export default {
        name: "ProfileCart",
        mixins: [ LoadingStatusesMixin, DefaultErrorHandlerMixin ],
        data: function(){
            return {
                error_message: '',
                modal_list_name: '',
            };
        },
        components: {
            ProfileSaveListModal, ProfileSelect2ActiveList, SaveConfirmationModal, PlanTable
        },
        computed: {
            ...mapGetters({
                plan_ids: 'cart/plan_ids',
                hidden_rows: 'cart/profile/hidden_rows',
                max_list_length: 'cart/profile/max_list_length',
                plans: 'cart/profile/plans',
                activeList: 'cart/profile/activeList',
                lists: 'cart/profile/lists',
                isGuest: 'common/isGuest',
                plans_comparison_url: 'common/plans_comparison_url',
                //sort: 'cart/profile/sort',
                hide_similar_parameters: 'cart/profile/hide_similar_parameters',
                sortListForSelect2: 'cart/profile/sortListForSelect2',
            }),
        },
        created: function() {
            if (!this.isGuest) {
                this.$store.dispatch('cart/profile/getLists')
                    .then(
                        this.updatePlansFromActiveList,
                    )
                    .catch(this.defaultErrorHandler);
            } else {
                this.updatePlansFromActiveList().catch(this.defaultErrorHandler);
            }
        },
        methods: {
            updatePlansFromActiveList() {
                var vm = this;

                this.status = status.LOADING;

                return this.$store.dispatch('cart/profile/getActiveListPlans').then(
                    () => {
                        if (!vm.isLoadedOnce) {
                            vm.$watch('plan_ids', function() {
                                vm.$store.dispatch('cart/profile/updatePlansFromIds', this.plan_ids);
                            });
                        }

                        vm.status = status.COMPLETE;
                    }
                );
            },
            showDeleteListModal(list) {
                this.$refs.delete_confirmation_modal.show(
                    () => {
                        //Yes button callback
                        this.deleteList(list);
                    },
                    null,
                    () => {} //Cancel button
                );
            },
            deleteList(list) {
                var vm = this;

                this.$store.dispatch('cart/profile/deleteList', list.id)
                .then(
                    () => {
                        return vm.$store.dispatch('cart/profile/getLists')
                    })
                .then(
                    () => {
                       /* if (vm.lists.length > 0 && !vm.lists[0].is_active && !vm.activeList) {
                            return vm.$store.dispatch('cart/setListActive', vm.lists[0].id);
                        } else {*/
                            return vm.updatePlansFromActiveList();
                        //}
                    }
                )
                .catch(vm.defaultErrorHandler);
            },
            createNewActiveList() {
                return this.$store.dispatch('cart/profile/createNewActiveList')
                    .then(
                        () => {
                            this.$store.commit('cart/profile/setPlans', []);
                            return this.$store.dispatch('cart/profile/getLists');
                        },
                    )
                    .catch(this.defaultErrorHandler);
            },
            showSaveModal() {
                this.$refs.save_modal.show();
            },
            createNewActiveListWithChangesCheck() {
                this.$refs.save_confirmation_modal.show(this.createNewActiveList);
            },
            saveSort(newSort) {
                this.$store.dispatch('cart/profile/setSort', newSort)
                    .then(
                        () => {
                            this.activeList.has_changes = true;
                        }
                    )
                    .catch(this.defaultErrorHandler);
            },
            saveHideSimilarParameters(value) {
                this.$store.dispatch('cart/profile/setHideSimilarParameters', value)
                    .then(
                        () => {
                            this.activeList.has_changes = true;
                        }
                    )
                    .catch(this.defaultErrorHandler);
            }
        },
        watch: {
            status() {
                this.$refs.plan_table.status = this.status;
            }
        }
    }
</script>

<style>
    .hide_similar_parameters.b-form-field_checkbox .b-form-field__view {
        margin-right: 5px;
    }

    .profile-cart {
        margin-bottom: 100px;
    }
</style>
