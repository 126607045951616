<template>
    <a :href="plans_comparison_url" v-if="$root.inProfileCartMode"
       class="btn btn-sm btn-primary ml-lg-auto">
        Add more plans
    </a>
</template>

<script>
    import {mapGetters} from 'vuex';

    export default {
        computed: {
            ...mapGetters({
                plans_comparison_url: 'common/plans_comparison_url',
            }),
        },
    }
</script>