<template>
    <div>
        <template v-for="block in blocks">
            <template v-if="block.type == 'raw_html'">
                <span v-html="block.html"></span>
            </template>

            <Plan v-if="block.type == 'horizontal_plan'" :plan="block.plan">
            </Plan>

            <PlanComparisonTable
                    v-if="block.type == 'plan_table'"
                    :plans="block.plans"
                    :sortListForSelect2="sortListForSelect2">
            </PlanComparisonTable>

            <template v-if="block.type == 'plan_list'">
                <div class="special">
                    <div class="b-hr"></div>
                    <PlansList :enabled_connection_types="enabled_connection_types" :addressIsRequired="addressIsRequired">
                        <div v-if="showConnectionFilters" class="form-group mb-3">
                            <ConnectionTypeFilter/>
                        </div>
                    </PlansList>
                    <AddressAutocompleteModal :address-is-required="addressIsRequired"/>
                </div>
            </template>
        </template>
    </div>
</template>

<script>
import Plan from './plan/Plan';
import PlansList from './plan/PlansList'
import PlanComparisonTable from './cart/profile/table/ResponsivePlanTable'
import {mapGetters} from 'vuex'
import {cloneObject} from "../js/utils";
import AddressAutocompleteModal from './autocomplete/AddressAutocompleteModal'
import ConnectionTypeFilter from "./ConnectionTypeFilter";

export default {
        name: "SpecialPage",
        components: {ConnectionTypeFilter, Plan, PlansList, PlanComparisonTable, AddressAutocompleteModal },
        computed: {
            ...mapGetters([
                'enabled_connection_types',
                'sortListForSelect2',
                'ignore_address_coverage',
                'filters',
                'isAddressEmpty',
            ]),
            addressIsRequired: function () {
                return Boolean(this.filters.tracking_enabled);
            },
            showConnectionFilters: function () {
                return this.$store.state.plans.showConnectionTypeFilter;
            },
        },
        data: function() {
            var parts = this.$root.inner_html_on_init
                .split( /(<div data-plan-id="\d+"><\/div>|<div data-plan-list="[^><]*"><\/div>|<div data-plan-table="\d+"><\/div>)/i );

            var blocks = [];

            for(var i in parts) {
                var match;
                if (match = parts[i].match( /<div data-plan-id="(\d+)"><\/div>/i )) {
                    blocks.push({
                        type: 'horizontal_plan',
                        plan: plans_special[match[1]],
                    });
                } else if (match = parts[i].match( /<div data-plan-list="([^><]*)"><\/div>/i )) {
                    blocks.push({
                        type: 'plan_list',
                    });

                    //getting default init config
                    let plansList_config = cloneObject(vue_store_init.plans);
                    //adding override get parameter from data parameter
                    plansList_config['get_from_yii'] = JSON.parse(match[1].replace(/&quot;/g, '"'));
                    this.$store.dispatch('init', {
                        init_data: plansList_config,
                        allow_advanced_filtering: true
                    });
                } else if (match = parts[i].match( /<div data-plan-table="(\d+)"><\/div>/i )) {
                    blocks.push({
                        type: 'plan_table',
                        plans: plans_table[match[1]]
                    });
                }else {
                    blocks.push({
                        type: 'raw_html',
                        html: parts[i]
                    });
                }
            }

            return {
                blocks: blocks,
            };
        },
    }
</script>
