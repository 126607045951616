import Vue from "vue";
import Vuex from "vuex";
import common from './modules/common'

Vue.use(Vuex);

if (typeof (vue_debug_mode) != 'undefined') {
    Vue.config.devtools = vue_debug_mode;
}

export default new Vuex.Store({
    modules: {
        common,
    },
});