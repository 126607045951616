<template>
    <span :class="{'got-a-notice': notice!=''}"
          v-b-tooltip.hover
          :title="notice"
        ref="span">
        <slot>
        </slot>
    </span>
</template>

<script>
    export default {
        props: {
            field: String,
            plan: Object
        },
        computed: {
            notice()
            {
                if (this.plan.fields_notices.hasOwnProperty(this.field)) {
                    return this.plan.fields_notices[this.field];
                }

                //Hard remove of bootstrap tooltip
                this.$nextTick(function() {
                    this.$refs.span.removeAttribute('data-original-title');
                });

                return '';
            }
        }
    }
</script>