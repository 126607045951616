<template>
    <div class="container-fluid">
        <div class="d-lg-flex justify-content-between align-items-center"  v-if="!isAddressEmpty">
            <div class="b-address mb-3 mb-lg-0">
                <AddressAutocomplete/>
            </div>
        </div>

        <FixedAlert v-if="isAddressEmpty" :bold="true" :top="400">
            <span>Some plans may not be available at your property. <a href="#" data-remodal-target="changeAddress">Enter your address</a> to check.</span>
        </FixedAlert>
        <AlertIgnoreAddress v-else :top="400"/>

        <AlertExpiredPlan/>

        <div class="b-hr"></div>

        <PlansList :errorMessageNoPlans="isAddressEmpty ? 'Plans aren\'t found' : 'No plans available at your address'"/>

        <AddressAutocompleteModal :addressIsRequired="false"/>
        <AvailableConnectionsModal :showChooseButtons="false" :autoOpen="false"/>
    </div>
</template>

<script>
    import PlansList from './plan/PlansList'
    import AddressAutocomplete from './autocomplete/AddressAutocomplete'
    import AddressAutocompleteModal from './autocomplete/AddressAutocompleteModal'
    import AlertIgnoreAddress from './AlertIgnoreAddress'
    import FixedAlert from './FixedAlert'
    import AvailableConnectionsModal from './AvailableConnectionsModal'
    import { mapGetters } from 'vuex'
    import AlertExpiredPlan from './AlertExpiredPlan';

    export default {
        components: {
            PlansList, AddressAutocomplete, AddressAutocompleteModal, AlertIgnoreAddress, FixedAlert,
            AvailableConnectionsModal, AlertExpiredPlan
        },
        computed: {
            ...mapGetters([
                'isAddressEmpty',
            ]),
        },
    }
</script>