<template>
    <Select2 :options="sortListForSelect2" v-model="sort"
             :settings="{ width: '150px', minimumResultsForSearch: -1 }"/>
</template>

<script>
    import { mapGetters } from 'vuex'

    export default {
        computed: {
            ...mapGetters([
                'sortListForSelect2',
            ]),
            sort: {
                get () {
                    return this.$store.state.plans.filters.sort;
                },
                set (value) {
                    this.$store.commit('setSort', value);
                }
            },
        }
    }
</script>