<template>
    <div class="remodal remodal_big" data-remodal-id="availableModal">
        <div class="remodal__header">
            <h2 class="remodal__title">{{currentAddress}}</h2>
        </div>
        <div class="remodal__body mb-3">

            <div class="text-center d-sm-none" v-if="gotAvailableConnections && showChooseButtons">
                <button v-if="best_type !== 'cable'" @click="showPlans(best_type)" class="btn btn-primary">Show best available plans</button>
                <a v-else @click.prevent="showPlans()" href="#" class="b-link b-link_primary d-block">Show all available plans</a>
            </div>

            <div class="mb-4 mt-4" v-if="getText(best_type)">
                <span v-html="getText(best_type)"></span>
            </div>

            <div class="mb-4">
                <div class="b-available-connection d-lg-flex justify-content-center pb-5">
                    <div
                        v-for="(connection_label, connection_type) in available_connection_types_list_for_modal"
                        :class="{
                        'b-connection ml-3 mr-3 mb-3': true,
                        'b-best-connection': connection_type === best_type }"
                        >
                        <span v-if="connection_type === best_type" class="mb-2 d-block">Best connection</span>

                        <span v-if="connection_type !== best_type" class="mb-2 d-none d-block">Available</span>

                        <div class="b-item pt-2">
                            <img v-bind:src="getSpeedIconUrl(connection_type)" alt="" class="b-feature__ico mr-0 mb-0"/>
                            <b>{{connection_label}}</b>
                            <p class="pl-2 pr-2">{{getSpeedHint(connection_type)}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="remodal__footer text-center">
            <template v-if="gotAvailableConnections && showChooseButtons">
                <button v-if="best_type !== 'cable'" @click="showPlans(best_type)" class="btn btn-primary mb-3">Show best available plans</button>
                <a @click.prevent="showPlans()" href="#" class="b-link b-link_primary d-block">Show all available plans</a>
            </template>
            <template v-else-if="!is_it_default_site_preset">
                <GoToDefaultPresetText/>
                <div class="go-to-default-preset-btn-container mt-4">
                    <a class="btn btn-primary mb-2 mt-4" :href="defaultPresetComparePageWithCurrentFilters">Broadband Compare site</a>
                </div>
            </template>
            <template v-else>
                <button data-remodal-action="confirm" class="btn btn-primary mb-2">GOT IT</button>
            </template>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    import Vue from 'vue';

    Vue.component('GoToDefaultPresetText', {
        computed: {
            ...mapGetters([
                'go_to_default_preset_connections_modal_text',
                'defaultPresetComparePageWithCurrentFilters'
            ])
        },
        render: function (createElement) {
            const match = /(.*){link}(.+){\/link}(.*)/.exec(this.go_to_default_preset_connections_modal_text);

            if (match == null) {
                return createElement('span', this.go_to_default_preset_connections_modal_text);
            }

            return createElement(
                'span',
                [
                    match[1],
                    createElement(
                        'a',
                        {
                            domProps: {
                                href: this.defaultPresetComparePageWithCurrentFilters
                            },
                        },
                        match[2],
                    ),
                    match[3]
                ]
            )
        },
    });

    export default {
        props: {
            showChooseButtons: {
                type: Boolean,
                default: true
            },
            autoOpen: {
                type: Boolean,
                default: true
            },
        },
        mounted() {
            $('[data-remodal-id="availableModal"]').remodal({
                hashTracking: false
            });
        },
        computed: {
            ...mapGetters([
                'connection_types_list',
                'connection_types_list_for_modal',
                'connection_types_for_modal_speed_hints',
                'connection_types_for_modal_speeds',
                'connection_types_for_modal_texts',
                'currentAddress',
                'enabled_connection_types',
                'connection_types_priority_list',
                'show_availability_modal',
                'is_it_default_site_preset',
                'defaultPresetComparePageWithCurrentFilters',
            ]),
            ...mapGetters({
                speedIconUrl: 'common/speedIconUrl'
            }),
            available_connection_types_list_for_modal() {
                const result = {};
                for (let connection_type in this.connection_types_list_for_modal) {
                    if (this.isAvailable(connection_type)) {
                        result[connection_type] = this.connection_types_list_for_modal[connection_type];
                    }
                }
                return result;
            },
            best_type() {
                for (let i in this.connection_types_priority_list) {
                    const type = this.connection_types_priority_list[i];
                    if (this.enabled_connection_types.indexOf(type) !== -1) {
                        return type;
                    }
                }

                return this.enabled_connection_types[0];
            },
            gotAvailableConnections()
            {
                return Object.keys(this.available_connection_types_list_for_modal).length > 0
            },
            best_available_plans_filters() {
                return this.$store.state.plans.best_available_plans_filters;
            }
        },
        methods: {
            getSpeedIconUrl(type) {
                if (this.connection_types_for_modal_speeds.hasOwnProperty(type)) {
                    return this.speedIconUrl(this.connection_types_for_modal_speeds[type]);
                }
            },
            getSpeedHint(type) {
                if (this.connection_types_for_modal_speed_hints.hasOwnProperty(type)) {
                    return this.connection_types_for_modal_speed_hints[type];
                }
            },
            getText(type) {
                if (this.connection_types_for_modal_texts.hasOwnProperty(type)) {
                    return this.connection_types_for_modal_texts[type];
                }
            },
            isAvailable(type) {
                return this.enabled_connection_types.indexOf(type) !== -1
            },
            showPlans(type) {
                const connection_types = {};

                for(let i in this.connection_types_list) {
                    connection_types[i] = false;
                }

                if (type !== undefined) {
                    for(let i in this.best_available_plans_filters) {
                        connection_types[this.best_available_plans_filters[i]] = true;
                    }
                    connection_types[type] = true;
                    this.$store.dispatch('showAlertConnectionType', this.connection_types_list_for_modal[type]);

                    if (type === 'fibre' || type === 'hyper') {
                        connection_types['fibre'] = true;
                        connection_types['cable'] = true;
                    }
                } else {
                    this.$store.dispatch('disableAlertConnectionType');
                }

                this.$store.commit('setFilters', {
                    connection_types: connection_types
                });

                $('[data-remodal-id="availableModal"]').remodal().close();
            }
        },
        watch: {
            show_availability_modal(value) {
                if (value && this.autoOpen)
                    $('[data-remodal-id="availableModal"]').remodal().open();
            }
        }
    }
</script>

<style>
.go-to-default-preset-btn-container {
    border-top: 1px solid #ebebeb;
    color: white;
}
</style>
