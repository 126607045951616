<template>
    <td class="minus-column">
        <span :class="{ minus_container: true, disabled: rowConfig.showUnpublishedTip }"
              @click="toggleVisibility()">
            <i v-b-tooltip.hover
               :disabled="rowConfig.showUnpublishedTip"
               :title="tooltipText"
               :class="{'fas': true, 'fa-minus': !rowConfig.hidden, 'fa-plus': rowConfig.hidden}"></i>
        </span>
    </td>
</template>

<script>
    import DefaultErrorHandlerMixin from '../../../../common/DefaultErrorHandlerMixin'

    export default {
        mixins: [ DefaultErrorHandlerMixin ],
        props: {
            rowConfig: Object
        },
        computed: {
            tooltipText() {
                if (this.rowConfig.showUnpublishedTip) {
                    return '';
                } else {
                    return !this.rowConfig.hidden ? 'Hide row' : 'Show row';
                }
            }
        },
        methods: {
            toggleVisibility() {
                if (this.rowConfig.showUnpublishedTip) {
                    return;
                }

                var promise;
                if(this.rowConfig.hidden) {
                    promise = this.$store.dispatch('cart/profile/showCartRow', this.rowConfig.id);
                }
                else {
                    promise = this.$store.dispatch('cart/profile/hideCartRow', this.rowConfig.id);
                }

                promise.then(
                    () => {
                        this.$store.dispatch('cart/profile/activeListChanged');
                    }
                ).catch(this.defaultErrorHandler);
            },
        },
    }
</script>