<template>
    <div class="text-center comparison-loader">
        <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
    </div>
</template>

<script>
    export default {
        name: "CircleLoader"
    }
</script>
