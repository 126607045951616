<template>
    <table class="b-table b-table_comparison">
        <thead>
        <tr>
            <th>
                <PlanTableHeader :plan="plan"></PlanTableHeader>
            </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="rowConfig in rowsConfig" v-if="visible(rowConfig)">
            <td v-if="showRowContent(rowConfig)"
                :class="{'mobile-plan-columns':true, grey: areAllParamsSimilar(rowConfig)}">
                <ColumnContent :plan="plan" :rowConfig="rowConfig"/>
            </td>
            <td v-if="showUnpublishedTip(rowConfig)"
                :rowspan="rowSpanForUnpublishedPlans"
                class="unpublished-plan">
                <div class="text-center">This plan is outdated</div>
            </td>
        </tr>
        </tbody>
    </table>
</template>

<script>
    import RowsConfigMixin from '../common/RowsConfigMixin';
    import DefaultErrorHandlerMixin from '../../../../common/DefaultErrorHandlerMixin'
    import '../columns/ColumnContent';
    import PlanTableHeader from '../common/PlanTableHeader';

    export default {
        mixins: [ DefaultErrorHandlerMixin, RowsConfigMixin ],
        components: {
            PlanTableHeader
        },
        props: {
            rowsConfig: Object,
            plans: Array,
            plan: {
                Object
            },
            hidden_parameters_are_shown: false,
            hide_similar_parameters: false,
            rowSpanForUnpublishedPlans: null
        },
        methods: {
            showRowContent(rowConfig) {
                return this._showRowContentForPlan(this.plan, rowConfig);
            },
            showUnpublishedTip(rowConfig) {
                return this._showUnpublishedTip(this.plan, rowConfig);
            },
            visible(rowConfig) {
                return this._isRowVisible(rowConfig, this.plans, this.hidden_parameters_are_shown, this.hide_similar_parameters)
            },
            areAllParamsSimilar(rowConfig) {
                return this._areAllPlansSimilarInRow(this.plans, rowConfig);
            },
        },
    }
</script>

<style>
    td.mobile-plan-columns.grey {
        color: #bbbbbb;
    }
</style>