<template>
    <div class="table-mobile clearfix" :class="{ dimmed: isLoadingStatus }">
        <div class="b-table-title">
            <table class="b-table b-table_comparison">
                <thead>
                <tr>
                    <RowMinusHeader v-if="$root.inProfileCartMode"/>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                    <MobilePlanTableColumnTitle
                                        v-for="rowConfig in rowsConfig" :key="rowConfig.id"
                                        v-if="visible(rowConfig)"
                                        :rowConfig="rowConfig">
                    </MobilePlanTableColumnTitle>
                </tbody>
            </table>
        </div>
        <div class="b-table-content">
            <div class="b-table-content-item"
                 v-for="plan in plans">
                    <MobilePlanTablePlan
                        :plan="plan"
                        :plans="plans"
                        :rowsConfig="rowsConfig"
                        :hidden_parameters_are_shown="hidden_parameters_are_shown"
                        :hide_similar_parameters="hide_similar_parameters"/>
            </div>
        </div>
    </div>
</template>

<script>
    import MobilePlanTableColumnTitle from './MobilePlanTableColumnTitle';
    import MobilePlanTablePlan from './MobilePlanTablePlan';
    import RowsConfigMixin from '../common/RowsConfigMixin';
    import RowMinusHeader from '../common/RowMinusHeader';

    export default {
        mixins: [ RowsConfigMixin ],
        props: {
            plans: Array,
            rowsConfig: Array,
            hide_similar_parameters: {
                type: Boolean,
                default: false
            },
            hidden_parameters_are_shown: {
                type: Boolean,
                default: false
            }
        },
        components: {
            MobilePlanTableColumnTitle,
            MobilePlanTablePlan,
            RowMinusHeader
        },
        mounted() {
            this.initPlansOwlCarousel();
            this.adjustRowsHeights();

            $(window).on('resize', () => {
                this.adjustRowsHeights();
            });
        },
        methods: {
            visible(rowConfig) {
                return this._isRowVisible(rowConfig, this.plans, this.hidden_parameters_are_shown, this.hide_similar_parameters)
            },
            initPlansOwlCarousel() {
                if (this.plans.length > 1) {
                    $('.table-mobile .b-table-content').owlCarousel({
                        loop: false,
                        rewind: true,
                        nav: true,
                        smartSpeed: 900,
                        navText: ["<i class=''></i>", "click for see next plan <i class='fa fa-chevron-right'></i>"],
                        dots: false,
                        responsive: {
                            0: {
                                nav: true,
                                items: 1
                            },
                            600: {
                                nav: true,
                                items: 1
                            },
                            1000: {
                                nav: true,
                                items: 1
                            }
                        }
                    });
                }
            },
            adjustRowsHeights() {
                this.$nextTick(() => {
                    var tableHeight = {};

                    $('.table-mobile table').each(function () {
                        $(this).find('tr').each(function (i) {
                            const currentRow = tableHeight[i];
                            if (currentRow) {
                                currentRow.push(this.clientHeight);
                            } else {
                                tableHeight[String(i)] = [this.clientHeight];
                            }
                        });
                    });

                    Object.keys(tableHeight).map(value => {
                        tableHeight[value] = tableHeight[value].reduce(function (a, b) {
                            return a > b ? a : b;
                        });
                    });

                    $('.table-mobile table').each(function () {
                        $(this).find('tr').height(function (i, val) {
                            val = tableHeight[i];
                            return val;
                        });
                    });
                });
            }
        },
        watch: {
            rowsConfig: {
                handler() {
                    this.adjustRowsHeights();
                },
                deep: true,
            },
            hide_similar_parameters() {
                this.adjustRowsHeights();
            },
            hidden_parameters_are_shown() {
                this.adjustRowsHeights();
            },
            plans(newPlans, oldPlans) {
                for (let i in oldPlans) {
                    if (!newPlans.hasOwnProperty(i) || newPlans[i].id != oldPlans[i].id) {
                        $('.table-mobile .b-table-content').trigger('remove.owl.carousel', [i]).trigger('refresh.owl.carousel');
                        return;
                    }
                }
            }
        }
    }
</script>