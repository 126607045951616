<script>
    import ComparePage from '../ComparePage';
    export default {
      components: { ComparePage },
      props: ['count']
    };
</script>
<template>
    <content-placeholders :rounded="true">
        <div class="plan-skeleton mb-4" v-for="n in count">
            <div class="plan-skeleton__header row">
                <div class="col-lg-6">
                    <content-placeholders-text :lines="1" />
                </div>
            </div>
            <div class="row d-flex align-items-center">
                <div class="col-lg-2">
                    <content-placeholders-img/>
                </div>
                <div class="col-lg-2">
                    <content-placeholders-text :lines="5" />
                </div>
                <div class="col-lg-2">
                    <content-placeholders-text :lines="5" />
                </div>
                <div class="col-lg-2">
                    <content-placeholders-text :lines="5" />
                </div>
                <div class="col-lg-2">
                    <content-placeholders-text :lines="5" />
                </div>
                <div class="col-lg-2">
                    <content-placeholders-text :lines="4" />
                </div>
            </div>
        </div>
    </content-placeholders>
</template>
