<template>
    <ul class="b-pagination" v-if="pageCount > 1">
        <li class="b-pagination__page-item" v-if="currentPage != 1">
            <a class="b-pagination__page-link b-pagination__page-link_arrow" href="#"
               @click.prevent="setCurrentPage(currentPage-1)">
                <i class="fas fa-arrow-left"></i>
            </a>
        </li>

        <template v-for="page in pageSequence">
            <li class="b-pagination__page-item">
                <a v-if="page != null" class="b-pagination__page-link" :class="{ 'b-pagination__page-link_active': currentPage == page }"
                   href="#" @click.prevent="setCurrentPage(page)">
                    {{page}}</a>
                <template v-if="page == null">...</template>
            </li>
        </template>

        <li  class="b-pagination__page-item" v-if="currentPage != pageCount">
            <a class="b-pagination__page-link b-pagination__page-link_arrow" href="#"
               @click.prevent="setCurrentPage(currentPage+1)">
                <i class="fas fa-arrow-right"></i>
            </a>
        </li>
    </ul>
</template>

<script>
    export default {
        props: {
            pageCount: {
                type: Number,
                required: true,
            },
            page: {
                type: Number,
                required: true,
            },
            radius: {
                type: Number,
                default: 5,
            }
        },
        data() {
            return {
                currentPage: this.page
            };
        },
        computed: {
            pageSequence: function() {
                var sequence = [];

                if (this.pageCount <= this.radius * 2) {

                    for(var i = 1; i <= this.pageCount; i++) {
                        sequence.push(i);
                    }

                } else {

                    if (this.currentPage <= this.radius+1) {

                        var end_page = this.currentPage + this.radius;

                        //Beginning
                        for(var i = 1; i <= end_page; i++) {
                            sequence.push(i);
                        }

                        if (this.pageCount > end_page) {
                            //null means '...'
                            sequence.push(null);
                        } else {
                            sequence.push(this.pageCount - 1);
                        }
                        sequence.push(this.pageCount);

                    } else if (this.currentPage >= this.pageCount - this.radius) {

                        //Last pages
                        sequence.push(1);
                        sequence.push(null);

                        for(var i = this.currentPage - this.radius; i<=this.pageCount; i++) {
                            sequence.push(i);
                        }

                    } else {

                        //Middle
                        sequence.push(1);

                        if (this.currentPage - this.radius > 2)
                            sequence.push(null);


                        for(var i = this.currentPage - this.radius; i <= this.currentPage + this.radius; i++) {
                            sequence.push(i);
                        }

                        if (this.currentPage + this.radius < this.pageCount - 1)
                            sequence.push(null);

                        sequence.push(this.pageCount);

                    }
                }

                return sequence;
            }
        },
        methods: {
            setCurrentPage: function(newPage) {
                this.currentPage = newPage;
                this.$emit('update', newPage);
            }
        }
    }
</script>
