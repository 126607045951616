<template>
    <div v-if="(hidden_parameters_count > 0 && !hidden_parameters_are_shown) || hidden_parameters_are_shown"
         class="profile-plans-down"
         data-test-id="comparison-cart-expand-similar-parameters-button">
        <button @click="hidden_parameters_are_shown = !hidden_parameters_are_shown" class="btn btn-primary btn-outline-gray-dark btn-sm">
            <template v-if="hidden_parameters_are_shown">
                collapse hidden parameters
            </template>
            <template v-else>
                expand hidden parameters
            </template>
        </button>
        <span v-if="!hidden_parameters_are_shown">
           {{hidden_parameters_count_formatted}}
       </span>
    </div>
</template>

<script>
    export default {
        props: {
            hidden_parameters_count: {
                type: Number
            },
            hidden_parameters_are_shown: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            hidden_parameters_count_formatted() {
                if (this.hidden_parameters_count != 1) {
                    return this.hidden_parameters_count + ' parameters are hidden';
                } else {
                    return 'One parameter is hidden';
                }
            },
        },
        watch: {
            hidden_parameters_are_shown: function(newValue) {
                this.$emit('update:hidden_parameters_are_shown', newValue);
            }
        }
    }
</script>


<style>
    .profile-plans-down {
        margin-bottom: 10px;
    }

    .profile-plans-down span {
        color: #555555;
        font-size: 14px;
        display: inline-block;
        vertical-align: top;
        padding-top: 5px;
    }

    .profile-plans-down button
    {
        margin-right: 10px;
    }
</style>