function parseIntParam(param, default_value) {
    param = parseInt(param);
    if (isNaN(param)) {
        param = default_value;
    }
    return param;
}

//inits checkbox list with all existing options
function initCheckboxList(filter_values, filter_all_options) {
    for (var filter_option in filter_all_options) {
        if (!filter_values.hasOwnProperty(filter_option)) {
            filter_values[filter_option] = 0;
        }
    }
}

function initAdvancedFilters(values, init_data)
{
    var adv_filters = values.advanced_filters;

    //Set default sliders values if not set
    adv_filters.price_min = parseIntParam(adv_filters.price_min, init_data.sliders_range.price.min);
    adv_filters.price_max = parseIntParam(adv_filters.price_max, init_data.sliders_range.price.max);
    adv_filters.data_min = parseIntParam(adv_filters.data_min, init_data.sliders_range.data.min);
    adv_filters.data_max = parseIntParam(adv_filters.data_max, init_data.sliders_range.data.max);
    adv_filters.speed_min = parseIntParam(adv_filters.speed_min, init_data.sliders_range.speed.min);
    adv_filters.speed_max = parseIntParam(adv_filters.speed_max, init_data.sliders_range.speed.max);

    if (typeof(adv_filters['provider_ids']) == 'object') {
        //Convert object to array
        adv_filters.provider_ids = Object.values(adv_filters.provider_ids);
    }

    adv_filters.features = convertApiListToCheckboxBooleanList(adv_filters.features);
    adv_filters.contract_options = convertApiListToCheckboxBooleanList(adv_filters.contract_options);
    initCheckboxList(adv_filters.features, init_data.features_list);
    initCheckboxList(adv_filters.contract_options, init_data.contract_options_list);
}

function initSimpleFilters(values, init_data)
{
    values.connection_types = convertApiListToCheckboxBooleanList(values.connection_types);
    values.quick_filters = convertApiListToCheckboxBooleanList(values.quick_filters);
    initCheckboxList(values.quick_filters, init_data.quick_filters_list);
    initCheckboxList(values.connection_types, init_data.connection_types_list);
}

function convertApiListToCheckboxBooleanList(apiList) {
    var result = {};
    for (var i in apiList) {
        var name = apiList[i];
        result[name] = 1;
    }

    return result;
}

import { getPriceTypeChangeDictionariesPatch } from "./price_type_slider_patch";
export function convertPriceSliderRange(init_data, priceType) {
    //Convert price slider range according to price_type
    var months = init_data.price_types_months;
    if (months[priceType] != 1) {
        var oldSlidersRangePrice = init_data.sliders_range.price;
        var convertRatio = months[priceType];

        var dictionaries_convert_patch = getPriceTypeChangeDictionariesPatch(
            oldSlidersRangePrice,
            convertRatio);

        init_data = mergeDeep(init_data, dictionaries_convert_patch);
    }
}

import {mergeDeep, cloneObject, GET} from "../../../utils";
export default function (filters_init, init_data, allow_advanced_filtering) {
    filters_init = cloneObject(filters_init);

    //Init data from var
    if (init_data.hasOwnProperty('filters')) {
        filters_init = mergeDeep(filters_init, init_data.filters);
    }

    //Init data from query
    var currentGet = GET();

    if (init_data.hasOwnProperty('get_from_yii')
        && init_data['get_from_yii'] != undefined) {
        //GET parameters passed from backend
        currentGet = mergeDeep(init_data.get_from_yii, currentGet);
    }

    if (currentGet.hasOwnProperty('f')) {
        if (!allow_advanced_filtering && currentGet.f.hasOwnProperty('advanced_filters')) {
            delete currentGet.f.advanced_filters;
        }

        filters_init = mergeDeep(filters_init, currentGet.f);
    }

    if (allow_advanced_filtering) {
        //For "unlimited" text in sliders
        init_data.dictionaries.sliders_range.data.max++;
        init_data.dictionaries.sliders_range.price.max++;
        init_data.dictionaries.sliders_range.speed.max++;

        //Convert price slider range from 1 month range to current price type range (year, two years etc)
        convertPriceSliderRange(init_data.dictionaries, filters_init.price_type);
        initAdvancedFilters(filters_init, init_data.dictionaries);
    }
    initSimpleFilters(filters_init, init_data.dictionaries);

    return filters_init;
}
