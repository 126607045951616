<template>
    <div>
        <div class="alert alert-expired-plan alert-address-bold" role="alert" v-if="showMessageInPlanView">
            {{showMessageInPlanView}}
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';

    export default {
        computed: {
            ...mapGetters([
                'showMessageInPlanView'
            ]),
        },
    }
</script>