<template>
    <!-- Plan name -->
    <span>
        <a v-if="plan.status == 'published'" :href="plan.url" class="b-link b-link_primary">{{plan.name}}</a>
        <span v-else>{{plan.name}}</span>
    </span>
</template>

<script>
    export default {
        props: {
            plan: Object
        }
    }
</script>