import { mapGetters } from 'vuex'

export default {
    computed: {
        ...mapGetters({
            getSelectedDetails: 'cart/profile/getSelectedDetails',
        }),
    },
    methods: {
        _areAllPlansSimilarInRow(plans, rowConfig) {
            if (plans.length <= 1 || !rowConfig.rawValueExpression)
                return false;

            var previous_value;
            var previous_notice;
            for (let i in plans) {
                if (plans[i].status != 'published' && rowConfig.hideIfPlanNotPublished) {
                    continue;
                }

                let current_value = rowConfig.rawValueExpression(plans[i], this.getSelectedDetails(plans[i]));
                let current_notice = this._getFieldNotice(rowConfig, plans[i]);

                if (i != 0 && (current_value != previous_value || current_notice != previous_notice))
                    return false;

                previous_value = current_value;
                previous_notice = current_notice;
            }

            return true;
        },
        _rowSpanForUnpublishedPlans(rowsConfig) {
            var rows_count_visible_on_unpublished = rowsConfig.filter((row) => {
                return !row.hideIfPlanNotPublished
            }).length;

            return rowsConfig.length - rows_count_visible_on_unpublished;
        },
        _getFieldNotice(rowConfig, plan) {
            var fieldNameForNotice = this._getFieldNameForNotice(rowConfig);
            if (fieldNameForNotice == '') {
                return '';
            }

            var selectedPlanDetails = this.getSelectedDetails(plan);

            if (selectedPlanDetails.fields_notices.hasOwnProperty(fieldNameForNotice)) {
                return selectedPlanDetails.fields_notices[fieldNameForNotice];
            }
            return '';
        },
        _getFieldNameForNotice(rowConfig) {
            if (rowConfig.hasOwnProperty('noticeField')) {
                return rowConfig['noticeField'];
            }
            return '';
        },
        _hiddenParametersCount(plans, rowsConfig, hide_similar_parameters) {
            var count = 0;
            for (var i in rowsConfig) {
                var rowConfig = rowsConfig[i];

                if (
                    //Manually hidden row
                    rowConfig.hidden && !(this._areAllPlansSimilarInRow(plans, rowConfig) && hide_similar_parameters)) {
                    count++;
                }
            }
            return count;
        },
        _isRowVisible(rowConfig, plans, hidden_parameters_are_shown, hide_similar_parameters) {
            return !((rowConfig.hidden && !hidden_parameters_are_shown) || (this._areAllPlansSimilarInRow(plans, rowConfig) && hide_similar_parameters));
        },
        _showRowContentForPlan(plan, rowConfig) {
            return plan.status == 'published' || (plan.status != 'published' && rowConfig.hideIfPlanNotPublished == false);
        },
        _showUnpublishedTip(plan, rowConfig) {
            return plan.status != 'published' && rowConfig.showUnpublishedTip
        }
    },
};
