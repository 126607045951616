<template>
    <div>
        <div :class="{'alert alert-address':true, 'alert-address-bold': bold}" id="alertPanel" role="alert">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            bold: {
                type: Boolean,
                default: false
            },
            top: {
                type: Number,
                default: 300
            }
        },
        mounted: function() {
            if (document.getElementById('alertPanel') && document.documentElement.clientWidth >= 768) {
                jSticky('alertPanel', {
                    top: this.top,
                    classActive: 'b-stripe_alertPanel_active',
                    clientHeight: false
                });
            }
        },
        updated: function() {
            if (document.getElementById('alertPanel') && document.documentElement.clientWidth >= 768) {
                jSticky('alertPanel', {
                    top: this.top,
                    classActive: 'b-stripe_alertPanel_active',
                    clientHeight: false
                });
            }
        }
    }
</script>

<style>
    .alert-address {
        background: #ffba00;
    }

    .alert-address a {
        text-decoration: underline;
        color: black;
    }

    .alert-address-bold {
        font-weight: bold;
    }
</style>