<template>
    <div>
        <input type="hidden" :name="form_name_additional() + '[has_promo_price]'"
               :value="selected_plan.has_promo_price">
        <input type="hidden" v-if="selected_plan.has_promo_price"
               :name="form_name_additional() + '[promo_price_with_features]'"
               :value="'$' + promo_price_with_features.toFixed(2)">
        <input type="hidden" v-if="selected_plan.has_promo_price" :name="form_name_additional() + '[promo_length]'"
               :value="selected_plan.promo_length">
        <input type="hidden" :name="form_name_additional() + '[price_with_features]'"
               :value="'$' + price_with_features.toFixed(2)">
        <input type="hidden" :name="form_name_additional() + '[estimated_one_off_charges_sum]'"
               :value="estimated_one_off_charges_sum_formatted">
        <input type="hidden" :name="form_name() + '[plan_details_id]'" :value="selected_plan_id">

        <div class="remodal__body mb-4">
            <div data-test-role="select-plan-contract-options" v-if="got_contract_options_choice" class="form-group">
                <label><b>Choose contract option</b></label>
                <div>
                    <label v-for="(plan, plan_id) in details_plans" :for="'contract' + plan.id"
                           class="b-form-field b-form-field_custom mr-1">
                        <input class="b-form-field__control" type="radio" name="selected_plan"
                               :id="'contract' + plan.id"
                               :value="plan.id" v-model="selected_plan_id"/>
                        <span class="b-form-field__view b-form-field__view_primary">{{ plan.name }}</span>
                    </label>
                </div>
            </div>
            <p v-else>
                Contract option - <b>{{ selected_plan.name }}</b>
            </p>
            <p v-if="getNoticeForField('contract_type')!=''">
                {{ getNoticeForField('contract_type') }}
            </p>

            <div class="form-group" v-if="selected_plan_optional_features.length > 0">
                <label><b>Pick addons and features</b></label>
                <div>
                    <ul class="b-list b-list_columns-always_2">
                        <li v-for="feature in selected_plan_optional_features" class="b-list__item">
                            <label :for="'feature' + selected_plan.id + '_' + feature.type"
                                   class="b-form-field b-form-field_checkbox">
                                <input class="b-form-field__control" type="checkbox"
                                       :id="'feature' + selected_plan.id + '_' + feature.type"
                                       @change="onChangeSelected(feature, $event)"
                                       v-model="feature.selected"/>

                                <span class="b-form-field__view"></span>
                                <span class="b-form-field__text">
                                    {{ feature.name }}
                                    <b :class="{'got-a-notice': feature.notice!=''}"
                                       v-b-tooltip.hover
                                       :title="feature.notice">
                                        <span v-if="feature.options.length">from </span>
                                        {{ feature.formatted_price }}
                                    </b>
                                    <template v-if="feature.accompanying_feature">
                                        + {{ feature.accompanying_feature.name }}
                                        <b :class="{'got-a-notice': feature.accompanying_feature.notice!=''}"
                                           v-b-tooltip.hover
                                           :title="feature.accompanying_feature.notice">
                                            {{ feature.accompanying_feature.formatted_price }}
                                        </b>
                                    </template>
                                </span>
                            </label>

                            <ul v-if="feature.selected && feature.options.length" class="b-list ml-4 d-inline-block">
                                <li v-for="option in feature.options" :set="id = 'option' + option.ext_id"
                                    class="b-list__item">
                                    <label :for="id" class="b-form-field b-form-field_radio">
                                        <input class="b-form-field__control" type="radio"
                                               :id="id"
                                               :value="option"
                                               v-model="feature.option"
                                        />
                                        <span class="b-form-field__view"></span>
                                        <span class="b-form-field__text">
                                            {{ option.name }}
                                            <b>
                                                {{
                                                    option.price_monthly ? `$${formatPrice(option.price_monthly)}/mo` : ''
                                                }}
                                                {{ option.price_monthly && option.price_one_off ? ' + ' : '' }}
                                                {{
                                                    option.price_one_off ? `$${formatPrice(option.price_one_off)}/once` : ''
                                                }}
                                            </b>
                                        </span>
                                    </label>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
            <div>
                <p class="text-gray-dark mb-0">Estimated charges</p>
                <table class="b-table b-table_select">
                    <thead class="b-table__head">
                    <template v-if="selected_plan.has_promo_price">
                        <tr class="b-table__heading">
                            <th>Price for the first {{ promo_length_string }}</th>
                            <th class="b-table__heading-price">${{ formatPrice(promo_price_with_features) }}/mo</th>
                        </tr>
                        <tr class="b-table__sub">
                            <td>Price after first {{ promo_length_string }}</td>
                            <td>${{ formatPrice(price_with_features) }}/mo</td>
                        </tr>
                    </template>
                    <template v-else>
                        <tr class="b-table__heading">
                            <th>Price</th>
                            <th class="b-table__heading-price">${{ formatPrice(price_with_features) }}/mo</th>
                        </tr>
                    </template>
                    </thead>
                    <tbody>
                    <tr>
                        <td>Broadband</td>
                        <td>${{ selected_plan.price }}/mo</td>
                    </tr>
                    <tr v-for="feature in estimated_features_monthly"
                        :set="formatted_price = feature.option && feature.option.price_monthly ? ('$' + formatPrice(feature.option.price_monthly) + '/mo') : feature.formatted_price">
                        <input type="hidden"
                               :name="form_name_additional() + '[estimated_features_monthly]['+feature.name+']'"
                               :value="formatted_price">

                        <input type="hidden"
                               v-if="feature.option"
                               :name="form_name_additional() + '[options]['+feature.type+']'"
                               :value="feature.option.ext_id">

                        <td>{{ feature.name }}</td>
                        <td><span :class="{'got-a-notice': feature.notice!=''}"
                                  v-b-tooltip.hover
                                  :title="feature.notice">
                            {{ formatted_price }}
                        </span></td>
                    </tr>
                    <tr v-if="estimated_one_off_charges.length > 0" class="b-table__heading">
                        <th>One off charges</th>
                        <th>{{ estimated_one_off_charges_sum_formatted }}</th>
                    </tr>
                    <tr v-for="feature in estimated_one_off_charges"
                        :set="formatted_price = feature.option && feature.option.price_one_off ? ('$' + formatPrice(feature.option.price_one_off)) : feature.formatted_price">
                        <input type="hidden"
                               :name="form_name_additional() + '[estimated_one_off_charges]['+feature.name+']'"
                               :value="formatted_price">

                        <input type="hidden"
                               v-if="feature.option"
                               :name="form_name_additional() + '[options]['+feature.type+']'"
                               :value="feature.option.ext_id">

                        <td>{{ feature.name }}</td>
                        <td><span :class="{'got-a-notice': feature.notice!=''}"
                                  v-b-tooltip.hover
                                  :title="feature.notice">
                            {{ formatted_price }}
                        </span></td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data: function () {
        return {
            selected_plan_id: default_selected_details_id,
            details_plans: plan_data,
            loading: false,
        }
    },
    beforeMount() {
        for (var plan_id in this.details_plans) {
            var plan = this.details_plans[plan_id];
            plan.price = this.formatPrice(plan.price);
            plan.promo_price = this.formatPrice(plan.promo_price);
            if (plan.connection_fee !== null) {
                plan.connection_fee = this.formatPrice(plan.connection_fee);
            }

            for (var i in plan.features) {
                var feature = plan.features[i];
                feature.price = this.formatPrice(feature.price);
                Vue.set(feature, 'selected', false);
                Vue.set(feature, 'accompanying_feature', undefined);
                Vue.set(feature, 'show_in_checkbox_list', true);
            }

            this.process_router_delivery_fee_feature(plan);
        }
    },
    methods: {
        getNoticeForField(fieldName) {
            if (this.selected_plan.fields_notices.hasOwnProperty(fieldName)) {
                return this.selected_plan.fields_notices[fieldName];
            }

            return '';
        },
        formatPrice(price) {
            return parseFloat(parseFloat(price).toFixed(2));
        },
        process_router_delivery_fee_feature(plan) {
            var feature_router_delivery = this.get_feature_of_plan(plan, TYPE_ROUTER_DELIVERY_FEE);

            if (feature_router_delivery != undefined) {
                feature_router_delivery.show_in_checkbox_list = false;

                var feature_router = this.get_feature_of_plan(plan, TYPE_ROUTER);
                var feature_rental_router = this.get_feature_of_plan(plan, TYPE_RENTAL_ROUTER);

                if (feature_router != undefined && feature_router.mode == MODE_OPTIONAL) {
                    feature_router.accompanying_feature = feature_router_delivery;
                    feature_router_delivery.mode = MODE_OPTIONAL;
                }

                if (feature_rental_router != undefined && feature_rental_router.mode == MODE_OPTIONAL) {
                    feature_rental_router.accompanying_feature = feature_router_delivery;
                    feature_router_delivery.mode = MODE_OPTIONAL;
                }
            }
        },
        get_feature_of_plan(plan, type) {
            return plan.features.find(function (feature) {
                return feature.type == type;
            });
        },
        //Обновляем связанную опцию, если выбрана основная, то и связанная тоже
        updateAccompanyingFeature(feature) {
            if (feature.accompanying_feature != undefined) {
                feature.accompanying_feature.selected = feature.selected;
            }
        },
        onChangeSelected(feature, e) {
            this.$nextTick(() => {
                this.updateAccompanyingFeature(feature);
            });

            if (!feature.option && feature.options.length) {
                //select first available option if not
                this.$set(feature, 'option', feature.options[0]);
            }

            //Взаимоисключащие фичи связанные с роутером
            var mutuallyExclusiveRouterFeatures = [TYPE_BYO, TYPE_ROUTER, TYPE_RENTAL_ROUTER];
            if (mutuallyExclusiveRouterFeatures.includes(feature.type) && feature.selected) {
                for (var i in mutuallyExclusiveRouterFeatures) {
                    var other_router_feature =
                        this.get_feature_of_plan(this.selected_plan, mutuallyExclusiveRouterFeatures[i]);
                    if (other_router_feature == feature)
                        continue;

                    if (other_router_feature != undefined) {
                        other_router_feature.selected = false;
                        this.updateAccompanyingFeature(other_router_feature);
                    }
                }
            }
        },
        form_name_additional() {
            return window.form_name_additional;
        },
        form_name() {
            return window.form_name;
        }
    },
    computed: {
        selected_plan() {
            return this.details_plans[this.selected_plan_id];
        },
        selected_plan_optional_features() {
            var result = this.selected_plan.features.filter(function (feature) {
                return feature.mode == MODE_OPTIONAL && feature.show_in_checkbox_list;
            });

            result.sort(function (a, b) {
                return a.price < b.price;
            });

            return result;
        },
        features_to_show_in_bill() {
            return (price_type) => {
                let result = this.selected_plan.features.filter(function (feature) {
                    let include =
                        feature.price_type === price_type
                        || (feature.option && (price_type === PRICE_TYPE_MONTHLY
                        ? feature.option.price_monthly
                        : feature.option.price_one_off))
                        //Free features are included in one off payments
                        || (feature.price_type === PRICE_TYPE_FREE
                        && price_type === PRICE_TYPE_ONE_OFF)
                        //Available power is included in monthly payments
//                        || ((feature.type === TYPE_GAS || feature.type === TYPE_POWER || feature.type === TYPE_MOBILE)
                        || (TYPE_BUNDLED_LIST.indexOf(feature.type) !== -1
                        && feature.price_type === PRICE_TYPE_NO_PRICE
                        && price_type === PRICE_TYPE_MONTHLY);

                    if (feature.mode === MODE_OPTIONAL && feature.selected === false) {
                        include = false;
                    }

                    return include;
                });

                //Add connection fee to on off charges
                if (price_type === PRICE_TYPE_ONE_OFF) {
                    let formatted_price;
                    if (this.selected_plan.connection_fee > 0) {
                        formatted_price = '$' + this.formatPrice(this.selected_plan.connection_fee);
                    } else if (this.selected_plan.connection_fee === 0) {
                        formatted_price = 'Free';
                    }
                    if (this.selected_plan.connection_fee === null) {
                        formatted_price = 'No data';
                    }

                    result.push({
                        name: 'Connection fee',
                        price: this.selected_plan.connection_fee,
                        formatted_price: formatted_price,
                        notice: this.getNoticeForField('connection_fee')
                    });
                }

                result.sort(function (a, b) {
                    return b.price - a.price;
                });

                return result;
            }
        },
        /////////////////////////////////////////
        features_bill_sum() {
            return (price_type) => {
                let result = 0;
                let show_no_data = false;

                this.features_to_show_in_bill(price_type)
                    .forEach(function (feature) {
                        let price = feature.price;

                        if (price === null) {
                            show_no_data = true;
                        }

                        if (feature.option) {
                            price = parseFloat(price_type === PRICE_TYPE_MONTHLY
                                ? feature.option.price_monthly
                                : feature.option.price_one_off)
                                //if option price undefined - takes feature price
                                || price;
                        }

                        result += price;
                    });

                if (show_no_data) {
                    return 'No data';
                }

                return result;
            }
        },
        promo_length_string() {
            var result = this.selected_plan.promo_length;

            if (result == 1) {
                result = 'month';
            } else {
                result += ' months';
            }

            return result;
        },
        promo_price_with_features() {
            return this.selected_plan.promo_price + this.estimated_features_monthly_sum;
        },
        price_with_features() {
            return this.selected_plan.price + this.estimated_features_monthly_sum;
        },
        //////////////////////////////////////////////
        estimated_one_off_charges_sum_formatted() {
            if (typeof this.estimated_one_off_charges_sum == 'number') {
                return '$' + this.estimated_one_off_charges_sum.toFixed(2);
            }

            return this.estimated_one_off_charges_sum
        },
        estimated_one_off_charges() {
            return this.features_to_show_in_bill(PRICE_TYPE_ONE_OFF);
        },
        estimated_one_off_charges_sum() {
            return this.features_bill_sum(PRICE_TYPE_ONE_OFF);
        },
        estimated_features_monthly() {
            return this.features_to_show_in_bill(PRICE_TYPE_MONTHLY);
        },
        estimated_features_monthly_sum() {
            return this.features_bill_sum(PRICE_TYPE_MONTHLY);
        },
        form_attribute_name() {
            return form_attribute_name;
        },
        got_contract_options_choice() {
            return Object.keys(this.details_plans).length > 1;
        }
    },
    watch: {
        selected_plan_id() {
            window.parent.postMessage('updateFrameSize', "*");
            $('[data-toggle="tooltip"]').tooltip();
        },
        details_plans: {
            handler: () => {
                window.parent.postMessage('updateFrameSize', "*");
            },
            deep: true
        },
    },
    created() {
        window.onload = function () {
            this.loading = true;
        }
    },
}
</script>

<style scoped>
.b-form-field_radio .b-form-field__view {
    display: inline-block;
    width: 15px;
    height: 15px;
    border: 2px solid #00b2c7;
    border-radius: 50%;
    vertical-align: middle;
    margin-right: 10px;
    position: relative;
}

.b-form-field_radio .b-form-field__control {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none;
}

.b-form-field_radio .b-form-field__control:checked + .b-form-field__view:before {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    width: 7px;
    height: 7px;
    background-color: #00b2c7;
    border-radius: 50%;
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
</style>
