<template>
    <div class="table-responsive b-table-desktop">
        <table class="b-table b-table_comparison">
            <col style="width: 40px;">
            <col span="9" style="width: 150px;">
            <thead>
            <tr>
                <RowMinusHeader v-if="$root.inProfileCartMode"/>
                <th></th>
                <th v-for="plan in plans">
                    <PlanTableHeader :plan="plan"/>
                </th>
                <td v-if="plans.length == 1">
                    <p>Please add more plans to compare</p>
                    <AddMorePlansButton/>
                </td>
            </tr>
            </thead>
            <tbody>
                <DesktopPlanTableRow v-for="rowConfig in rowsConfig" :key="rowConfig.id"
                              :plans="plans"
                              :rowSpanForUnpublishedPlans="_rowSpanForUnpublishedPlans(rowsConfig)"
                              :rowConfig="rowConfig"
                              :hide_similar_parameters="hide_similar_parameters"
                              :hidden_parameters_are_shown="hidden_parameters_are_shown"/>
            </tbody>
        </table>
    </div>
</template>

<script>
    import DesktopPlanTableRow from './DesktopPlanTableRow';
    import PlanTableHeader from '../common/PlanTableHeader';
    import RowsConfigMixin from '../common/RowsConfigMixin';
    import RowMinusHeader from '../common/RowMinusHeader';
    import AddMorePlansButton from '../common/AddMorePlansButton';

    export default {
        mixins: [ RowsConfigMixin ],
        props: {
            plans: Array,
            rowsConfig: Array,
            hide_similar_parameters: {
                type: Boolean,
                default: false
            },
            hidden_parameters_are_shown: {
                type: Boolean,
                default: false
            }
        },
        components: {
            DesktopPlanTableRow,
            PlanTableHeader,
            RowMinusHeader,
            AddMorePlansButton
        }
    }
</script>