<template>
    <div>
        <ProviderPage v-if="isProviderPage"/>
        <ComparePage v-if="isComparisonPage"/>
        <SpecialPage v-if="isSpecialPage"/>
        <ProfileCart v-if="isProfilePage"/>
        <FixedBar v-if="!isProfilePage"
            :isPlansPage = "isComparisonPage || isProviderPage"
            :top="isProviderPage ? 400 : 300"
            :showFilters = "isComparisonPage"
        />
        <CartNonVueAdapter v-if="cartEnabled"/>
        <ErrorModal :message="error_message"/>
    </div>
</template>

<script>
    import ProfileCart from './cart/profile/ProfileCart'
    import CartNonVueAdapter from './cart/CartNonVueAdapter'
    import ComparePage from './ComparePage'
    import SpecialPage from './SpecialPage'
    import ErrorModal from './ErrorModal'
    import FixedBar from './FixedBar'
    import ProviderPage from './ProviderPage'
    import { VUE_APP_MODES } from '../modes';
    import cart_store from "../js/store/modules/cart/cart";
    import plans_store from "../js/store/modules/plans/index";
    import DefaultErrorHandlerMixin from './common/DefaultErrorHandlerMixin'

    export default {
        mixins: [ DefaultErrorHandlerMixin ],
        props: ['mode', 'error_message'],
        computed: {
            isProviderPage() {
                return this.mode == VUE_APP_MODES.PROVIDER
            },
            isComparisonPage() {
                return this.mode == VUE_APP_MODES.COMPARISON
            },
            isProfilePage() {
                return this.mode == VUE_APP_MODES.PROFILE_CART
            },
            isSpecialPage() {
                return this.mode == VUE_APP_MODES.SPECIAL
            },
            cartEnabled() {
                return this.$store.getters['common/cart_enabled'];
            }
        },
        components: {ComparePage, SpecialPage, ErrorModal, ProviderPage, ProfileCart, CartNonVueAdapter, FixedBar},
        created: function () {
            this.$store.commit('common/init', vue_store_init.common);

            if (this.cartEnabled) {
                this.$store.registerModule('cart', cart_store);
                this.$store.dispatch('cart/init', vue_store_init.cart);
            }

            if (this.isProfilePage) {
                this.$store.dispatch('cart/profile/init', vue_store_init.cart);
            }

            if (this.isComparisonPage || this.isSpecialPage || this.isProviderPage) {
                this.$store.registerModule('plans', plans_store);
                this.$store.dispatch('init', {
                    init_data: vue_store_init.plans,
                    show_filters: this.isComparisonPage || this.isSpecialPage
                });
            }
        }
    }
</script>

<style>
    .big-message {
        height: 200px;
        line-height: 200px;
        font-size: 130%;
    }

    .dimmed {
        position: relative;
    }

    .dimmed:after {
        content: " ";
        z-index: 10;
        display: block;
        position: absolute;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        background: rgba(255, 255, 255, 0.75);
    }
</style>