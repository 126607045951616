<template>
    <div class="remodal remodal_big" data-remodal-id="advancedFilter" id="advanced-filter" data-remodal-options="hashTracking: false">
        <div class="remodal__header">
            <h2 class="remodal__title">Advanced filters</h2>
        </div>
        <div class="remodal__body mb-4">
            <div class="container-fluid">
                <div class="row mb-4">
                    <div class="col-lg-4 mb-3 mb-lg-0">
                        <AdvancedFiltersSlider ref="sliderPrice"
                                               :min.sync="values.price_min"
                                               :max.sync="values.price_max"
                                               :range="slidersRange.price"
                                               :get-hint-callback="getHintForPriceSlider"
                                               :label="priceTypeLabel"/>
                    </div>
                    <div class="col-lg-4 mb-3 mb-lg-0">
                        <AdvancedFiltersSlider ref="sliderSpeed"
                                               :min.sync="values.speed_min"
                                               :max.sync="values.speed_max"
                                               :snap="true"
                                               :range="slidersRange.speed"
                                               :get-hint-callback="getHintForSpeedSlider"
                                               :label="'Download speed'"/>
                    </div>
                    <div class="col-lg-4 mb-3 mb-lg-0">
                        <AdvancedFiltersSlider  ref="sliderData"
                                                :min.sync="values.data_min"
                                                :max.sync="values.data_max"
                                                :range="slidersRange.data"
                                                :get-hint-callback="getHintForDataSlider"
                                                :label="'Data'"/>
                    </div>
                </div>
                <div class="row mb-4">
                    <div class="col-lg-4">
                        <div class="form-group text-left">
                            <label><b>Features</b></label>
                            <ul class="b-list b-list_columns_2">
                                <li class="b-list__item" v-for="(feature_label, feature) in features_list">
                                    <label v-bind:for="'fl_' + feature" class="b-form-field b-form-field_checkbox">
                                        <input class="b-form-field__control" v-bind:value="feature" type="checkbox"
                                               v-model="values.features[feature]" v-bind:id="'fl_' + feature" />
                                        <span class="b-form-field__view"></span>
                                        <span class="b-form-field__text">{{feature_label}}</span>
                                    </label>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="form-group text-left">
                            <label><b>Contract options</b></label>
                            <ul class="b-list b-list_columns_2">
                                <li class="b-list__item" v-for="(contract_option_label, contract_option) in contract_options_list">
                                    <label v-bind:for="'contract_' + contract_option" class="b-form-field b-form-field_checkbox">
                                        <input class="b-form-field__control" v-bind:value="contract_option" type="checkbox"
                                               v-model="values.contract_options[contract_option]" v-bind:id="'contract_' + contract_option" />
                                        <span class="b-form-field__view"></span>
                                        <span class="b-form-field__text">{{contract_option_label}}</span>
                                    </label>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="form-group text-left preferred-service-providers">
                            <label><b>Preferred internet service providers</b></label>
                            <Select2 :options="providerListForSelect2" v-model="values.provider_ids"  :settings="{ placeholder: 'Select broadband',  multiple: true, width: '100%' }" />

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="remodal__footer">
            <button data-remodal-action="confirm" class="btn btn-primary mb-3 mr-2">APPLY FILTERS</button>
            <button v-on:click="resetFilters" class="btn btn-outline-gray-darken mb-3">RESET FILTERS</button>
            <div class="text-center">
                <a href="#" data-remodal-action="cancel" class="b-link b-link_gray-darken">Close</a>
            </div>
        </div>
    </div>
</template>

<script>
    import {cloneObject, convertObjectListToSelect2Options} from '../../js/utils'
    import AdvancedFiltersSlider from './AdvancedFiltersSlider';
    import { mapGetters } from 'vuex'
    import Vue from 'vue'

    export default {
        data: function () {
            return {
                values: {
                },
            };
        },
        components: {
            AdvancedFiltersSlider
        },
        computed: {
            providerListForSelect2: function() {
                return convertObjectListToSelect2Options(this.providers_list)
                    .sort((a, b) => {
                        const nameA = a.text.toUpperCase();
                        const nameB = b.text.toUpperCase();

                        if (nameA < nameB) {
                            return -1;
                        } else if (nameA > nameB) {
                            return 1;
                        }

                        return 0;
                    });
            },
            ...mapGetters([
                'filters',
                'price_type',
                'slidersRange',
                'priceTypeLabel',
                'features_list',
                'contract_options_list',
                'providers_list',
            ]),
        },
        beforeMount: function() {
            this.values = cloneObject(this.filters.advanced_filters);
        },
        mounted: function() {
            var vm = this;

            $(document).on('opening', '.remodal[data-remodal-id="advancedFilter"]', function () {
                vm.values = cloneObject(vm.$store.state.plans.filters.advanced_filters);
                Vue.nextTick(function() {
                    vm.$refs.sliderPrice.reinit();
                    vm.$refs.sliderData.reinit();
                    vm.$refs.sliderSpeed.reinit();
                });
            });

            $(document).on('confirmation', '.remodal[data-remodal-id="advancedFilter"]', function () {
                vm.$store.commit('setFilters', { 'advanced_filters': cloneObject(vm.values) });
                vm.$store.commit('convertAdvancedToQuickFilters');
            });
        },
        methods: {
            resetFilters: function() {
                for(var i in this.values.features) {
                    this.values.features[i] = 0;
                }
                for(var i in this.values.contract_options) {
                    this.values.contract_options[i] = 0;
                }

                this.$refs.sliderPrice.reset();
                this.$refs.sliderData.reset();
                this.$refs.sliderSpeed.reset();

                this.values.provider_ids = [];
            },
            getHintForPriceSlider: function(min, max) {
                if (min == this.slidersRange.price.max) {
                    min = min - 1;
                }

                if (max == this.slidersRange.price.max) {
                    return '$' + min + ' - $' + (max-1) + '+';
                } else {
                    return '$' + min + ' - $' + max;
                }
            },
            getHintForSpeedSlider: function(min, max) {
                if (min == this.slidersRange.speed.max) {
                    min = min - 1;
                }

                if (max == this.slidersRange.speed.max) {
                    return min + ' - ' + (max-1) + '+ Mbps';
                } else {
                    return min + ' - ' + max + ' Mbps';
                }
            },
            getHintForDataSlider: function(min, max) {
                if (max == this.slidersRange.data.max) {
                    return min + ' GB - Unlimited';
                } else {
                    return min + ' GB - ' + max + ' GB';
                }
            }
        },
    }
</script>
