import convertToApi from "./state_to_api_converter";
import { GET, convertObjectListToSelect2Options, cloneObject, getCookie } from "../../../utils";

export default {
    autocompleteDataUrl: function (state) {
        return state.addressApiServiceUrl;
    },
    addressApiToken: function (state) {
        return state.addressApiToken;
    },
    addressApiVersion: function (state) {
        return state.addressApiVersion;
    },
    debugAddress: function (state) {
        return state.debugAddress;
    },
    debugAddressAid: function (state) {
        return state.debugAddressAid;
    },
    apiUrl: function(state) {
        return state.api_url;
    },
    leadDataTarget: function(state) {
        return state.leadDataTarget;
    },
    promo: function(state) {
        return state.data.promo;
    },
    filters: function(state) {
        return state.filters;
    },
    filtersQueryData: function(state) {
        return convertToApi(state);
    },
    currentAddress (state) {
        return state.filters.address.formatted;
    },
    addCurrentFiltersToUrl: function(state, getters) {
        return (url) => {
            var currentGet = GET();
            delete currentGet['page'];
            delete currentGet['f'];

            var filtersQueryData = cloneObject(getters.filtersQueryData);
            delete filtersQueryData['f']['save_to_session'];

            var mergedWithCurrentGet = Object.assign(currentGet, filtersQueryData);

            let query_string = $.param(mergedWithCurrentGet);

            let new_url = url;

            if (query_string != '') {
                new_url = new_url + '?' + query_string;
            }

            return new_url;
        };
    },
    urlWithCurrentFilters: function(state, getters) {
        return getters.addCurrentFiltersToUrl(window.location.pathname);
    },
    //Simple filters
    plans: function(state) {
        return state.data.plans;
    },
    show_availability_modal: function(state) {
        return state.data.show_availability_modal;
    },
    current_page: function(state) {
        return state.meta.current_page;
    },
    page_count: function(state) {
        return state.meta.page_count;
    },
    enabled_connection_types: function(state) {
        return state.data.connection_types;
    },
    connection_types_list: function(state) {
        return state.dictionaries.connection_types_list;
    },
    connection_types_list_for_modal: function(state) {
        return state.dictionaries.connection_types_list_for_modal;
    },
    quick_filters_list: function(state) {
        return state.dictionaries.quick_filters_list;
    },
    fibre_types_list: function(state) {
        return state.dictionaries.fibre_types_list;
    },
    sort_list: function(state) {
        return state.dictionaries.sort_list;
    },
    price_types_list: function(state) {
        return state.dictionaries.price_types_list;
    },
    price_type: function(state) {
        return state.filters.price_type;
    },
    plansFrom: function (state) {
        return state.meta.per_page * state.meta.current_page + 1;
    },
    plansPerPage: function (state) {
        return state.meta.per_page;
    },
    plansTo: function (state) {
        if (state.meta.current_page + 1 == state.meta.page_count) {
            return state.meta.total_count;
        }

        return state.meta.per_page * (state.meta.current_page + 1);
    },
    plansTotalCount: function (state) {
        return state.meta.total_count;
    },
    priceUnit: function (state) {
        return state.dictionaries.price_types_short_labels_list[state.meta.price_type];
    },
    isAddressEmpty: function (state) {
        return /*!state.filters.address.formatted || */!state.filters.address.id;
    },
    //Advanced filters
    slidersRange: function(state) {
        return state.dictionaries.sliders_range;
    },
    priceTypeLabel: function (state) {
        return state.dictionaries.price_types_list[state.filters.price_type];
    },
    features_list: function(state) {
        return state.dictionaries.features_list;
    },
    contract_options_list: function(state) {
        return state.dictionaries.contract_options_list;
    },
    providers_list: function(state) {
        return state.dictionaries.providers_list;
    },
    price_types_months: function (state) {
        return state.dictionaries.price_types_months;
    },
    sortListForSelect2: function(state, getters) {
        return convertObjectListToSelect2Options(getters.sort_list);
    },
    priceTypesForSelect2: function(state, getters) {
        return convertObjectListToSelect2Options(getters.price_types_list);
    },
    connection_types_priority_list: function (state) {
        return state.connection_types_priority_list;
    },
    connection_types_for_modal_speed_hints: function (state) {
        return state.data.connection_types_for_modal_speed_hints;
    },
    connection_types_for_modal_speeds: function (state) {
        return state.data.connection_types_for_modal_speeds;
    },
    connection_types_for_modal_texts: function (state) {
        return state.dictionaries.connection_types_for_modal_texts;
    },
    ignore_address_coverage: function (state) {
        return state.filters.ignore_address_coverage;
    },
    bundle_options_instead_availability: function (state) {
        return state.bundle_options_instead_availability;
    },
    ignore_address_alert_text: function (state) {
        return state.ignore_address_alert_text;
    },
    is_it_default_site_preset: function (state) {
        return state.is_it_default_site_preset;
    },
    ignore_address_modal_text: function (state) {
        return state.ignore_address_modal_text;
    },
    showMessageInPlanView: function (state) {
        return state.showMessageInPlanView;
    },
    go_to_default_preset_connections_modal_text: function (state) {
        return state.go_to_default_preset_connections_modal_text;
    },
    default_preset_url: function (state) {
        return state.default_preset_url;
    },
    defaultPresetComparePageWithCurrentFilters: function (state, getters) {
        return getters.addCurrentFiltersToUrl(state.default_preset_url + '/compare');
    },
    filtersPatchToRemoveDisabledConnections(state, getters) {
        const patch = {};

        for (let type in state.filters.connection_types) {
            let connection_type_filter_is_on = state.filters.connection_types[type];
            if (connection_type_filter_is_on && getters.isConnectionTypeDisabled(type))
                patch[type] = false;
        }

        return patch;
    },
    isConnectionTypeDisabled(state) {
        return (type) => {
            return state.data.connection_types.indexOf(type) === -1;
        }
    },
    useful_tips_images(state) {
        return state.dictionaries.useful_tips_images;
    },
    minAddressQueryLength: function () {
        return 5;
    }
};
