<template>
    <span>{{result}}</span>
</template>

<script>
    import { mapGetters } from 'vuex'
    export default {
        props: {
            plan: Object,
            callback: Function
        },
        computed: {
            ...mapGetters({
                getSelectedDetails: 'cart/profile/getSelectedDetails',
            }),
            result() {
                return this.callback(this.plan, this.getSelectedDetails(this.plan));
            }
        },
    }
</script>