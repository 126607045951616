<template>
    <tr>
        <RowMinus v-if="$root.inProfileCartMode" :rowConfig="rowConfig"></RowMinus>
        <td class="name-column">{{rowConfig.name}}</td>
    </tr>
</template>

<script>
    import RowMinus from '../common/RowMinus';

    export default {
        components: { RowMinus },
        props: {
            rowConfig: Object,
        },
    }
</script>