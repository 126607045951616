<template>
    <div>
        <CircleLoader v-if="circleLoaderShouldBeShown"/>

        <div v-if="plansShouldBeShown || skeletonShouldBeShown"
             class="d-md-flex align-items-center mb-3">

            <HideSimilarParametersCheckbox :value.sync="hide_similar_parameters" @update:value="$emit('change-hide-similar-parameters', $event)"/>

            <AddMorePlansButton v-if="plans.length < max_list_length"/>
        </div>

        <PlanTableSkeleton v-if="skeletonShouldBeShown" :isMobile="screenIsMobile"/>

        <template v-if="plansShouldBeShown">

            <DesktopPlanTable v-if="screenIsMobile == false"
                :plans="sortedPlans"
                :rowsConfig="rowsConfig"
                :hide_similar_parameters="hide_similar_parameters"
                :hidden_parameters_are_shown="hidden_parameters_are_shown"
            />

            <MobilePlanTable v-if="screenIsMobile == true"
                :plans="sortedPlans"
                :rowsConfig="rowsConfig"
                :hide_similar_parameters="hide_similar_parameters"
                :hidden_parameters_are_shown="hidden_parameters_are_shown"
                />

            <HiddenParametersStatus
                    :hidden_parameters_are_shown="hidden_parameters_are_shown"
                    @update:hidden_parameters_are_shown="hidden_parameters_are_shown = $event"
                    :hidden_parameters_count="hiddenParametersCount"/>
        </template>

        <FeaturedModal ref="featured_modal"/>
    </div>
</template>

<script>
    import PlanTableSkeleton from './common/PlanTableSkeleton';
    import AddMorePlansButton from './common/AddMorePlansButton';
    import HideSimilarParametersCheckbox from './common/HideSimilarParametersCheckbox';
    import MobilePlanTable from './mobile/MobilePlanTable';
    import DesktopPlanTable from './desktop/DesktopPlanTable';
    import HiddenParametersStatus from './common/HiddenParametersStatus';
    import {mapGetters} from 'vuex';
    import {cloneArray, cloneObject} from "../../../../js/utils";
    import {default_row_config} from './rows_config';
    import rows_config from './rows_config';
    import FeaturedModal from '../../../plan/FeaturedModal';
    import {status as loadingStatus, LoadingStatusesMixin} from '../../../common/LoadingStatusesMixin'
    import RowsConfigMixin from './common/RowsConfigMixin';
    import {FEATURES_ITEMS_TEMPLATE} from './rows_config';
    import ScreenSizeMixin from "../../../common/ScreenSizeMixin";

    export default {
        mixins: [ LoadingStatusesMixin, RowsConfigMixin, ScreenSizeMixin],
        components: {
            PlanTableSkeleton,
            FeaturedModal,
            HiddenParametersStatus,
            DesktopPlanTable,
            MobilePlanTable,
            AddMorePlansButton,
            HideSimilarParametersCheckbox,
        },
        props: {
            plans: {
                type: Array,
                default: []
            },
            hidden_rows: {
                type: Array,
                default() {
                  return []
                }
            },
            hide_similar_parameters: {
                type: Boolean,
                default: false
            },
            sortListForSelect2: {
                type: Array,
                default: []
            },
            hidden_parameters_are_shown: {
                type: Boolean,
                default: false
            }
        },
        data: function () {
            return {
                plans_order_ids: [],
                sort: null,
            };
        },
        beforeMount: function () {
            if (this.plans.length > 0) {
                this.status = loadingStatus.COMPLETE;
            }
        },
        created() {
            var vm = this;
            this.$eventHub.$on('show-featured-modal', function($event) {
                vm.$refs.featured_modal.show($event);
            });
            this.$eventHub.$on('update:plan-selected-details-id', function($event)  {
                for (var i in this.plans) {
                    var plan = this.plans[i];
                    if (plan.id == $event.planId) {
                        plan.selected_details_id = $event.planDetailsId;
                        break;
                    }
                }

                if (this.sortIsOutdated)
                    this.sort = null;
            });
        },
        methods: {
        },
        computed: {
            ...mapGetters({
                getSelectedDetails: 'cart/profile/getSelectedDetails',
                getSelectedDetailsFeaturePrice: 'cart/profile/getSelectedDetailsFeaturePrice',
                show_rating_in_providers: 'common/show_rating_in_providers',
                show_rating_in_plans: 'common/show_rating_in_plans',
                max_list_length: 'cart/profile/max_list_length',
            }),
            rowsConfig: function () {
                var result = cloneArray(rows_config);
                for (var i in result) {
                    if (typeof result[i] != "string") {
                        result[i] = cloneObject(result[i]);
                    }
                }
                let featuresTemplateIndex = result.indexOf(FEATURES_ITEMS_TEMPLATE);
                if (featuresTemplateIndex > -1) {
                    result.splice(featuresTemplateIndex, 1);
                }
                for (var i in this.commonFeaturesList) {
                    let feature = this.commonFeaturesList[i];
                    let item = Object.assign(
                        cloneObject(default_row_config),
                        {
                            id: feature.type,
                            name: feature.name,
                            rawValueExpression: (plan) => this.getSelectedDetailsFeaturePrice(plan, feature.type),
                            noticeField: 'features.' + feature.type,
                        }
                    );
                    result.splice(featuresTemplateIndex, 0, item);
                }

                for (var i in result) {
                    result[i].hidden = this.hidden_rows.indexOf(String(result[i].id)) != -1;
                }

                if (this.hidden_parameters_are_shown) {
                    result.sort(function (a, b) {
                        if (a.hidden != b.hidden) {
                            return a.hidden ? 1 : -1;
                        }

                        return 0;
                    });
                }

                if (this.show_rating_in_plans == false) {
                    result = result.filter(function (rowConfig) {
                        return rowConfig.id != 10;//Rating
                    });
                }

                if (this.show_rating_in_providers == false) {
                    result = result.filter(function (rowConfig) {
                        return rowConfig.id != 2;//ISP Rating
                    });
                }

                return result;
            },
            commonFeaturesList: function () {
                var result = [];

                for (var i in this.plans) {
                    var plan = this.plans[i];
                    var detailsPlans = this.getSelectedDetails(plan);

                    if (detailsPlans) {
                        for (var m in detailsPlans.features) {
                            var feature = detailsPlans.features[m];

                            let already_in_result = result.find((element) => {
                                return element.type == feature.type;
                            }) != undefined;

                            if (!already_in_result)
                                result.push(feature);
                        }
                    }
                }

                return result;
            },
            plansShouldBeShown: function () {
                return this.isCompleteStatus && this.plans.length > 0;
            },
            sortedPlans: function () {
                return this.plans.sort((a, b) => {
                    return this.plans_order_ids.indexOf(a.id) - this.plans_order_ids.indexOf(b.id);
                });
            },
            planOrderIdsCurrentlyUsed() {
                return this.plans_order_ids;
            },
            planOrderIdsCalculatedFromSelectedDetailsAndCurrentSort() {
                var plans_order_ids = [];
                for (var i in this.plans) {
                    plans_order_ids.push(this.plans[i].id);
                }

                var getPlan = (id) => {
                    return this.plans.find((plan) => {
                        return plan.id == id;
                    });
                };

                plans_order_ids.sort((a_id, b_id) => {
                    var a = getPlan(a_id);
                    var b = getPlan(b_id);

                    switch (this.sort) {
                        case 'rating':
                            return this.getSelectedDetails(b).rating - this.getSelectedDetails(a).rating;

                        case 'price-asc':
                            return this.getSelectedDetails(a).price - this.getSelectedDetails(b).price;

                        case 'price-desc':
                            return this.getSelectedDetails(b).price - this.getSelectedDetails(a).price;

                        case 'review-asc':
                            return a.provider.rating - b.provider.rating;

                        case 'review-desc':
                            return b.provider.rating - a.provider.rating;
                    }
                });
                return plans_order_ids;
            },
            sortIsOutdated() {
                for (var i in this.planOrderIdsCalculatedFromSelectedDetailsAndCurrentSort) {
                    var plan_id = this.planOrderIdsCalculatedFromSelectedDetailsAndCurrentSort[i];

                    if (this.planOrderIdsCurrentlyUsed.indexOf(plan_id) != i) {
                        return true;
                    }
                }

                return false;
            },
            hiddenParametersCount()
            {
                return this._hiddenParametersCount(this.plans, this.rowsConfig, this.hide_similar_parameters)
            }
        },
        watch: {
            plans: {
                handler: function (newPlans) {
                    for (var i in newPlans) {
                        var plan = newPlans[i];
                        if (plan.hasOwnProperty('selected_details_id') == false && plan.details.length > 0) {
                            this.$set(plan, 'selected_details_id', plan.details[0].id);
                        }

                        if (plan.hasOwnProperty('provider')) {
                            plan.provider.rating = parseFloat(plan.provider.rating);
                        }
                    }

                    return newPlans;
                },
                immediate: true
            },
            sort: function (newSort) {
                if (newSort != null) {
                    this.plans_order_ids = this.planOrderIdsCalculatedFromSelectedDetailsAndCurrentSort;
                    this.$emit('change-sort', newSort);
                }
            },
        }
    }
</script>
