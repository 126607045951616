import { mergeDeep, GET, cloneObject, setCookie, eraseCookie, getCookie } from "../../../utils";
import convertInput from "./state_input_converter";
import { getPriceTypeChangeDictionariesPatch, getPriceTypeChangeFiltersPatch } from "./price_type_slider_patch";
import state from "./state";
import getters from "./getters";

var default_state = cloneObject(state);
var cancelSource;

function loadFromServerPlansPage (store, page) {
    var dataToSend = store.getters.filtersQueryData;
    dataToSend['page'] = page;
    if (cancelSource) {
        cancelSource.cancel('cancelled');
    }
    cancelSource = axios.CancelToken.source();
    return new Promise((resolve, reject) => {
        axios
            .get(store.getters.apiUrl, {
                params: dataToSend,
                cancelToken: cancelSource.token
            })
            .then(function (response) {
                if (response.data.hasOwnProperty('meta') && response.data.hasOwnProperty('data')) {
                    store.commit('setResponse', response.data);
                    store.dispatch('removeDisabledConnectionTypesFromFilters');
                    resolve(response);
                } else {
                    reject(new Error('Wrong response structure'));
                }
            }).catch(function (error) {
                reject(error);
            });
    });
}

export default {
    //namespaced: true,
    state: state,
    getters: getters,
    mutations: {
        setResponse: function(state, response) {
            state.data = response.data;
            state.meta = response.meta;

            if (!response.data.hasOwnProperty('promo')) {
                state.promo = null;
            }
        },
        setFilters: function(state, filters_patch) {
            if (filters_patch.hasOwnProperty('connection_types') &&
                filters_patch.connection_types.hasOwnProperty('fibre')) {
                const value = filters_patch.connection_types.fibre;
                delete filters_patch.connection_types['fibre'];

                for (let i in state.dictionaries.fibre_types_list) {
                    let fibre_type = state.dictionaries.fibre_types_list[i];
                    filters_patch.connection_types[fibre_type] = value;
                }
            }

            state.filters = Object.assign(state.filters, filters_patch);
        },
        setDictionaries: function(state, dictionaries) {
            state.dictionaries = Object.assign(state.dictionaries, dictionaries);
        },
        setAddress (state, newAddress) {
            if (!newAddress.hasOwnProperty('id') && newAddress.formatted != state.address.formatted) {
                //Clear aid if address changed and no id provided
                newAddress['id'] = null;
            }

            state.filters.address =  Object.assign(state.filters.address, newAddress);
        },
        setPriceType (state, newPriceType) {
            state.filters.price_type =  newPriceType;
        },
        setSlidersRange (state, sliders_range) {
            state.dictionaries.sliders_range = Object.assign(state.dictionaries.sliders_range, sliders_range);
        },
        setSort (state, sort) {
            state.filters.sort = sort;
        },
        convertQuickFiltersToAdvanced (state) {
            for (var feature in state.dictionaries.features_quick_filters_dictionary) {
                var quick_filter = state.dictionaries.features_quick_filters_dictionary[feature];
                state.filters.advanced_filters.features[feature] = state.filters.quick_filters[quick_filter];
            }

            for (var contract_option in state.dictionaries.contract_options_quick_filters_dictionary) {
                var quick_filter = state.dictionaries.contract_options_quick_filters_dictionary[contract_option];
                state.filters.advanced_filters.contract_options[contract_option] = state.filters.quick_filters[quick_filter];
            }
        },
        convertAdvancedToQuickFilters (state) {
            for (var feature in state.dictionaries.features_quick_filters_dictionary) {
                let quick_filter = state.dictionaries.features_quick_filters_dictionary[feature];
                state.filters.quick_filters[quick_filter] = state.filters.advanced_filters.features[feature];
            }

            for (var contract_option in state.dictionaries.contract_options_quick_filters_dictionary) {
                var quick_filter = state.dictionaries.contract_options_quick_filters_dictionary[contract_option];
                state.filters.quick_filters[quick_filter] = state.filters.advanced_filters.contract_options[contract_option];
            }
        },
        setPage(state, page) {
            state.meta.current_page = page;
        },
        setPerPage(state, value) {
            state.meta.per_page = value;
        },
        setAlertConnectionType(state, connection_type) {
            state.alert_connection_type = connection_type;
        }
    },
    actions: {
        loadPage(context, page) {
            return loadFromServerPlansPage(context, page);
        },
        restoreFiltersFromGET(context) {
            context.dispatch('init', {
                init_data: vue_store_init.plans,
                show_filters: context.state.show_filters
            });
        },
        init(context, payload) {
            var init_data = cloneObject(payload.init_data);

            if (payload.hasOwnProperty('show_filters')) {
                context.state.show_filters = payload.show_filters;
            }

            var allow_advanced_filtering;
            if (payload.hasOwnProperty('allow_advanced_filtering')) {
                allow_advanced_filtering = payload.allow_advanced_filtering;
            } else {
                allow_advanced_filtering = context.state.show_filters;
            }

            //Format init, add missing elements and convert checkboxlists to Vue format
            //(object with booleans as values and option's names as properties instead
            //of just array of options names)
            var filters_init = convertInput(default_state.filters, init_data, allow_advanced_filtering);

            //Init state
            context.commit('setFilters', filters_init);
            context.commit('setDictionaries', init_data.dictionaries);
            context.state.api_url = init_data.api_url;
            context.state.connection_types_priority_list = init_data.connection_types_priority_list;
            context.state.alert_connection_type_template = init_data.alert_connection_type_template;
            context.state.show_alert_connection_type = init_data.show_alert_connection_type;
            context.state.bundle_options_instead_availability = init_data.bundle_options_instead_availability;
            context.state.speed_tip = init_data.speed_tip;
            context.state.ignore_address_alert_text = init_data.ignore_address_alert_text;
            context.state.ignore_address_modal_text = init_data.ignore_address_modal_text;
            context.state.showMessageInPlanView = init_data.showMessageInPlanView;
            context.state.go_to_default_preset_connections_modal_text = init_data.go_to_default_preset_connections_modal_text;
            context.state.default_preset_url = init_data.default_preset_url;
            context.state.is_it_default_site_preset = init_data.is_it_default_site_preset;
            context.state.best_available_plans_filters = init_data.best_available_plans_filters;
            context.state.leadDataTarget = init_data.leadDataTarget;
            context.state.showConnectionTypeFilter = (init_data.get_from_yii && init_data.get_from_yii['showConnectionTypeFilter']) ? init_data.get_from_yii['showConnectionTypeFilter'] : 0;
            context.commit('setAlertConnectionType', getCookie('alert_connection_type'));

            context.commit('setPerPage', (init_data.get_from_yii && init_data.get_from_yii['per-page']) ? init_data.get_from_yii['per-page'] : 20);

            var currentGet = GET();
            if (currentGet.hasOwnProperty('page')) {
                var pageToLoad = parseInt(currentGet.page);
            } else {
                var pageToLoad = 1;
            }

            //Load first pack of plans
            context.commit('setPage', pageToLoad);
        },
        updatePriceType(context, newPriceType) {
            var state = context.state;

            if (state.show_filters) {
                var priceMin = state.filters.advanced_filters.price_min;
                var priceMax = state.filters.advanced_filters.price_max;
                var oldSlidersRangePrice = state.dictionaries.sliders_range.price;
                var oldPriceType = state.filters.price_type;
                var months = context.getters.price_types_months;
                var convertRatio = months[newPriceType] / months[oldPriceType];

                var dictionaries_convert_patch = getPriceTypeChangeDictionariesPatch(
                    oldSlidersRangePrice,
                    convertRatio);

                var filters_convert_patch = getPriceTypeChangeFiltersPatch(
                    priceMin,
                    priceMax,
                    oldSlidersRangePrice,
                    convertRatio);

                context.commit('setSlidersRange', dictionaries_convert_patch.sliders_range);
                context.commit('setFilters', mergeDeep(state.filters, filters_convert_patch));
            }

            context.commit('setPriceType', newPriceType);
        },
        removeDisabledConnectionTypesFromFilters(context) {
            const connection_types_patch = context.getters.filtersPatchToRemoveDisabledConnections;

            if (Object.keys(connection_types_patch).length) {
                window.unwatchFilters();
                context.commit('setFilters', {
                    connection_types: Object.assign({}, context.getters.filters.connection_types, connection_types_patch)
                });
                window.initFiltersWatch();

                if (context.getters.isConnectionTypeDisabled(context.state.alert_connection_type))
                    context.dispatch('disableAlertConnectionType');
            }
        },
        disableAlertConnectionType(context) {
            context.commit('setAlertConnectionType', '');
            eraseCookie('alert_connection_type');
        },
        showAlertConnectionType(context, type) {
            context.commit('setAlertConnectionType', type);
            setCookie('alert_connection_type', type, {expires: 3600 * 24 * 180});
        },
        stopIgnoringAddress(context) {
            context.commit('setFilters', { ignore_address_coverage: 0 });
        },
        startIgnoringAddress(context) {
            context.dispatch('disableAlertConnectionType');
            context.commit('setFilters', { ignore_address_coverage: 1 });
        }
    },
};
