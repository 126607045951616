export default {
    namespaced: true,
    state: {
        user: {
            isGuest: true,
            name: null,
            avatar_url: null,
        },
        urls: {
            logout_url: null,
            profile_url: null,
            cart_comparison_url: null,
            plans_comparison_url: null
        },
        rating_color_ranges: {},
        speed_color_ranges: {},
        show_rating_in_plans: 1,
        show_rating_in_providers: 1,
        cart_enabled: 1,
    },
    getters: {
        show_rating_in_plans: function(state) {
            return state.show_rating_in_plans;
        },
        show_rating_in_providers: function(state) {
            return state.show_rating_in_providers;
        },
        cart_enabled: function(state) {
            return state.cart_enabled;
        },
        isGuest: function(state) {
            return state.user.isGuest;
        },
        user_name: function(state) {
            return state.user.name;
        },
        logout_url: function(state) {
            return state.urls.logout_url;
        },
        profile_url: function(state) {
            return state.urls.profile_url;
        },
        avatar_url: function(state) {
            return state.user.avatar_url;
        },
        plans_comparison_url: function(state) {
            return state.urls.plans_comparison_url;
        },
        cart_comparison_url: function(state) {
            return state.urls.cart_comparison_url;
        },
        getRatingClass: function(state, getters) {
            return (rating) => {
                var codes = ['fa-danger', 'fa-secondary', 'fa-success'];

                return getters.getRangesClass(rating, codes);
            }
        },
        getSmileClass: function(state, getters) {
            return (rating) => {
                var codes = ['fa-frown', 'fa-meh', 'fa-smile'];

                return getters.getRangesClass(rating, codes);
            }
        },
        getRangesClass: function(state) {
            return (rating, codes) => {
                if (rating == null) {
                    rating = 0;
                }

                for (var i in state.rating_color_ranges) {
                    var threshold = state.rating_color_ranges[i];
                    if (rating < threshold) {
                        return codes[i];
                    }
                }

                if (rating == threshold) return codes[i];
            }
        },
        getSpeedClass: function(state) {
            return (speed) => {
                if (speed == null) {
                    speed = 0;
                }

                var codes = ['low', 'medium', 'high'];

                for (var i in state.speed_color_ranges) {
                    var threshold = state.speed_color_ranges[i];
                    if (String(speed).replace(',', '') < threshold) {
                        return codes[i];
                    }
                }
            }
        },
        speedIconUrl: function(state, getters) {
            return (speed_down) => {
                return '/images/symbol/svg/speed-' + getters.getSpeedClass(speed_down) + '.svg';
            }
        }
    },
    mutations: {
        init: function(state, init_data) {
            state.user = Object.assign(state.user, init_data.user);
            state.urls = Object.assign(state.urls, init_data.urls);
            state.rating_color_ranges = Object.assign(state.rating_color_ranges, init_data.rating_color_ranges);
            state.speed_color_ranges = Object.assign(state.speed_color_ranges, init_data.speed_color_ranges);
            state.show_rating_in_plans = parseInt(init_data.show_rating_in_plans);
            state.show_rating_in_providers = parseInt(init_data.show_rating_in_providers);
            state.cart_enabled = parseInt(init_data.cart_enabled);
        }
    },
};
