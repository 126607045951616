<template>
    <span>
        <a :href="plan.url" target="_blank" class="b-link_primary-dark" v-if="fee.type === 'complicated_etf'"
            v-b-tooltip.hover
            :title="fee.complicated_etf_comment"
        >
            {{fee.value}}
        </a>
        <span v-else >
            {{fee.value}}
        </span>
    </span>
</template>

<script>
    export default {
        props: {
            fee: Object,
            plan: Object
        },
    }
</script>