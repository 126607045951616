<template>
    <div>
        <a data-remodal-target="tipsModal"
           class="b-link b-link_ico b-link_gray-dark b-link_setting b-link_hover_gray-darken b-link__tips">
            <span class="b-link__ico"><i class="fas fa-info-circle"></i></span>
            <span class="b-link__text">Show me useful tips</span>
        </a>

        <div class="remodal remodal-fullscreen tipsModal"
             data-remodal-id="tipsModal"
             data-remodal-options="hashTracking: false">
            <div v-if="renderImg" class="owl-carousel tipsModal-slider">
                <div v-for="(useful_tips_image, i) in useful_tips_images">
                    <img :src="useful_tips_image.url">
                    <a v-if="useful_tips_image.show_all_parent_id"
                        @click="goToSlide(findSlideIndex(useful_tips_image.show_all_parent_id))"
                        class="see-all">Show all tips at once</a>
                    <div class="slider-counter">
                        Tip {{i+1}}/{{useful_tips_images.length}}
                    </div>
                </div>
            </div>
            <button data-remodal-action="close" class="remodal-close">Close</button>
            <button data-remodal-action="close" class="remodal-close-btn"><i class="fas fa-times"></i></button>
        </div>
    </div>
</template>

<script>
    export default {
        data: function() {
            return {
                renderImg: false
            };
        },
        computed: {
            useful_tips_images() {
                return this.$store.getters.useful_tips_images;
            }
        },
        methods: {
            goToSlide(slide_id) {
                $('.tipsModal-slider').trigger('to.owl.carousel', [slide_id]);
            },
            findSlideIndex(id) {
                for (var i in this.useful_tips_images) {
                    if (this.useful_tips_images[i].id == id) {
                        return i;
                    }
                }
            }
        },
        mounted() {
            let vm = this;
            $(document).on('opening', '.tipsModal', function() {
                vm.renderImg = true;

                vm.$nextTick(() => {
                    $('.tipsModal-slider').on('initialized.owl.carousel changed.owl.carousel', function(e) {
                        if (!e.namespace)  {
                            return;
                        }
                        // carousel.items().length
                        var carousel = e.relatedTarget;
                        var btn = $('<button data-remodal-action="close" class="close-btn">Close</button>');
                        $('.b-link__tips').click(function () {
                            $('.tipsModal-slider').find('button.close-btn').detach();
                        });
                        if (carousel.current() + 1 === carousel.items().length) {
                            $('.tipsModal-slider .owl-next').addClass('hidden');
                            $('.tipsModal-slider .owl-nav').append(btn);
                        } else {
                            $('.tipsModal-slider .owl-next').removeClass('hidden');
                            $("button.close-btn").detach();
                        }
                    }).owlCarousel({
                        loop: false,
                        nav: true,
                        dots: false,
                        animateOut: 'fadeOut',
                        responsive:{
                            0:{
                                nav:true,
                                navText: ['previous', 'next'],
                                items:1
                            },
                            600:{
                                nav:true,
                                navText: ['previous', 'next'],
                                items:1
                            },
                            1000:{
                                nav:true,
                                navText: ['previous', 'next'],
                                items:1
                            }
                        }
                    });
                });
            });
        }
    }
</script>
