<template>
    <div>
        <div class="remodal remodal_small" :data-remodal-id="modalId" data-remodal-options="hashTracking: false">
            <div class="remodal__header">
                <h2 class="remodal__title">Save your comparison list</h2>
                <div class="remodal__close" data-remodal-action="cancel">&times;</div>
            </div>
            <div class="remodal__body mb-3">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-lg-10 m-auto text-left">
                            <div class="form-group">
                                <label><b>Name</b></label>
                                <input type="text" v-bind:class="{'is-invalid': error_message}" class="form-control" v-model="list_name">
                                <span v-if="error_message" class="invalid-tooltip">{{error_message}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="remodal__footer">
                <button @click="saveThisComparisonList" class="btn btn-primary text-uppercase">Save</button>
                <button @click="closeModal" class="btn btn-primary text-uppercase">Cancel</button>
            </div>
        </div>

        <ConfirmationModal ref="confirmation_modal"
                                  :title="'Overwrite comparison list'"
                                  :text="'You already have a list with the same name. Overwrite it?'"/>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    import RemodalMixin from '../../common/RemodalMixin'

    export default {
        name: 'ProfileSaveListModal',
        mixins: [ RemodalMixin ],
        data: function(){
            return {
                error_message: '',
                list_name: '',
                save_callback: null,
                cancel_callback: null
            };
        },
        computed: {
            ...mapGetters({
                activeList: 'cart/profile/activeList',
                lists: 'cart/profile/lists',
            }),
        },
        methods: {
            show(save_callback, cancel_callback) {
                this.save_callback = save_callback;
                this.cancel_callback = cancel_callback;

                this.list_name = this.activeList.name;

                this.remodal.open();
            },
            errorHandler: function(error) {
                if (error.message != '') {
                    this.error_message = error.message;
                } else {
                    this.error_message = "Sorry, an error occurred during request, please try again later"
                }
            },
            saveActiveList(overwrite) {
                this.$store.dispatch('cart/profile/saveActiveList', {
                    name: this.list_name,
                    overwrite: overwrite
                }).then(
                    () => {
                        this.$store.dispatch('cart/profile/getLists').then(
                            () => {
                                this.error_message = "";
                                this.remodal.close();
                                if (this.save_callback) {
                                    this.save_callback();
                                }
                            },
                            this.errorHandler
                        );
                    },
                    this.errorHandler
                );
            },
            showOverwriteConfirmationModal: function() {
                this.$refs.confirmation_modal.show(
                    () => {
                        //Yes button callback
                        this.saveActiveList(true);
                    },
                    () => {
                        //No button callback
                        this.error_message = "List with this name already exists";
                        this.remodal.open();
                    }
                );
            },
            closeModal() {
                if (this.cancel_callback)
                    this.cancel_callback();

                this.remodal.close();
            },
            saveThisComparisonList() {
                var listWithSameName = this.lists.find((list) => {
                    return list.name == this.list_name;
                });

                if (listWithSameName != undefined && !listWithSameName.is_active) {
                    this.showOverwriteConfirmationModal();
                } else {
                    this.saveActiveList(false);
                }
            }
        }
    }
</script>
