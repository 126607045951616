import {cloneArray, cloneObject} from "../../../../utils";
import {sendToCartApi} from "../cart";

export default  {
    updatePlansFromIds(context, plan_ids) {
        var check_plan_ids = cloneArray(plan_ids);
        var plans = cloneArray(context.state.plans);

        context.state.plans.forEach(function(plan) {
            if (check_plan_ids.indexOf(plan.id) == -1) {
                plans.splice(plans.indexOf(plan), 1)
            } else {
                //Remove existing plans from checklist
                check_plan_ids.splice(check_plan_ids.indexOf(plan.id), 1);
            }
        });
        if (check_plan_ids.length != 0) {
            //need to update list
            context.dispatch('getActiveListPlans');
        } else {
            context.commit('setPlans', plans);
        }
    },
    getActiveListPlans: function (context) {
        return sendToCartApi(
            {
                action: 'get-active-list-data',
            },
            (response) => {
                return response.data.hasOwnProperty('plans');
            })
            .then((response) => {
                context.commit('cart/setCount', response.data.plans.length, { root: true });
                context.commit('setPlans', response.data.plans);
                context.commit('setHiddenRows', Object.values(response.data.hiddenRows));
                context.commit('setHideSimilarParameters', response.data.hideSimilarParameters);
                context.commit('setSort', response.data.sort);
            });
    },
    getLists: function (context) {
        return sendToCartApi(
            {
                action: 'get-lists',
            },
            (response) => {
                return response.data.hasOwnProperty('lists');
            })
            .then((response) => {
                context.commit('setLists', response.data.lists);
            });
    },
    setListActive(context, list_id) {
        return sendToCartApi(
            {
                action: 'set-list-active',
                data: { id: list_id },
                method: 'POST'
            },
            (response) => {
                return response.data.hasOwnProperty('name') && response.data.hasOwnProperty('plans');
            })
            .then((response) => {
                context.commit('setListActive', list_id);
                context.dispatch('getLists');
                context.commit('cart/setCount', response.data.plans.length, { root: true });
                context.commit('setPlans', response.data.plans);
                context.commit('setSort', response.data.sort);
                context.commit('setHiddenRows', Object.values(response.data.hiddenRows));
                context.commit('setHideSimilarParameters', response.data.hideSimilarParameters);
            });
    },
    deleteList(context, list_id) {
        return sendToCartApi(
            {
                action: 'delete-list',
                data: { id: list_id },
                method: 'POST'
            },
            (response) => {
                return response.data.hasOwnProperty('status') && response.data.hasOwnProperty('message');
            });
    },
    saveActiveList(context, payload) {
        return sendToCartApi(
            {
                action: 'save-active-list',
                /*
                {
                    name: string
                    overwrite: bool
                }
                 */
                data: payload,
                method: 'POST'
            },
            (response) => {
                return response.data.hasOwnProperty('status') && response.data.hasOwnProperty('message');
            });
    },
    createNewActiveList() {
        return sendToCartApi(
            {
                action: 'create-new-active-list',
                method: 'POST'
            });
    },
    setSort(context, newSort) {
        return sendToCartApi(
            {
                action: 'set-sort',
                method: 'POST',
                data: {
                    value: newSort
                }
            }).then(() => {
                context.commit('setSort', newSort);
            });
    },
    setHideSimilarParameters(context, value) {
        return sendToCartApi(
            {
                action: 'set-hide-similar-parameters',
                method: 'POST',
                data: {
                    value: value ? 1 : 0
                }
            }).then(() => {
                context.commit('setHideSimilarParameters', value);
            });
    },
    showCartRow(context, name){
        var newHiddenRows = cloneArray(context.state.hidden_rows);

        var index = newHiddenRows.indexOf(name);
        if (index !== -1) {
            newHiddenRows.splice(index, 1);

            return sendToCartApi(
                {
                    action: 'show-cart-row',
                    method: 'POST',
                    data: {
                        row_id: name
                    }
                }).then(() => {
                context.commit('setHiddenRows', newHiddenRows);
            });
        }
    },
    hideCartRow(context, name){
        var newHiddenRows = cloneArray(context.state.hidden_rows);
        newHiddenRows.push(name);
        return sendToCartApi(
            {
                action: 'hide-cart-row',
                method: 'POST',
                data: {
                    row_id: name
                }
            }).then(() => {
            context.commit('setHiddenRows', newHiddenRows);
        });
    },
    activeListChanged(context) {
        context.getters.activeList.has_changes = true;
    },
    init: function(context, init_data) {
        context.state.sort_list = cloneObject(init_data.sort_list);
        context.state.sort = init_data.sort;
        context.state.max_list_length = init_data.max_list_length;
        context.state.lessThanTwoYearsContractTypes = init_data.lessThanTwoYearsContractTypes;
        context.state.lessThanYearContractTypes = init_data.lessThanYearContractTypes;
    },
};