
export default {
    api_url: '/plan/search/index',
    show_filters: true,
    connection_types_priority_list: [],
    alert_connection_type_template: '',
    alert_connection_type: '',
    ignore_address_alert_text: '',
    ignore_address_modal_text: '',
    showMessageInPlanView: '',
    go_to_default_preset_connections_modal_text: '',
    default_preset_url: '',
    addressApiServiceUrl: AUTOCOMPLETE_GLOBALS.addressApiServiceUrl,
    addressApiToken: AUTOCOMPLETE_GLOBALS.addressApiToken,
    addressApiVersion: AUTOCOMPLETE_GLOBALS.addressApiVersion,
    debugAddressAid: AUTOCOMPLETE_GLOBALS.debugAddressAid,
    debugAddress: AUTOCOMPLETE_GLOBALS.debugAddress,
    leadDataTarget: '',
    showConnectionTypeFilter: 0,
    filters: {
        save_to_session: false,
        tracking_enabled: true,
        ignore_address_coverage: false,
        address: {
            formatted: '',
            id: null
        },
        connection_types: {},
        quick_filters: {},
        advanced_filters: {
            price_min: null,
            price_max: null,
            speed_min: null,
            speed_max: null,
            data_min: null,
            data_max: null,
            features: {},
            contract_options: {},
            provider_ids: []
        },
        sort: null,
        price_type: null
    },
    dictionaries: {
        connection_types_list: {},
        connection_types_list_for_modal: {},
        quick_filters_list: {},
        fibre_types_list: {},
        features_list: {},
        contract_options_list: {},
        providers_list: {},
        sort_list: {},
        price_types_list: {},
        price_types_short_labels_list: {},
        price_types_months: {},
        sliders_range: {
            price: {
                min: null,
                max: null,
            },
            data: {
                min: null,
                max: null
            },
            speed: {
                min: null,
                max: null,
            }
        },
        features_quick_filters_dictionary: {},
        contract_options_quick_filters_dictionary: {},
        useful_tips_images: []
    },
    data: {
        plans: [],
        promo: [],
        connection_types: [],
        connection_types_for_modal_speed_hints: {},
        connection_types_for_modal_speeds: {},
        show_availability_modal: false
    },
    meta: {
        total_count: 0,
        page_count: 0,
        current_page: 0,
        per_page: 0,
        price_type: null,
        coverage_connection_problems: false
    },
};
