<template>
    <div>
        <div class="alert alert-success" role="alert" v-if="visible">
            <button type="button" class="close" @click="$store.dispatch('disableAlertConnectionType')" aria-label="Close"><span aria-hidden="true">×</span></button>
            <span v-html="alertText"></span>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                manuallyClosed: false
            };
        },
        computed: {
            alertTemplate() {
                return this.$store.state.plans.alert_connection_type_template;
            },
            show_alert_connection_type() {
                return this.$store.state.plans.show_alert_connection_type;
            },
            connectionType() {
                return this.$store.state.plans.alert_connection_type;
            },
            alertText() {
                //replace all occurencies
                return this.alertTemplate.split('{connection_type}').join('<b>' + this.connectionType + '</b>');
            },
            visible() {
                return this.show_alert_connection_type && this.connectionType != '' && this.connectionType != undefined && this.connectionType != 'undefined'
            }
        },
    }
</script>