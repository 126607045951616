<template>
    <span>
        ${{price_per_two_years}} <span
            v-if="show_notice"
            class="b-tip"
            v-b-tooltip.hover
            title="Based on current standard price"></span>
    </span>
</template>

<script>
    import { mapGetters } from 'vuex'

    export default {
        props: {
            plan: Object,
        },
        computed: {
            ...mapGetters({
                getSelectedDetails: 'cart/profile/getSelectedDetails',
            }),
            price_per_two_years() {
                return this.getSelectedDetails(this.plan).price_per_two_years;
            },
            show_notice() {
                var lessThanTwoYearsContractTypes = this.$store.getters['cart/profile/lessThanTwoYearsContractTypes'];

                return lessThanTwoYearsContractTypes.indexOf(this.getSelectedDetails(this.plan).contract_type_value) != -1;
            }
        },
    }
</script>