<template>
    <div>
        <div class="b-link b-link_del mb-2"
             v-if="$root.inProfileCartMode"
             @click="deletePlan()">
            <i class="fas fa-times fa-danger mr-1"></i>
            delete from comparison
        </div>
        <div class="b-logo b-logo_provider-comparison mb-3">
            <img :src="plan.provider.logo" class="b-logo__image" :alt="plan.provider.name">
        </div>
        <SignInButton :overridePlanId="plan.id+'.'+plan.selected_details_id" :plan="plan"
                      :defaultTrackerLabel="'SIGN UP'"/>
    </div>
</template>

<script>
    import DefaultErrorHandlerMixin from '../../../../common/DefaultErrorHandlerMixin'

    export default {
        mixins: [ DefaultErrorHandlerMixin ],
        props: {
            plan: Object,
        },
        methods: {
            deletePlan() {
                this.$store.dispatch('cart/deletePlan', this.plan.id)
                    .then(
                        () => {
                            this.$store.dispatch('cart/profile/activeListChanged');
                        }
                    ).catch(this.defaultErrorHandler);
            },
        }
    }
</script>