<template>

    <div>
        <a data-remodal-target="advancedFilter" href="#" class="b-link b-link_ico b-link_gray-dark b-link_setting b-link_hover_gray-darken">
            <span class="b-link__ico">
                <i class="fas fa-cog"></i>
                <span v-if="filterCountForBadge" class="b-badge b-badge_primary">{{filterCountForBadge}}</span>
            </span>
            <span class="b-link__text">{{label}}</span>
        </a>
    </div>

</template>

<script>
    import {cloneObject} from '../../js/utils'
    import { mapGetters } from 'vuex'

    export default {
        props: {
            label: {
                type: String,
                default: 'More filters'
            }
        },
        computed: {
            filterCountForBadge() {
                var counter = 0;

                //Do not count features & contract_options which we see in quick_filters
                var skipFeaturesList = Object.keys(this.$store.state.plans.dictionaries.features_quick_filters_dictionary);
                var skipContractOptionsList = Object.keys(this.$store.state.plans.dictionaries.contract_options_quick_filters_dictionary);

                var filter = cloneObject(this.filtersQueryData.f.advanced_filters);

                //Sliders range consists of two parameters, but count as one
                for(var slidersParam in this.slidersRange) {
                    if (filter.hasOwnProperty(slidersParam + '_min') ||
                        filter.hasOwnProperty(slidersParam + '_max')) {
                        counter++;

                        delete filter[slidersParam + '_min'];
                        delete filter[slidersParam + '_max'];
                    }
                }

                for(var param in filter) {
                    if (param == 'provider_ids') {
                        counter++;
                    } else {
                        if (param == 'features') {
                            for (var i in filter[param]) {
                                var feature_name = filter[param][i];
                                if (skipFeaturesList.indexOf(feature_name) == -1)
                                    counter++;
                            }
                        } else if (param == 'contract_options') {
                            for (var i in filter[param]) {
                                var contract_option = filter[param][i];
                                if (skipContractOptionsList.indexOf(contract_option) == -1)
                                    counter++;
                            }
                        } else {
                            counter += Object.keys(filter[param]).length;
                        }
                    }
                }

                return counter;
            },
            ...mapGetters([
                'slidersRange',
                'filtersQueryData'
            ]),
        },
    };
</script>
