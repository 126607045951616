<template>
    <div>
        <FixedAlert v-if="ignore_address_coverage" :top="top">
            <AlertIgnoreAddressText/>
        </FixedAlert>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import Vue from 'vue';
    import FixedAlert from "./FixedAlert";

    Vue.component('AlertIgnoreAddressText', {
        computed: {
            ...mapGetters([
                'ignore_address_alert_text'
            ]),
        },
        render: function (createElement) {
            var match = /(.*){link}(.+){\/link}(.*)/.exec(this.ignore_address_alert_text);

            if (match == null) {
                return createElement('span', this.ignore_address_alert_text);
            }

            return createElement(
                'span',
                [
                    match[1],
                    createElement(
                        'a',
                        {
                            domProps: {
                                href: '#'
                            },
                            on: {
                                click: (event) => {
                                    event.preventDefault();
                                    this.stopIgnoringAddress();
                                }
                            }
                        },
                        match[2],
                    ),
                    match[3]
                ]
            )
        },
        methods: {
            stopIgnoringAddress() {
                this.$store.commit('setFilters', { ignore_address_coverage: 0 });
            }
        }
    });

    export default {
        props: {
            top: {
                type: Number,
                default: 300
            }
        },
        components: {FixedAlert},
        computed: {
            ...mapGetters([
                'ignore_address_coverage'
            ]),
        },
    }
</script>
