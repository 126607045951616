<template>
    <div class="remodal-wrapper remodal-is-closed" style="display: none;">
        <div class="remodal remodal-is-closed featured-modal" tabindex="-1" data-remodal-id="tncs-modal"
             data-remodal-options="hashTracking: false">
            <div class="remodal__header">
                <h2 class="remodal__title">{{label}}</h2>
            </div>
            <div class="remodal__body mb-4">
                <div v-html="text"></div>
            </div>
            <div class="remodal__footer">
                <div class="text-center">
                    <a href="#" data-remodal-action="cancel" class="featured-close">Close</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data: function() {
            return {
                text: '',
                label: 'Terms and conditions',
            }
        },
        methods: {
            show(text) {
                this.text = text;
                this.$nextTick(function() {
                    $('[data-remodal-id="tncs-modal"]').remodal().open();
                });
            },
        }
    }
</script>