<template>
    <th class="minus-column minus-column-question">
        <span class="b-tip"  v-b-tooltip.hover title="You can hide rows by clicking on minuses below. To see them again you can click the 'expand hidden parameters' button below">
        </span>
    </th>
</template>

<script>
    export default {
    }
</script>

<style>
    .tooltip.b-tooltip {
        opacity: 1;
    }
</style>

