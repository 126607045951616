import ContractOptions from './custom/ContractOptions'
import Deal from './custom/Deal'
import DefaultColumn from './custom/DefaultColumn'
import PlanName from './custom/PlanName'
import PlanRating from './custom/PlanRating'
import ProviderName from './custom/ProviderName'
import ProviderRating from './custom/ProviderRating'
import SpecialPrice from './custom/SpecialPrice'
import ProviderTermsCondLink from './custom/ProviderTermsCondLink'
import TerminationFee from './custom/TerminationFee'
import PricePerYear from './custom/PricePerYear'
import PricePerTwoYears from './custom/PricePerTwoYears'
import Vue from 'vue'
import { mapGetters } from 'vuex'

/**
 * Contents for profile cart columns for different characteristics
 * See js/store/modules/cart/profile/table/rows_config.js
 */

Vue.component('ColumnContent', {
    props: {
        rowConfig: {
            type: Object,
            required: true
        },
        plan: {
            type: Object,
            required: true
        },
    },
    components: {
        ContractOptions, Deal, DefaultColumn, PlanName, PlanRating,
        ProviderName, ProviderRating, SpecialPrice, ProviderTermsCondLink, TerminationFee,
        PricePerYear, PricePerTwoYears
    },
    render: function (createElement) {
        var columnComponent = this.createComponentFromRowConfig(createElement);

        if (columnComponent.componentOptions.tag != 'ContractOptions' && this.fieldNameForNotice != '') {
            //ContractOptions creates tooltips itself, individually for each contract type
            columnComponent = this.wrapWithNoticeTooltip(createElement, columnComponent);
        }

        return columnComponent;
    },
    computed: {
        ...mapGetters({
            getSelectedDetails: 'cart/profile/getSelectedDetails',
        }),
        fieldNameForNotice() {
            if (this.rowConfig.hasOwnProperty('noticeField')) {
                return this.rowConfig['noticeField'];
            }
            return '';
        }
    },
    mounted() {
        if (this.$refs.hasOwnProperty('tooltip-container')) {
            $(this.$refs['tooltip-container']).tooltip();
        }
    },
    methods: {
        wrapWithNoticeTooltip(createElement, columnComponent) {
            var fieldNotice = this.getFieldNotice(this.getSelectedDetails(this.plan));

            return createElement('span', {
                    attrs: {
                        class: fieldNotice != '' ? 'got-a-notice' : '',
                        title: fieldNotice,
                        'data-original-title': fieldNotice,
                    },
                    ref: 'tooltip-container'
                },
                [columnComponent]
            )
        },
        createComponentFromRowConfig(createElement)
        {
            var columnComponentName;

            var columnProps = {
                plan: this.plan
            };
            if (this.rowConfig.hasOwnProperty('component')) {
                columnComponentName = this.rowConfig.component;
            } else {
                columnComponentName = 'DefaultColumn';
                if (this.rowConfig.hasOwnProperty('formattedValueExpression')) {
                    columnProps['callback'] = this.rowConfig.formattedValueExpression;
                } else {
                    columnProps['callback'] = this.rowConfig.rawValueExpression;
                }
            }

            return createElement(
                columnComponentName,
                {
                    props: columnProps
                },
                this.$slots.default,
            );
        },
        getFieldNotice(planDetails) {
            if (planDetails.fields_notices.hasOwnProperty(this.fieldNameForNotice)) {
                return planDetails.fields_notices[this.fieldNameForNotice];
            }
            return '';
        }
    },
});
