<template lang="pug">
  div.remodal.large(:data-remodal-id="id" aria-labelledby="firstModalTitle" aria-hidden="true" role="dialog")
    div.content
      div.head(v-if="shownTitle")
        div.w-100.head-line
          button(data-remodal-action="close").close-btn.remodal-close
          h2.modal-title {{ shownTitle }}
      div.text-center.py-3
        p
          | We will contact you as soon as possible. Cheers!
        a(href="#" data-remodal-action="close").btn.btn-primary.text-uppercase.mt-4.mx-auto got it
</template>

<script>

export default {
  name: "ConfirmPopupLead",
  props: {
    title: {
      type: String,
      default: 'Thanks for contacting us'
    },
    id: {
      type: String,
    },
  },
  data: function () {
    return {
      shownTitle: this.title,
      remodalObj: null
    };
  },
  mounted() {
    this.remodal();

    global.$(document).on('open', '[data-remodal-id="' + this.id + '"]', () => {
      this.remodal().open();
    });

    global.$(document).on('closed', '[data-remodal-id="' + this.id + '"]', () => {
      this.$emit('closed', this);
    });

    global.$(document).on('opened', '[data-remodal-id="' + this.id + '"]', () => {
      this.$emit('opened', this);
    });
  },
  methods: {
    closeModal: function () {
      this.remodal().close();
    },
    remodal() {
      if (!this.remodalObj) {
        var options = {};
        if (this.unclosable) {
          options = {
            closeOnEscape: false,
            closeOnOutsideClick: false
          }
        }

        this.remodalObj = global.$('[data-remodal-id="' + this.id + '"]').remodal(options);
      }

      return this.remodalObj;
    }
  },
  watch: {
    title: function () {
      this.shownTitle = this.title;
    }
  }
}
</script>

<style scoped>
.btn-secondary {
  max-width: 140px;
}
</style>
