<template>
    <div>
        <label v-if="!ignore_address_coverage"><b>Available at this address:</b></label>
        <div data-test-id="plans-basic-filter-connection_type">
            <label v-for="(connection_label, connection_type) in connection_types_list" v-bind:for="'ct_' + connection_type"
                   class="b-form-field b-form-field_custom"
                   v-bind:hidden="(!ignore_address_coverage &&
                                                   enabled_connection_types.indexOf(connection_type) === -1 &&
                                                   connection_type === 'cable') || (ignore_address_coverage === true &&
                                                   connection_type === 'cable') "
            >
                <input class="b-form-field__control"
                       type="checkbox"
                       name="connection_type"
                       v-bind:value="connection_type"
                       v-bind:id="'ct_' + connection_type"
                       v-model="connection_types_filter[connection_type]"
                       @click="$store.dispatch('disableAlertConnectionType')"
                       v-bind:disabled="!ignore_address_coverage && enabled_connection_types.indexOf(connection_type) === -1"
                >
                <span class="b-form-field__view b-form-field__view_primary">{{connection_label}}</span>
            </label>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters([
            'enabled_connection_types',
            'connection_types_list',
            'quick_filters_list',
            'isAddressEmpty',
            'ignore_address_coverage'
        ]),
        connection_types_filter() {
            return this.$store.state.plans.filters.connection_types;
        },
    },
}
</script>

<style scoped>

</style>
