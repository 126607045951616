import qs from "qs";

window.axios = require('axios');

//For yii2 to be recognised as an AJAX request
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

//Adding CSRF params for POST requests
/*axios.interceptors.request.use((config) => {
    if (config.method == 'post') {
        if (config['data'] == null) {
            config['data'] = {};
        }

        config['data'][yii.getCsrfParam()] = yii.getCsrfToken();
    }
    return config;
});*/

//Using qs library for serializing GET and POST data
axios.defaults.paramsSerializer = params => {
    return qs.stringify(params)
};
axios.defaults.transformRequest = [function (data) {
    return qs.stringify(data)
}];
