<template>
    <div :class="{'b-stripe b-stripe_quick-access-panel': true, 'fixed-bottom': !isPlansPage}" id="quickAccessPanel">
        <div class="container-fluid h-100">
            <div class="row d-flex align-items-center h-100">
                <template v-if="isPlansPage">
                    <div :class="{'col-lg-6': isGuest, 'col-lg-4': !isGuest, 'col-md-6 mb-3 mb-lg-0': true}">
                        <AddressAutocomplete/>
                    </div>
                    <div class="col-3 col-lg-2 col-md-6 mb-3 mb-lg-0">
                        <AdvancedFilters :label="'Filters'" v-if="showFilters"/>
                    </div>
                    <div class="col-9 col-lg-4 col-md-6 mb-3 mb-lg-0 d-flex justify-content-lg-end">
                        <Cart v-if="cart_enabled"/>
                    </div>
                </template>
                <template v-else>
                    <div class="col-12 mb-3 mb-lg-0 col-lg-6 d-flex">
                        <Cart v-if="cart_enabled"/>
                    </div>
                </template>
                <div v-if="!isGuest" :class="{
                    'col-2 col-md-6 mb-3 mb-lg-0 d-flex justify-content-lg-end': true,
                    'col-2 col-lg-2': isPlansPage,
                    'col-2 col-lg-6': !isPlansPage}">
                    <div :class="{'b-dropdown b-dropdown_user': true, 'b-dropdown_top': !isPlansPage}">
                        <div class="b-dropdown__thumb">
                            <div class="b-user">
                                <div class="b-user__view">
                                    <img :src="avatar_url" class="user-profile-small-img">
                                </div>
                                <div class="b-user__name">{{user_name}}</div>
                            </div>
                        </div>
                        <div class="b-dropdown__menu">
                            <a :href="profile_url" class="b-dropdown__item">Profile</a>
                            <a :href="logout_url" class="b-dropdown__item" data-method="post">Sign out</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import AdvancedFilters from './advanced_filters/AdvancedFilters'
    import AddressAutocomplete from './autocomplete/AddressAutocomplete'
    import Cart from './cart/Cart'
    import { mapGetters } from 'vuex'

    export default {
        props: {
            isPlansPage: Boolean,
            showFilters: {
                type: Boolean,
                default: true
            },
            top: {
                type: Number,
                default: 300
            }
        },
        components: {
            AddressAutocomplete, AdvancedFilters, Cart
        },
        computed: {
            ...mapGetters({
                isGuest: 'common/isGuest',
                user_name: 'common/user_name',
                avatar_url: 'common/avatar_url',
                logout_url: 'common/logout_url',
                profile_url: 'common/profile_url',
                cart_enabled: 'common/cart_enabled',
            }),
        },
        mounted: function() {
            if (this.isPlansPage && document.documentElement.clientWidth >= 768) {
                jSticky('quickAccessPanel', {
                    top: this.top,
                    classActive: 'b-stripe_quick-access-panel_active',
                    clientHeight: false
                });
            }

            var vm = this;
            Vue.nextTick(function() {
                var dropdowns = $(vm.$el).find('.b-dropdown');
                [].forEach.call(dropdowns, function (item) {
                    item.addEventListener('click', function () {
                        this.classList.toggle('b-dropdown_open')
                    })
                });
            });
        },
    }
</script>

<style>
    .b-stripe_quick-access-panel.fixed-bottom {
        top: auto;
        bottom: 0;
        display: block;
        padding: 15px 0 10px;
        z-index: 996;
    }
</style>
