<template>
    <!-- ISP -->
    <a :href="plan.provider.url" class="b-link b-link_primary">{{plan.provider.name}}</a>
</template>

<script>
    export default {
        props: {
            plan: Object
        }
    }
</script>