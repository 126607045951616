<template>
    <!-- Feature -->
    <span>
        <div v-if="plan.is_featured && plan.status == 'published'"
               @click="clickFeaturedLabel()"
               class="b-plan__badge">
            <span :class="'btn btn-sm ' + plan.deal.style">
                {{plan.deal.label}}
            </span>
        </div>
        <b v-else>-</b>
    </span>
</template>

<script>
    import { cloneObject } from "../../../../../../js/utils";
    import { mapGetters } from 'vuex'

    export default {
        props: {
            plan: Object
        },
        computed: {
            ...mapGetters({
                getSelectedDetails: 'cart/profile/getSelectedDetails',
            }),
        },
        methods: {
            clickFeaturedLabel() {
                var featured_data = cloneObject(this.plan.deal);
                featured_data.details_url = this.plan.url;
                this.$eventHub.$emit('show-featured-modal', featured_data);
            },
        }
    }
</script>