<template>
    <!-- ISP Rating -->
    <b><i :class="'far ' + getRatingClass(plan.provider.rating * 10) + ' ' + getSmileClass(plan.provider.rating * 10)"></i> {{plan.provider.rating}}</b>
</template>

<script>
    import { mapGetters } from 'vuex'

    export default {
        props: {
            plan: Object
        },
        computed: {
            ...mapGetters({
                getRatingClass: 'common/getRatingClass',
                getSmileClass: 'common/getSmileClass',
            }),
        },
    }
</script>