<template>
    <div>
        <a :href="cart_comparison_url" class="b-link b-link_ico b-link_gray-dark b-link_compare b-link_hover_gray-darken">
            <span class="b-link__ico">
                <i class="fas fa-balance-scale"></i>
                <span class="b-badge b-badge_primary" data-test-id="comparison-cart-items-count">{{count}}</span>
            </span>
            <span class="b-link__text" data-test-id="url-to-comparison-list">
                plans in your comparison list
            </span>
        </a>
    </div>
</template>

<script>
    export default {
        name: "Cart",
        computed: {
            count: function() {
                return this.$store.state.cart.count;
            },
            cart_comparison_url:  function() {
                return this.$store.getters['common/cart_comparison_url'];
            },
        },
    }
</script>
