<template>
    <!-- Special price -->
    <span>
        <b v-if="getSelectedDetails(plan).special_price">{{getSelectedDetails(plan).special_price}}</b>
        <b v-else>-</b>
    </span>
</template>

<script>
    import { mapGetters } from 'vuex'

    export default {
        props: {
            plan: Object
        },
        computed: {
            ...mapGetters({
                getSelectedDetails: 'cart/profile/getSelectedDetails',
            }),
        },
    }
</script>