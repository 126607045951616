<template>
    <div class="b-slider">
        <div class="b-slider__value">{{hint}}</div>
        <div class="b-slider__control no-ui-slider"></div>
        <div class="b-slider__label">{{label}}</div>
    </div>
</template>

<script>
    import noUiSlider from '../../../node_modules/nouislider/distribute/nouislider';

    export default {
        props: {
            min: {
                type: Number,
                required: true,
            },
            max: {
                type: Number,
                required: true,
            },
            range: {
                type: Object,
                required: true,
            },
            snap: {
                type: Boolean,
                default: false,
            },
            getHintCallback: Function,
            label: String,
        },
        computed: {
            hint: function() {
                if (typeof(this.getHintCallback) == 'undefined') {
                    return this.min + ' - ' + this.max;
                }

                return this.getHintCallback(this.min, this.max);
            },
            values: function() {
                return [this.min, this.max];
            }
        },
        methods: {
            getSliderElement: function(){
                return $(this.$el).find('.b-slider__control')[0];
            },
            reset: function() {
                this.getSliderElement().noUiSlider.set([this.range.min, this.range.max]);
            },
            reinit: function() {
                $(this.getSliderElement()).empty();
                this.getSliderElement().noUiSlider = null;
                this.init();
            },
            init: function() {
                var vm = this;

                var slider = this.getSliderElement();
                noUiSlider.create(slider, {
                    start: vm.values,
                    connect: true,
                    snap: vm.snap,
                    range: vm.range,
                    format: {
                        from: function(value) {
                            return parseInt(value);
                        },
                        to: function(value) {
                            return parseInt(value);
                        }
                    }
                }).on('update', function (values) {
                    vm.$emit('update:min', values[0]);
                    vm.$emit('update:max', values[1]);
                });
            }
        },
        mounted: function () {
           this.init();
        },
        destroyed: function () {
            $(this.$el).empty().removeAttr('class');
        },
    }
</script>

<style>
    .b-slider {
        margin-right: 10px;
        margin-left: 10px;
    }
</style>