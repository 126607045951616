<template>
    <div class="d-inline-block select-list-block">
        <Select2 :options="listsForSelect2"
                 v-model="select2_active_list_id"
                 :settings="{ minimumResultsForSearch: -1 }"
                 @change="select2ChangedActiveList"/>
        <SaveConfirmationModal ref="save_confirmation_modal"/>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    import SaveConfirmationModal from './SaveConfirmationModal'
    import DefaultErrorHandlerMixin from '../../common/DefaultErrorHandlerMixin'

    export default {
        mixins: [ DefaultErrorHandlerMixin ],
        data: function(){
            return {
                select2_active_list_id: null
            };
        },
        components: {
            SaveConfirmationModal
        },
        beforeMount: function(){
            this.select2_active_list_id = this.activeListId;
        },
        computed: {
            ...mapGetters({
                activeListId: 'cart/profile/activeListId',
                activeList: 'cart/profile/activeList',
                lists: 'cart/profile/lists',
            }),
            listsForSelect2: function() {
                var result = [];

                for (var i in this.lists) {
                    var list = this.lists[i];
                    result.push({
                        id: list.id,
                        text: list.name,
                    })
                }
                return result;
            },
        },
        methods: {
            select2ChangedActiveList(newActiveListId) {
                var vm = this;

                vm.$refs.save_confirmation_modal.show(
                () => {
                    vm.setListActive(newActiveListId);
                },
                () => {
                    vm.select2_active_list_id = vm.activeListId;
                });
            },
            setListActive(newActiveListId) {
                this.$store.dispatch('cart/profile/setListActive', newActiveListId).then(
                    () => {
                        this.select2_active_list_id = newActiveListId;
                    },
                    this.defaultErrorHandler
                );
            },
        },
        watch: {
            activeListId: function() {
                this.select2_active_list_id = this.activeListId;
            }
        }
    }
</script>

<style>
    .select-list-block .select2-container--default .select2-selection--single {
        border: 0;
    }
</style>
