import "core-js";
import "regenerator-runtime/runtime";

import './js/markup';
import './js/axios'

import Vue from 'vue'
import { TooltipPlugin } from 'bootstrap-vue'
import Select2 from './components/common/Select2';
import store from './js/store/index';
import Main  from './components/Main';
import VueContentPlaceholders from 'vue-content-placeholders'
import CircleLoader from './components/common/CircleLoader'
import SignInButton from './components/plan/SignInButton'
import ConfirmationModal from './components/common/ConfirmationModal'
import LeadCalculatorConfig from './calculator.js'
import PopupLead from './populLead.js'
import { VUE_APP_MODES } from './modes';
import Vuelidate from 'vuelidate';

Vue.prototype.$eventHub = new Vue();
window.Vue = Vue;
if (vue_debug_mode == undefined) {
    vue_debug_mode = false;
}
Vue.config.productionTip = vue_debug_mode;
Vue.config.debug = vue_debug_mode;
Vue.config.devtools = vue_debug_mode;

//Every mode has its own root element id
var modes_selectors = {};
modes_selectors[VUE_APP_MODES.COMPARISON] = '#plan-comparison';
modes_selectors[VUE_APP_MODES.PROVIDER] = '#plan-provider';
modes_selectors[VUE_APP_MODES.PROFILE_CART] = '#profile-cart';
modes_selectors[VUE_APP_MODES.CART_NON_VUE_ADAPTER] = '#cart-non-vue-adapter';
modes_selectors[VUE_APP_MODES.SPECIAL] = '#plan-special';

//vue_store_init is a variable with initialization data, its goes from Yii2 (see VueInitWidget)
if (typeof(vue_store_init) != 'undefined') {

    Vue.component('Select2', Select2);
    $.fn.select2.defaults.set("dropdownAutoWidth", true);
    $.fn.select2.defaults.set("width", 'auto');

    Vue.component('CircleLoader', CircleLoader);
    Vue.component('SignInButton', SignInButton);
    Vue.component('ConfirmationModal', ConfirmationModal);

    Vue.use(VueContentPlaceholders);
    Vue.use(TooltipPlugin);
    Vue.use(Vuelidate);

    //If we got the root element of mode on page, we initialize application in this mode
    for (var mode in modes_selectors) {
        var el_selector = modes_selectors[mode];
        if ($(el_selector).length > 0) {

            new Vue({
                el: el_selector,
                store,
                data: function() {
                    return {
                        error_message: '',
                        mode: mode,
                        inner_html_on_init: $(el_selector).html()
                    };
                },
                computed: {
                    inProfileCartMode() {
                        return this.mode == VUE_APP_MODES.PROFILE_CART;
                    }
                },
                components: { Main },
                template: '<Main :error_message="error_message" :mode="mode"/>',
            });

            break;
        }
    }
}

if ($('#vue_calculator').length > 0) {
    Vue.use(VueContentPlaceholders);
    Vue.use(TooltipPlugin);
    new Vue(LeadCalculatorConfig);
}

if ($('#popup_lead').length > 0) {
    new Vue(PopupLead);
}

