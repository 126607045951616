<template>
    <span>
        <a :href="plan.url"  target="_blank" class="b-link_primary-dark" v-if="fee.type === 'complicated_etf'"
            v-b-tooltip.hover
            :title="fee.complicated_etf_comment"
        >
            {{fee.value}}
        </a>
        <span v-else >
            {{fee.value}}
        </span>
    </span>
</template>

<script>
    import { mapGetters } from 'vuex'

    export default {
        props: {
            plan: Object,
        },
        computed: {
            ...mapGetters({
                getSelectedDetails: 'cart/profile/getSelectedDetails',
            }),
            fee() {
                return this.getSelectedDetails(this.plan).termination_fee;
            }
        },
    }
</script>