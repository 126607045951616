<template>
    <!-- Rating -->
    <b><i :class="'fas fa-star ' + getRatingClass(getSelectedDetails(plan).rating)"></i>
        {{getSelectedDetails(plan).rating}}</b>
</template>

<script>
    import { mapGetters } from 'vuex'

    export default {
        props: {
            plan: Object
        },
        computed: {
            ...mapGetters({
                getSelectedDetails: 'cart/profile/getSelectedDetails',
                getRatingClass: 'common/getRatingClass',
            }),
        },
    }
</script>