export function getPriceTypeChangeFiltersPatch(priceMin, priceMax, oldSlidersRangePrice, convertRatio) {
    if (priceMax == oldSlidersRangePrice.max) {
        priceMax = (priceMax - 1) * convertRatio + 1
    } else {
        priceMax = priceMax * convertRatio;
    }

    var newFilters = {
        advanced_filters: {
            price_min: parseInt(convertRatio * priceMin),
            price_max: parseInt(priceMax)
        }
    };

    return newFilters;
}

export function getPriceTypeChangeDictionariesPatch(oldSlidersRangePrice, convertRatio) {

    var newSlidersPriceRange = {
        price: {
            min: convertRatio * oldSlidersRangePrice.min,
            max: convertRatio * (oldSlidersRangePrice.max - 1) + 1
        }
    };

    return {
        sliders_range: newSlidersPriceRange
    };
}