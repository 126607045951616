<template>
    <div v-bind:style="screenIsMobile == true ? 'background-color :' + promo.background_color : 'background: url(' + promo.background_image + ') no-repeat'" :class="['b-hz-' + promo.text_color]" class="b-hz mb-3 d-flex">
        <div class="mt-auto mb-auto">
            <div v-if="!promo.is_system && promo.provider_image" class="b-hz__logo">
                <img class="b-hz__logo-img" :src="promo.provider_image" :alt="promo.title">
            </div>
            <h2 class="b-hz__title">{{promo.title}}</h2>
            <h3 class="b-hz__subtitle">{{promo.options}}</h3>
            <p class="b-hz__text"><nl2br tag="span" :text="promo.details"/></p>
            <div class="b-hz__btn">
                <a v-if="!promo.is_system && promo.plan_id" href="#" class="btn btn-secondary mr-2 mt-3 text-uppercase" @click="showTrackingForm()">switch now</a>
                <a :href="promo.url" class="btn btn-primary mt-3 text-uppercase" :target="promo.open_url_in_new_tab ? '_blank' : '_parent'">
                    Find out more
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    import Nl2br from 'vue-nl2br'
    import ScreenSizeMixin from "../common/ScreenSizeMixin";

    export default {
        mixins: [ScreenSizeMixin ],
        components: { Nl2br },
        props: {
            promo: Object,
            isCompleteStatus: false
        },
        mounted() {
          if (this.promo.third_party_pixel && this.isCompleteStatus) {
            $('body').append(this.promo.third_party_pixel);
          }
        },
        methods: {
            showTrackingForm() {
                var idParam = this.promo.base_id + '.' + this.promo.plan_id;

                showPlanTrackingForm(idParam, this.promo.target);
            },
        }
    }
</script>

<style>
    .b-hz.b-hz-white {
        color: white;
    }

    .b-hz.b-hz-black {
        color: #333;
    }
</style>
