<template>

    <div class="remodal error-remodal" tabindex="-1" data-remodal-id="error-modal"
         data-remodal-options="hashTracking: false">
        <div class="text-center">
            {{message}}
        </div>
    </div>

</template>

<script>
    import Vue from 'vue'

    export default {
        name: "ErrorModal",
        props: {
            message: {
                type: String,
            }
        },
        created () {
            var vm = this;

            $(document).on('opened', '[data-remodal-id="error-modal"]', function () {
                var msg_modal = $(this).remodal();

                setTimeout(function() {
                    msg_modal.close();
                }, 4000);
            });

            $(document).on('closed', '[data-remodal-id="error-modal"]', function () {
                vm.$root.error_message = '';
            });
        },

        watch: {
            message: function(newVal) {
                if (newVal != '') {
                    Vue.nextTick(function () {
                        $('[data-remodal-id="error-modal"]').remodal().open();
                    });
                }
            }
        }
    }
</script>

