import {convertObjectListToSelect2Options} from "../../../../utils";

export default {
    //Hidden parameters
    hidden_parameters_are_shown: function(state){
        return state.hidden_parameters_are_shown;
    },
    hide_similar_parameters: function(state){
        return state.hide_similar_parameters;
    },

    //Lists
    max_list_length (state) {
        return state.max_list_length;
    },
    activeList: function(state, getters, rootState, rootGetters) {
        if (rootGetters['common/isGuest']) {
            return {
                id: -1,
                is_active: true,
                has_changes: true
            };
        }

        return state.lists.find((list) => {
            return list.is_active;
        });
    },
    lists: function(state) {
        return state.lists;
    },
    activeListId: function(state, getters) {
        if (getters.activeList != undefined)
            return getters.activeList.id;
    },

    //Selected PlanDetails
    getSelectedDetails: function() {
        return (plan) => {
            return plan.details.find((planDetails) => {
                return planDetails.id == plan.selected_details_id;
            });
        }
    },
    getSelectedDetailsFeaturePrice(state, getters) {
        return (plan, feature_search_type) => {
            var feature = getters.getSelectedDetails(plan).features.find((feature) => {
                return feature.type == feature_search_type;
            });

            if (feature != undefined) {
                return feature.price;
            }

            return '-';
        }
    },

    //Sort
    sortListForSelect2: function(state) {
        return convertObjectListToSelect2Options(state.sort_list);
    },

    plans: function(state) {
        return state.plans;
    },

    hidden_rows: function(state) {
        var arr = [];

        for (var i in state.hidden_rows){
            arr.push(String(state.hidden_rows[i]));
        }

        return arr;
    },

    lessThanYearContractTypes: function(state) {
        return state.lessThanYearContractTypes;
    },

    lessThanTwoYearsContractTypes: function(state) {
        return state.lessThanTwoYearsContractTypes;
    },
};