<script>
    export default {
        props: {
            isMobile: Boolean
        },
        data: function() {
            return {
                rows_count: 17,
                columns_count: this.isMobile ? 1 : 5,
            };
        }
    };
</script>

<template>
    <content-placeholders :rounded="true">

        <div class="table-responsive profile-cart-skeleton">
            <table class="b-table b-table_comparison">
                <thead>
                <tr>
                    <th></th>
                    <th></th>
                    <th v-for="m in columns_count">
                        <div class="b-link b-link_del mb-2">
                            <i class="fas fa-times fa-danger mr-1"></i>
                            delete from comparison
                        </div>
                        <div class="b-logo b-logo_provider-comparison mb-3">
                            <content-placeholders-img/>
                        </div>
                        <div><button class="btn btn-primary">
                            SIGN UP
                        </button></div>
                    </th>
                </tr>
                </thead>
                <tbody>

                <tr v-for="i in rows_count">
                    <td><span class="minus_container"><i class="fas fa-minus"></i></span></td>
                    <td><content-placeholders-text :lines="1" /></td>
                    <td v-for="m in columns_count">
                        <content-placeholders-text :lines="1" />
                    </td>
                </tr>

                </tbody>
            </table>
        </div>

    </content-placeholders>
</template>