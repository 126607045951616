<template>
    <div
         class="remodal_small"
         :data-remodal-id="modalId">
        <div class="remodal__header" v-if="title">
            <h2 class="remodal__title">{{title}}</h2>
            <div class="remodal__close" data-remodal-action="cancel">&times;</div>
        </div>
        <div class="remodal__body mb-3">
            <div class="container-fluid">
                {{text}}
            </div>
        </div>
        <div class="remodal__footer">
            <button v-if="yes_callback" @click="callYesCallback()" class="btn btn-primary text-uppercase">Yes</button>
            <button v-if="no_callback" @click="callNoCallback()" class="btn btn-primary text-uppercase">No</button>
            <button v-if="cancel_callback" data-remodal-action="cancel" class="btn btn-primary text-uppercase">Cancel</button>
        </div>
    </div>
</template>

<script>
    import RemodalMixin from './RemodalMixin'

    export default {
        name: 'ConfirmationModal',
        mixins:[ RemodalMixin ],
        props: {
            title: {
                type: String,
            },
            text: {
                type: String,
                required: true,
            }
        },
        data: function() {
            return {
                yes_callback: null,
                no_callback: null,
                cancel_callback: null,
                execute_cancel_on_close: false,
            };
        },
        computed: {
            modalId: function() {
                return this.$options.name + this._uid;
            },
        },
        mounted() {
            $('[data-remodal-id="' + this.modalId + '"]').remodal({
                hashTracking: false
            });
        },
        created: function() {
            var vm = this;

            $(document).on('closed', '[data-remodal-id="' + this.modalId + '"]', function() {
                if (vm.execute_cancel_on_close && vm.cancel_callback && typeof(vm.cancel_callback) == 'function') {
                    vm.cancel_callback();
                }

                vm.yes_callback = null;
                vm.no_callback = null;
                vm.cancel_callback = null;
            });
        },
        methods: {
            show(yes_callback, no_callback, cancel_callback) {
                this.yes_callback = yes_callback;
                this.no_callback = no_callback;
                this.cancel_callback = cancel_callback;

                this.execute_cancel_on_close = true;

                this.remodal.open();
            },
            callYesCallback() {
                if (this.yes_callback) {
                    this.yes_callback();
                }

                this.execute_cancel_on_close = false;

                this.remodal.close();
            },
            callNoCallback() {
                if (this.no_callback) {
                    this.no_callback();
                }

                this.execute_cancel_on_close = false;

                this.remodal.close();
            },
        }
    }
</script>
