<template>
    <div class="remodal remodal_big" data-remodal-id="bundleOptionsModal">
        <div class="remodal__header">
            <h2 class="remodal__title">Choose the kind of plan you need</h2>
        </div>
        <div class="remodal__body">

            <div class="b-available-connection bundles d-lg-flex justify-content-center mt-4">
                <div class="b-connection ml-3 mr-3 mb-3" @click="click('without_bundles')">
                    <div class="b-item pt-2">
                        <img src="/img/icons/broadband-only.svg" alt="Broadband only" class="mr-0 mb-0"/>
                    </div>
                    <span class="mt-2 d-block">Broadband only</span>
                </div>
                <div class="b-connection ml-3 mr-3 mb-3" @click="click('power')">
                    <div class="b-item pt-2">
                        <img src="/img/icons/power-bundles.svg" alt="Power bundles" class="mr-0 mb-0"/>
                    </div>
                    <span class="mt-2 d-block">Power bundles</span>
                </div>
                <div class="b-connection ml-3 mr-3 mb-3" @click="click('mobile')">
                    <div class="b-item pt-2">
                        <img src="/img/icons/mobile-bundles.svg" alt="Mobile bundles" class="mr-0 mb-0"/>
                    </div>
                    <span class="mt-2 d-block">Mobile bundles</span>
                </div>
                <div class="b-connection ml-3 mr-3 mb-3" @click="click()">
                    <div class="b-item pt-2">
                        <img src="/img/icons/all-plans.svg" alt="Show all options" class="mr-0 mb-0"/>
                    </div>
                    <span class="mt-2 d-block">All options</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {cloneObject} from "../js/utils";

export default {
    props: {
        autoOpen: {
            type: Boolean,
            default: true
        },
    },
    mounted() {
        $('[data-remodal-id="bundleOptionsModal"]').remodal({
            hashTracking: false
        });
    },
    computed: {
        ...mapGetters([
            'filters',
            'currentAddress',
            'show_availability_modal',
        ]),
    },
    methods: {
        click(type) {
            let advanced = cloneObject(this.filters.advanced_filters);
            ['without_bundles', 'power', 'mobile'].forEach(t => {
                advanced.features[t] = (t === type) ? 1 : 0;
            });

            this.$store.commit('setFilters', {
                advanced_filters: advanced,
            });
            this.$store.commit('convertAdvancedToQuickFilters');

            $('[data-remodal-id="bundleOptionsModal"]').remodal().close();
        }
    },
    watch: {
        show_availability_modal(value) {
            if (value && this.autoOpen)
                $('[data-remodal-id="bundleOptionsModal"]').remodal().open();
        }
    }
}
</script>

<style scoped>
.remodal__title {
    text-align: center;
    text-transform: uppercase;
}
.b-available-connection .b-item {
    border: 2px solid #E4E4E4;
    //height: 170px;
    //border-radius: 15px;
}
.bundles img {
    margin-top: 22px;
}
.bundles .b-item {
    cursor: pointer;
}
.bundles .b-item:hover {
    background-color: #E9E8DE;
}
.b-available-connection {
    border-bottom: none;
}
.b-available-connection .b-connection span {
    font-size: 18px;
}
.remodal_big {
    max-width: 800px;
}
</style>
