<template>
    <div v-if="plan" v-bind:class="{ 'b-plan_deal': dealColorHighlight }" class="b-plan mb-4" :data-id="plan.id" ref="plan">
        <div class="b-plan__header">

            <div class="b-plan__badge" v-if="plan.has_deal">
                <span :class="'btn btn-sm ' + plan.deal.style" @click="clickFeaturedLabel()">{{plan.deal.label}}</span>
            </div>
            <div v-if="show_rating_in_plans" class="b-plan__rating b-rating">
                <div :class="'b-rating__star  fas fa-star ' + getRatingClass()"></div>
                <div class="b-rating__text">{{plan.rating}}</div>
            </div>
            <h4 class="b-plan__title">
                <a :href="plan.url" class="b-link b-link_primary b-link_hover_gray-darkest">{{plan.name}}</a><span v-if="plan.has_deal && !!plan.deal !== false && plan.deal.short_description" class="b-plan__title-sub" @click="clickFeaturedLabel()"> /  {{plan.deal.short_description}}</span>
            </h4>
            <div class="b-plan__actions">
                <PlanCartButton v-if="cart_enabled" :plan="plan" @added-to-cart="flyToCartAnimation"/>
                <!-- <i class="b-plan__action fas fa-share-alt"></i>
                <i class="b-plan__action far fa-envelope"></i> -->
            </div>
        </div>
        <div class="row d-flex b-plan-md">
            <div class="col-lg-2 mb-3 mb-lg-0">
                <a :href="plan.provider.url" class="b-plan__logo b-logo h-100 d-flex align-items-center">
                    <img data-test-role="plan-logo-image" ref="logo" :src="plan.provider.logo" :alt="plan.name" class="b-logo__image">
                </a>
            </div>
            <div class="w-60 col-md-12 mb-3 mb-lg-0">
                <div class="row">
                    <div class="col-lg-12 col-md-6">
                        <div class="b-feature b-feature-plan text-left">
                            <img v-bind:src="speedIconUrl" alt="" class="b-feature__ico float-left"/>
                            <div :class="{'speed-little-text' : speedTextIsLittle, 'b-feature__text': true}" class="text-nowrap">
                                {{plan.speed.down}}/{{plan.speed.up}} Mbps
                                <span class="b-tips">
                                    <i class="fas fa-question"></i>
                                    <span class="tip__text" v-html="speedTipTitle"></span>
                                </span>
                            </div>
                            <div class="b-feature__type">Speed ({{plan.connection_type}})</div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-6">
                        <div class="b-feature b-feature-plan text-left">
                            <img v-bind:src="dataIconUrl" alt="" class="b-feature__ico float-left"/>
                            <div class="b-feature__text">
                                <PlanNotice :plan="plan" :field="'data'">
                                    <template v-if="plan.data == -1">Unlimited</template>
                                    <template v-else>{{plan.data}} GB</template>
                                </PlanNotice>
                            </div>
                            <div class="b-feature__type">Data</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="w-40 col-md-12 mb-3 mb-lg-0 text-left">
                <div class="b-pure-content b-pure-content_type_plan">
                    <div class="row">
                        <div class="col-lg-12 col-md-6">
                            <h4>Contract options</h4>
                            <ul>
                                <li v-for="contract in plan.contract_options">
                                    <PlanNotice :plan="plan" :field="contract">{{contract}}</PlanNotice>
                                </li>
                            </ul>
                        </div>
                        <div class="col-lg-12 col-md-6">
                            <template v-for="fee in plan.fees">
                                <h4>{{fee.name}}</h4>
                                <PlanNotice v-if="fee.name === 'Connection fee'" :plan="plan" :field="fee.name">{{fee.value}}</PlanNotice>
                                <TerminationFeeValue v-if="fee.name === 'Termination fee'" :plan="plan" :fee="fee" />
                            </template>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 mb-3 mb-lg-0 text-left">
                <div class="b-pure-content b-pure-content_type_plan">
                    <table class="mb-2 w-100">
                        <tbody>
                            <template v-if="plan.included_features.length > 0">
                                <tr class="border-bottom-0"><th colspan="2"><b>Included features</b></th></tr>
                                <tr v-for="included_feature in plan.included_features">
                                    <td>{{included_feature.name}}</td>
                                    <td>
                                        <PlanNotice :plan="plan" :field="included_feature.name">
                                            <b v-if="included_feature.price == 0">Free</b>
                                            <b v-else>{{included_feature.price}}</b>
                                        </PlanNotice>
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 mb-3 mb-lg-0 text-left">
                <div class="b-pure-content b-pure-content_type_plan">
                    <table class="mb-2 w-100">
                        <tbody>
                            <template v-if="plan.available_features.length > 0">
                                <tr class="border-bottom-0">
                                    <th colspan="2"><b>Available features</b></th>
                                </tr>
                                <tr v-for="(feature, feature_i) in plan.available_features"
                                    :class="{'d-none': feature_i >= 2 && plan.available_features.length > 3 && !show_all_available_features}">
                                    <td>{{feature.name}}</td>
                                    <td><b><PlanNotice :plan="plan" :field="feature.name">{{feature.price}}</PlanNotice></b></td>
                                </tr>
                                <tr v-if="plan.available_features.length > 3"
                                    class="available_features_plan_toggle"
                                    @click="show_all_available_features = !show_all_available_features">
                                    <td colspan="2">
                                        <template v-if="show_all_available_features">
                                            show less
                                        </template>
                                        <template v-else>
                                            show more
                                        </template>
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="col-lg-2 text-center text-lg-left">
                <div class="b-price mb-3">
                    <h4 class="b-price__from">from</h4>
                    <span class="b-price__number">
                      <PlanNotice :plan="plan" :field="'price'">${{plan.price}}</PlanNotice>
                    </span>
                    <span class="b-price__unit">/{{priceUnit}}
                        <span
                                v-if="plan.show_based_on_current_standard_price_notice"
                                class="b-tip"
                                v-b-tooltip.hover
                                title="Based on current standard price">
                        </span>
                    </span>
                    <div class="b-price__additionally" v-if="plan.price_description" v-html="plan.price_description"></div>
                </div>
                <SignInButton :plan="plan" :overridePlanId="getPlanIdParamForIframe()" :buttonClass="dealColorHighlight ? 'btn-secondary' : 'btn-primary' "/>
            </div>
        </div>
    </div>

    <p v-else class="text-center">plan is outdated</p>
</template>

<script>
    import SignInButton from './SignInButton'
    import PlanCartButton from './PlanCartButton'
    import DefaultErrorHandlerMixin from '../common/DefaultErrorHandlerMixin'
    import { cloneObject } from '../../js/utils'
    import { mapGetters } from 'vuex'
    import PlanNotice from './PlanNotice'
    import TerminationFeeValue from './TerminationFeeValue'

    export default {
        name: "Plan",
        mixins: [ DefaultErrorHandlerMixin ],
        props: {
            'plan': Object,
            'priceUnit': {
                type: String,
                default: 'mo'
            }
        },
        components: {
            SignInButton, PlanCartButton, PlanNotice, TerminationFeeValue
        },
        data: function() {
            return {
                show_all_available_features: false
            }
        },
        computed: {
            ...mapGetters({
                show_rating_in_plans: 'common/show_rating_in_plans',
                cart_enabled: 'common/cart_enabled',
            }),
            speedIconUrl: function() {
                return this.$store.getters['common/speedIconUrl'](this.plan.speed.down);
            },
            speedTipTitle: function() {
                return this.$store.state.plans.speed_tip;
            },
            dataIconUrl: function() {
                var result = '/images/symbol/svg/data';

                if (this.plan.data == -1) {
                    result = result + '-unlimited';
                }

                return result + '.svg';
            },
            dealColorHighlight() {
                if (this.plan.has_deal) {
                    return parseInt(this.plan.deal.color_highlight);
                }

                return false;
            },
            speedTextIsLittle() {
                return (this.plan.speed.down.toString() + this.plan.speed.up.toString()).length > 6;
            }
        },
        methods: {
            getRatingClass() {
                return this.$store.getters['common/getRatingClass'](this.plan.rating);
            },
            getPlanIdParamForIframe() {
                return this.plan.id + '.' + this.plan.plan_details_id_on_sign_in_button + '.' + this.plan.detail_ids.join('_');
            },
            clickFeaturedLabel() {
                if (this.plan.deal.text != '') {
                    var featured_data = cloneObject(this.plan.deal);
                    featured_data.details_url = this.plan.url;

                    this.$eventHub.$emit('show-featured-modal', featured_data);
                }
            },
            flyToCartAnimation()
            {
                window.animateFlyToBasket(this.$refs.plan);
            },
        },
    }
</script>

<style>
    .b-pure-content table tbody tr.available_features_plan_toggle {
        border-bottom: 0;
    }
    .b-pure-content table tbody tr.available_features_plan_toggle td {
        text-align: left;
        color: #666666;
        cursor: pointer;
    }
    .b-plan__title-sub {
        cursor: pointer;
    }
    .b-feature__text.speed-little-text {
        font-size: 1rem;
    }

    .b-tips {
        width: 15px;
        height: 15px;
        border-radius: 50%;
        display: inline-block;
        vertical-align: middle;
        cursor: pointer;
        position: relative;
        margin: 0 1px;
        white-space: normal !important;
    }

    .b-tips .fa-question {
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background: #009db0;
        font-size: 8px;
        color: #fff;
        line-height: 15px;
        text-align: center;
        display: block;
        z-index: 2;
        position: relative;
        top: -2px;
    }

    .b-tips .tip__text {
        display: none;
        position: absolute;
        left: 7px;
        top: 7px;
        background: #fff;
        border: 1px solid #ccc;
        border-radius: 5px;
        z-index: 1;
    }

    .b-tips:hover .tip__text {
        width: 230px;
        display: block;
        color: #555555;
        font-size: 12px;
        padding: 10px;
    }
</style>
