<template>
    <div>
        <div class="remodal-wrapper remodal-is-closed" style="display: none;">
            <div class="remodal remodal-is-closed featured-modal" tabindex="-1" data-remodal-id="plan-info-text-modal"
                 data-remodal-options="hashTracking: false">
                <div class="remodal__header">
                    <h2 class="remodal__title">{{label}}</h2>
                </div>
                <div class="remodal__body mb-4">
                    <nl2br tag="p" :text="full_description"/>
                    <p class="mt-3">
                        <a :href="details_url">Full plan details</a>
                        <a href="#" @click.prevent="$refs.tncs_modal.show(main_terms)" class="d-block mt-2">Terms and Conditions apply</a>
                    </p>
                </div>
                <div class="remodal__footer">
                    <div class="text-center">
                        <a href="#" data-remodal-action="cancel" class="btn btn-primary mr-1">Got it</a>
                    </div>
                </div>
            </div>
        </div>

        <TermsAndConditionsModal ref="tncs_modal"/>
    </div>
</template>

<script>
    import TermsAndConditionsModal from "./TermsAndConditionsModal";
    import Nl2br from 'vue-nl2br'

    export default {
        name: "FeaturedModal",
        components: {TermsAndConditionsModal, Nl2br},
        data: function() {
            return {
                full_description: '',
                label: '',
                details_url: '',
                main_terms: ''
            }
        },
        methods: {
            show(deal) {
                this.full_description = deal.full_description;
                this.label = deal.short_description ? deal.short_description : deal.label;
                this.details_url = deal.details_url;
                this.main_terms = deal.main_terms;
                this.$nextTick(function() {
                    $('[data-remodal-id="plan-info-text-modal"]').remodal().open();
                });
            },
        }
    }
</script>
