<template>
    <!-- ISP -->
    <span v-if="plan.provider.termsCondLink != null"><a target="_blank" :href="plan.provider.termsCondLink" class="b-link_primary-dark">See</a></span>
    <span v-else>-</span>
</template>

<script>
    export default {
        props: {
            plan: Object
        }
    }
</script>