
function cleanSlidersValues(filters, slidersRange) {
    for(var sliderParam in slidersRange)
    {
        var min = slidersRange[sliderParam].min;
        var max = slidersRange[sliderParam].max;

        if (filters.hasOwnProperty(sliderParam + '_min') && filters.hasOwnProperty(sliderParam + '_max'))
            if (filters[sliderParam + '_min'] == min && filters[sliderParam + '_max'] == max) {
                delete filters[sliderParam + '_min'];
                delete filters[sliderParam + '_max'];
            }
    }
}

function convertCheckboxBooleanListToApiList(booleanList) {
    var result = [];
    for (var name in booleanList) {
        if (booleanList[name]) {
            result.push(name);
        }
    }

    return result;
}

//Removes default values from filter data
function cleanFilter(obj, skip_properties) {
    for (var propName in obj) {
        if (skip_properties != undefined && skip_properties.indexOf(propName) != -1)
            continue;

        if (obj[propName] === null || obj[propName] === undefined || obj[propName] == 0) {
            delete obj[propName];
        } else {
            if (typeof(obj[propName]) == 'object') {
                cleanFilter(obj[propName]);

                if (Object.keys(obj[propName]).length == 0) {
                    delete obj[propName];
                }
            }
        }
    }
}

//Converts each boolean attribute in object and nested objects to integer (1 or 0)
function convertBooleanToIntFilter(obj) {
    for (var propName in obj) {
        if (typeof obj[propName] === 'boolean') {
            obj[propName] = (obj[propName] ? 1 : 0);
        } else {
            if (typeof(obj[propName]) == 'object') {
                convertBooleanToIntFilter(obj[propName]);
            }
        }
    }
}

import {cloneObject} from "../../../utils";

export default function (state) {
    var query_data = cloneObject(state.filters);

    query_data.connection_types = convertCheckboxBooleanListToApiList(query_data.connection_types);
    query_data.quick_filters = convertCheckboxBooleanListToApiList(query_data.quick_filters);
    query_data.advanced_filters.features = convertCheckboxBooleanListToApiList(query_data.advanced_filters.features);
    query_data.advanced_filters.contract_options = convertCheckboxBooleanListToApiList(query_data.advanced_filters.contract_options);

    //Remove slider values if they == sliders range
    cleanSlidersValues(query_data.advanced_filters, state.dictionaries.sliders_range);

    //Skip sliders values, send even if min value equals 0
    var skipPropertiesOnClean = ['tracking_enabled', 'save_to_session'];
    for(var slidersParam in state.dictionaries.sliders_range) {
        skipPropertiesOnClean.push(slidersParam + '_min');
    }

    cleanFilter(query_data, skipPropertiesOnClean);
    convertBooleanToIntFilter(query_data);

    return {
        f: query_data,
        page: state.meta.current_page + 1,
        "per-page": state.meta.per_page
    };

};
